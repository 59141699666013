.needHelpSecation {
    position: relative;
    padding-bottom: 0;
    padding-top: 1em;
    border-top: 1px solid var(--accent_color2);
    border-bottom: 1px solid var(--accent_color2);
}

.needHelpSecation .HelpWrapper {
    position: relative;
    padding: 0;
}

.needHelpSecation .HelpWrapper .TxtMain {
    position: relative;
    padding: 0.9375em;
}

.needHelpSecation .HelpWrapper .TxtMain h2 {
    font-size: 3em;
    color: var(--primary_color);
    margin-bottom: 0.3125em;
    font-weight: 700;
}

.needHelpSecation .HelpWrapper .TxtMain .helplineTxt {
    font-size: 1.125em;
    color: var(--accent_color1);
    margin-bottom: 1.875em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.needHelpSecation .HelpWrapper .TxtMain i {
    font-size: 2em;
    padding-left: 0;
}

.needHelpSecation .HelpWrapper .btn-link {
    color: var(--primary_color);
    font-size: 0.95rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
}

.needHelpSecation img {
    height: 100%;
    aspect-ratio: 1.4;
    height: 100%;
}

@media (max-width: 767.98px) {
    .needHelpSecation .HelpWrapper .mobileImg {
        display: none;
    }

    .needHelpSecation .HelpWrapper .TxtMain h2 {
        font-size: 1.9rem;
        text-align: center;
    }

    .needHelpSecation .HelpWrapper .btn-link {
        font-size: 0.7rem;
        text-align: center;
        width: 100%;
    }

    .needHelpSecation .HelpWrapper .TxtMain .helplineTxt {
        text-align: center;
    }
}

[dir='rtl'] .needHelpSecation .HelpWrapper .btn-link {
    float: right;
}

[dir='rtl'] .needHelpSecation .HelpWrapper .btn-link .btn-icon {
    padding-right: 0.675em !important;
}
