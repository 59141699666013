.FeaturedWrapper {
    position: relative;
}

.FeaturedWrapper .titleMain {
    position: relative;
    margin: 0;
    padding: 15px;
    background: #fff;
    /* box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.05); */
    margin-bottom: 30px;
}

.FeaturedWrapper .titleMain h2 {
    color: #ae6c2f;
    font-size: 36px;
    font-weight: 600;
    padding-left: 10px;
    border-left: 5px solid #ae6c2f;
    line-height: 1;
}

.FeaturedCarouselWrapper {
    position: relative;
}

.FeaturedCarouselWrapper .BirdMain {
    position: relative;
    width: 295px;
    height: auto;
    background: #ffd6d6;
    padding: 15px;
    border-radius: 15px;
}

.FeaturedCarouselWrapper .BirdMain .imgMain {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
}

.FeaturedCarouselWrapper .BirdMain .imgMain img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.FeaturedCarouselWrapper .BirdMain h3 {
    text-align: center;
    font-size: 16px;
    padding-top: 10px;
}

.FeaturedServicesWrapper {
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0;
}

.FeaturedServicesWrapper ul {
    position: relative;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, 50%);
}

.FeaturedServicesWrapper ul li {
    position: relative;
    margin: 0 0 30px;
    padding: 0 15px;
    display: inline-block;
    text-align: center;
}

.FeaturedServicesWrapper ul li .icon-wrap {
    position: relative;
    width: 140px;
    height: 140px;
    margin: 0 auto;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.FeaturedServicesWrapper ul li h2 {
    font-size: 14px;
    padding-bottom: 10px;
    text-align: center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
