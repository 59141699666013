.footer1 {
    position: relative;
    padding: 2.25em 0 0 0;
    margin-top: 4em;
    border-top: 5px solid #046f44;
    background: url(../../../public/assets/images/footer-flower.png) right bottom no-repeat #fefefe;
}

.footer1 .logoAppWrapper {
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 3.125em;
    margin-bottom: 3.125em;
}

.footer1 .logoAppWrapper .logoAppHldr {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 1.875em;
}

.footer1 .logoAppWrapper .logoAppHldr .logo {
    position: relative;
    margin-right: auto;
}

.footer1 .logoAppWrapper .logoAppHldr .CommunityHldr {
    display: none;
}

.footer1 .logoAppWrapper .logoAppHldr .txtAppIcon {
    position: relative;
    display: flex;
    align-items: center;
}

.footer1 .logoAppWrapper .logoAppHldr .txtAppIcon .txtMian {
    position: relative;
    font-size: 1em;
    line-height: normal;
    margin: 0;
    padding: 0;
    color: #046f44;
    margin-right: 1.5625em;
}

.footer1 .logoAppWrapper .logoAppHldr .txtAppIcon .iconMain {
    position: relative;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.footer1 .logoAppWrapper .logoAppHldr .txtAppIcon .iconMain img {
    width: 6.25em;
    margin-bottom: 0.625em;
}

.footer1 .logoAppWrapper .SocialLinkMain {
    position: relative;
}

.footer1 .logoAppWrapper .SocialLinkMain h6 {
    position: relative;
    margin: 0;
    padding: 0 0 1.25em 0;
    font-size: 1em;
    font-weight: 600;
}

.footer1 .logoAppWrapper .SocialLinkMain ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer1 .logoAppWrapper .SocialLinkMain ul li {
    position: relative;
    margin: 0;
    padding: m(0);
    display: inline-block;
    text-align: center;
    line-height: normal;
}

.footer1 .logoAppWrapper .SocialLinkMain ul li span {
    display: block;
    margin-right: 1.25em;
    font-size: 1.25em;
    line-height: normal;
}

.footer1 .logoAppWrapper .SocialLinkMain ul li span:hover {
    color: #046f44;
}

.footer1 .logoAppWrapper .SocialLinkMain ul li:last-child span {
    border-right: none;
}

.footer1 .logoAppWrapper .SocialLinkMain .emailID {
    position: relative;
    margin-top: 0.9375em;
    display: none;
}

.footer1 .logoAppWrapper .SocialLinkMain .emailID a {
    font-size: 1.125em;
    color: #48545c;
}

.footer1 .linkMain {
    position: relative;
    margin-bottom: 1.25em;
}

.footer1 .linkMain h6 {
    position: relative;
    margin: 0;
    padding: 0 0 0.625em 0;
    font-size: 1em;
    font-weight: 600;
}

.footer1 .linkMain ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 100%;
}

.footer1 .linkMain ul.grid-size-50 {
    grid-template-columns: repeat(auto-fit, 50%);
}

.footer1 .linkMain ul li {
    position: relative;
    margin: 0 0.5em 0.5em 0;
    padding: 0;
    display: inline-block;
    line-height: normal;
}

.footer1 .linkMain ul li a {
    font-size: 0.875em;
    color: #48545c;
    line-height: normal;
}

.footer1 .linkMain ul li a:hover {
    color: #046f44;
}

.footer1 .copyRight {
    border-top: 1px solid #bdbdbd;
    position: relative;
    padding: 1.875em 0;
    text-align: center;
    font-size: 0.8125em;
}

.footer1 .copyRight a {
    color: #303030;
}

.footer1 .copyRight a:hover {
    color: #046f44;
}

.footer1 .container {
    max-width: 87% !important;
}

@media (max-width: 767.98px) {
    .footer1 .logoAppWrapper {
        padding-bottom: 1.5em;
        margin-bottom: 1.5em;
    }

    .footer1 .logoAppWrapper .logoAppHldr {
        padding-bottom: 0.9375em;
        margin: 0 0 0;
        display: block;
    }

    .footer1 .logoAppWrapper .logoAppHldr .logo {
        padding: 0;
        margin-right: 0;
        margin-bottom: 0.9375em;
    }

    .footer1 .logoAppWrapper .logoAppHldr .logo img {
        width: 15.625em;
    }

    .footer1 .logoAppWrapper .logoAppHldr .CommunityHldr {
        display: block;
        margin-bottom: 1.5em;
    }

    .footer1 .logoAppWrapper .logoAppHldr .txtAppIcon .iconMain {
        flex-direction: initial;
    }

    .footer1 .logoAppWrapper .logoAppHldr .txtAppIcon .iconMain a {
        margin-right: 0.625em;
    }

    .footer1 .logoAppWrapper .logoAppHldr .txtAppIcon .iconMain a:last-child {
        margin-right: 0;
    }

    .footer1 .logoAppWrapper .logoAppHldr .txtAppIcon .iconMain a img {
        margin-bottom: 0;
    }

    .footer1 .linkMain h6 {
        font-weight: 400;
        margin-bottom: 0;
    }

    .footer1 .linkMain ul {
        margin-bottom: 1.5em;
    }

    .footer1 .linkMain ul.grid-size-50 {
        grid-template-columns: repeat(auto-fit, 100%);
    }

    .footer1 .SocialLinkMain {
        margin: 0 0;
    }

    .footer1 .SocialLinkMain h6 {
        display: none;
        font-size: 1.25em;
    }

    .footer1 .SocialLinkMain ul {
        display: block;
    }

    .footer1 .SocialLinkMain ul li {
        margin-right: 0.9375em;
    }

    .footer1 .SocialLinkMain ul li a {
        border: none;
    }

    .footer1 .SocialLinkMain .emailID {
        display: block;
    }

    .footer1 .copyRight {
        margin: 0 0;
        padding: 1.875em 0.75em;
        text-align: center;
    }
}
