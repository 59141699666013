.eventWrapper {
    position: relative;
}

.eventWrapper h2 {
    font-size: 2em;
    margin-bottom: 0.9375em;
    font-weight: 700;
}

.eventWrapper .eventBoxMain {
    position: relative;
    margin: 0;
    padding: 0;
    border: 1px solid #e3e4e8;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 0.9375em;
    margin-bottom: 1.875em;
}

.eventWrapper .eventBoxMain .eventImgMain {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 15.625em;
    overflow: hidden;
    border-radius: 0.9375em 0 0 0.9375em;
}

.eventWrapper .eventBoxMain .eventImgMain img {
    width: 100%;
    height: 15.625em;
    object-fit: cover;
}

.eventWrapper .eventBoxMain .eventTxtMain {
    position: relative;
    padding: 0.9375em;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.eventWrapper .eventBoxMain .eventTxtMain .cateTxt {
    position: relative;
    width: 100%;
    font-size: 0.875em;
    color: #046f44;
    margin-bottom: 0.3125em;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
}

.eventWrapper .eventBoxMain .eventTxtMain .title {
    position: relative;
    width: 100%;
    font-size: 1.25em;
    margin-bottom: 0.3125em;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.eventWrapper .eventBoxMain .eventTxtMain .decTxt {
    position: relative;
    width: 100%;
    font-size: 1.125em;
    flex: 1 1 auto;
}

.eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper {
    position: relative;
    display: grid;
    grid-template-columns: 60% 40%;
    align-items: center;
}

.eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper .dateLocaMain {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
}

.eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper .dateLocaMain .dateTime {
    position: relative;
    color: #828282;
    font-size: 1.125em;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
}

.eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper .dateLocaMain .dateTime i {
    margin-right: 0.3125em;
    width: 1.125em;
}

.eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper .dateLocaMain .dateTime span {
    position: relative;
    padding-left: 1.5625em;
}

.eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper .dateLocaMain .dateTime span::before {
    content: '';
    position: absolute;
    left: 0.625em;
    top: 0.5em;
    width: 10px;
    height: 10px;
    background: #828282;
    border-radius: 0.9375em;
}

.eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper .dateLocaMain .location {
    position: relative;
    color: #828282;
    font-size: 1.125em;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper .dateLocaMain .location i {
    margin-right: 0.3125em;
    width: 1.125em;
}

.eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper .regimain {
    position: relative;
    text-align: right;
}

.eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper .regimain .RegisterBtn {
    position: relative;
    padding: 0.5em 1.125em;
    border: none;
    background: #04656f;
    border-radius: 0.25em;
    font-size: 0.875em;
    color: #fff;
}

@media (max-width: 767.98px) {
    .eventWrapper .eventBoxMain {
        border-radius: 0.375em;
    }

    .eventWrapper .eventBoxMain .eventImgMain {
        border-radius: 0.375em 0.375em 0 0;
        height: 12.625em;
    }

    .eventWrapper .eventBoxMain .eventTxtMain .cateTxt {
        font-size: 0.75em;
        letter-spacing: normal;
    }

    .eventWrapper .eventBoxMain .eventTxtMain .title {
        -webkit-line-clamp: 4;
        font-size: 0.875em;
    }

    .eventWrapper .eventBoxMain .eventTxtMain .decTxt {
        margin-bottom: 0.9375em;
        display: none;
    }

    .eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper {
        display: block;
        margin-top: auto;
    }

    .eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper .dateLocaMain {
        margin-bottom: 0.9375em;
    }

    .eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper .dateLocaMain .dateTime {
        display: block;
        font-size: 0.75em;
    }

    .eventWrapper
        .eventBoxMain
        .eventTxtMain
        .dateLocaWrapper
        .dateLocaMain
        .dateTime
        span::before {
        width: 6px;
        height: 6px;
        top: 0.4375em;
    }

    .eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper .dateLocaMain .location {
        font-size: 0.75em;
    }

    .eventWrapper .eventBoxMain .eventTxtMain .dateLocaWrapper .regimain {
        text-align: left;
    }
}
