.trendingWrapper {
    position: relative;
    margin: 0;
    padding-bottom: 1em;
    padding-top: 2.5em;
}

.trendingWrapper h1 {
    font-size: 2em;
    color: #046f44;
    position: relative;
    padding-bottom: 0.3125em;
    font-weight: 400;
    line-height: 1;
}

.trendingWrapper h1 .line {
    position: absolute;
    content: '';
    width: 3em;
    margin: 0 auto;
    height: 2px;
    background: #046f44;
    bottom: 0;
    left: 0;
    right: 0;
}

.trendingWrapper .body-text {
    font-size: 1.125em;
    text-align: center;
}

.trendingWrapper .TrendingtabWrapper {
    position: relative;
    padding-top: 1em;
}

.trendingWrapper .TrendingtabWrapper .TrendingtabHldr {
    position: relative;
}

.trendingWrapper .TrendingtabWrapper .TrendingtabHldr .nav-item {
    margin: 0 0.9375em 20px;
}

.trendingWrapper .TrendingtabWrapper .TrendingtabHldr .nav-item .nav-link {
    font-size: 1.125em;
    font-weight: 600;
    color: #1d1d1d;
    font-weight: 400;
    background: #f8f8f8;
    padding: 0.5em 1em;
    border-radius: 0.375em;
}

.trendingWrapper .TrendingtabWrapper .TrendingtabHldr .nav-item .nav-link.active {
    color: #fff;
    background-color: #046f44;
}

.trendingWrapper .TrendingtabWrapper .TrendingContent {
    position: relative;
}

.trendingWrapper .card {
    border: none;
    border-radius: 10px;
    border-radius: 15px;
    border: 1px solid #e3e4e8;
    background: #fff;
    box-shadow: 5px 5px 25px 0px #0000001a;
}

.trendingWrapper .card .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.5;
    display: flex;
    border-radius: 15px 15px 0px 0px;
}

.trendingWrapper .card .cardImg img {
    width: 100%;
    object-fit: cover;
    border-radius: 0px 15px 0px 0px;
    animation: fadeIn2 1s;
    -webkit-animation: fadeIn2 1s;
    -moz-animation: fadeIn2 1s;
    -o-animation: fadeIn2 1s;
    -ms-animation: fadeIn2 1s;
}

.trendingWrapper .TrendingtabWrapper .TrendingContent .card .cardImg .tag {
    z-index: 2;
    position: absolute;
    bottom: 0.625em;
    right: 0.9375em;
    padding: 0.375em 0.5625em;
    border: 1px solid #f2f2f3;
    border-radius: 4px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.8) 0, rgba(255, 255, 255, 0.55) 100%);
    box-shadow: 0 20px 40px 0 rgba(57, 124, 191, 0.1);
    backdrop-filter: blur(20px);
    color: #1d1d1d;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.75em;
    text-transform: uppercase;
}

.tag {
    z-index: 2;
    position: absolute;
    bottom: 0.625em;
    right: 0.9375em;
    padding: 0.375em 0.5625em;
    border: 1px solid #f2f2f3;
    border-radius: 4px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.8) 0, rgba(255, 255, 255, 0.55) 100%);
    box-shadow: 0 20px 40px 0 rgba(57, 124, 191, 0.1);
    backdrop-filter: blur(20px);
    color: #1d1d1d;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.75em;
    text-transform: uppercase;
    margin-left: 10px;
}

.trendingWrapper .card .card-body {
    position: relative;
    padding: 1em;
    border-radius: 0px 0px 6px 6px;
}

.trendingWrapper .card .card-body h4 {
    font-size: 0.75em;
    color: #046f44;
    padding-bottom: 0.5em;
    margin: 0;
    text-transform: uppercase;
}

.trendingWrapper .card .card-body h3 {
    font-size: 1em;
    margin: 0 0 0.75em 0;
    padding: 0;
    line-height: normal;
    font-weight: 400;
}

.trendingWrapper .card .card-body .tag-heading {
    color: #046f44;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
    letter-spacing: 2.1px;
    text-transform: uppercase;
    margin-bottom: 0.6em;
}

.trendingWrapper .card .card-body .authorDateMain {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 12.25px;
    font-weight: 400;
}

.trendingWrapper .card .card-body .authorDateMain .author {
    color: #046f44;
    text-align: start;
}

.trendingWrapper.card .card-body .authorDateMain .date {
    color: #666;
    padding-left: 0.5em;
}

.trendingWrapper .card .card-body .dateMain {
    z-index: 2;
    position: absolute;
    width: 4em;
    height: 4em;
    border-radius: 5px;
    background: #c9b198;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    top: -2.5em;
    right: 0.9375em;
    text-align: center;
    color: #fff;
    padding: 0.125em 0.9375em;
}

.trendingWrapper .card .card-body .dateMain h2 {
    font-size: 1.5em;
    font-weight: 700;
    line-height: normal;
}

.trendingWrapper .card .card-body .dateMain .month {
    font-size: 0.75em;
    font-weight: 600;
    line-height: normal;
}

.trendingWrapper .card {
    height: 100% !important;
}

.mobileText {
    display: none;
}

.trendingWrapper .swiper-button-prev,
.swiper-button-next {
    margin-top: 0;
}

.trendingWrapper .swiper-button-next {
    margin-top: 0;
}

.trendingWrapper .swiper-button-prev {
    top: 1% !important;
}

.trendingWrapper .swiper-button-next {
    top: 1% !important;
}

.trendingWrapper .button {
    color: var(--Grey-3, var(--Grey-3, #828282));
    text-align: center;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    border: 1px solid var(--Grey-3, #828282);
    border-radius: 0.625em;
    background: var(--White, #fff);
    display: inline-flex;
    padding: 0.375em 1.5em;
    align-items: center;
    gap: 10px;
}

.trendingWrapper .p-dropdown {
    width: 100%;
    margin-bottom: 24px;
}

.trendingWrapper .p-dropdown {
    border: 1px solid #046f44;
}

.trendingWrapper .p-dropdown:not(.p-disabled).p-focus {
    border-color: #046f44;
    box-shadow: none;
}

.trendingWrapper .p-dropdown:not(.p-disabled):hover {
    border-color: #046f44;
}

.trendingWrapper .swiper-pagination-bullet-active {
    width: 43px !important;
    height: 7px !important;
    border-radius: 10px !important;
    background: var(--primary_color) !important;
}

.trendingWrapper .p-dropdown .p-dropdown-trigger {
    color: var(--accent_color1);
}

.trendingWrapper .btn-icon {
    margin-top: 1px;
}

@media (max-width: 767.98px) {
    .padding {
        padding: 15px;
    }

    .trendingWrapper .TrendingtabWrapper .TrendingtabHldr .nav-item {
        margin-bottom: 0.9375em;
    }

    [dir='rtl'] .trendingWrapper .card .card-body .authorDateMain .date {
        padding-left: 0;
    }

    .trendingWrapper .button {
        margin-top: 1em;
    }

    .trendingWrapper .swiper-pagination-fraction,
    .trendingWrapper .swiper-pagination-custom,
    .trendingWrapper .swiper-horizontal > .swiper-pagination-bullets,
    .trendingWrapper .swiper-pagination-bullets.swiper-pagination-horizontal {
        display: block;
    }

    .trendingWrapper .swiper-button-next {
        display: none !important;
    }

    .trendingWrapper .swiper-button-prev {
        display: none !important;
    }
}

@keyframes fadeIn2 {
    0% {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

[dir='rtl'] .trendingWrapper h1 {
    padding-bottom: 0.125em;
}

[dir='rtl'] .trendingWrapper .pi-arrow-right::before {
    content: '\e91a';
}
