.TopSliderMain {
    position: relative;
    border-bottom: 0.1875em solid #00a19a;
}

.TopSliderMain .sliderBox {
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: 100% !important;
    aspect-ratio: 1.5;
    width: 100%;
}

.TopSliderMain .sliderBox img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.TopSliderMain .sliderBox::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 69.79%, rgba(0, 0, 0, 0.56) 93.23%);
}

.TopSliderMain .sliderBox .textMain {
    height: 66vw;
    /* 100vw divided by 1.5 aspect */
    /* max-height: calc(100vh - 160px); */
    width: 66%;
    padding-bottom: 40px;
    z-index: 1;
    display: flex;
    align-items: end;
}

.TopSliderMain .sliderBox .textMain h3 {
    color: #fff;
    font-size: 2em;
    font-weight: 500;
    line-height: 1.2;
    width: 100%;
    text-align: left;
    text-shadow: 0 0 24px #000;
    z-index: 1;
    text-align: start;
}

.TopSliderMain .sliderBox .textMain p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.TopSliderMain .swiper-pagination-clickable .swiper-pagination-bullet-active i {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 70px;
    height: 6px;
    background-color: red;
    border-radius: 10px;
}

.TopSliderMain .swiper-pagination-clickable .swiper-pagination-bullet-active b {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 70px;
    height: 6px;
    border-radius: 10px;
    background-color: blue;
}

.TopSliderMain .swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.TopSliderMain
    .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 0;
    white-space: nowrap;
    width: 96% !important;
    right: 0;
    margin: 0 auto;
    text-align: right;
}

@media (max-width: 767.98px) {
    .TopSliderMain .titleHldr {
        background: #f8f8f8;
        padding: 20px 10px;
    }

    .TopSliderMain {
        border-bottom: 0;
    }

    .TopSliderMain .sliderBox .textMain h3 {
        font-size: 16px;
        font-weight: 500;
    }

    .TopSliderMain .swiper-pagination-clickable .swiper-pagination-bullet {
        width: 35px;
        height: 6px;
        border-radius: 10px;
        background-color: #fff !important;
        opacity: 1;
    }

    .TopSliderMain .swiper-pagination-clickable .swiper-pagination-bullet-active i {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 35px;
        height: 6px;
        background-color: #83858f;
        border-radius: 10px;
    }

    .TopSliderMain .swiper-pagination-clickable .swiper-pagination-bullet-active b {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 35px;
        height: 6px;
        border-radius: 10px;
        background-color: var(--accent_color4) !important;
    }

    .TopSliderMain
        .swiper-horizontal
        > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
    .TopSliderMain
        .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
        bottom: 30%;
    }

    [dir='rtl'] .TopSliderMain .swiper-pagination-clickable .swiper-pagination-bullet-active i {
        right: 0;
    }

    [dir='rtl'] .TopSliderMain .swiper-pagination-clickable .swiper-pagination-bullet-active b {
        right: 0;
    }
}

@media (min-width: 767.98px) {
    .TopSliderMain .swiper-pagination {
        position: absolute !important;
        bottom: 30px !important;
        left: 0;
        right: 0;
        display: block;
    }

    .TopSliderMain .swiper-pagination-clickable .swiper-pagination-bullet {
        width: 70px;
        height: 6px;
        border-radius: 10px;
        background-color: #83858f !important;
        opacity: 1;
    }

    .TopSliderMain .swiper-pagination-clickable .swiper-pagination-bullet-active i {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 70px;
        height: 6px;
        background-color: #83858f !important;
        border-radius: 10px;
    }

    .TopSliderMain .swiper-pagination-clickable .swiper-pagination-bullet-active b {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 70px;
        height: 6px;
        border-radius: 10px;
        background-color: var(--accent_color4) !important;
    }

    .TopSliderMain .swiper-pagination-fraction,
    .TopSliderMain .swiper-pagination-custom,
    .TopSliderMain .swiper-horizontal > .swiper-pagination-bullets,
    .TopSliderMain .swiper-pagination-bullets.swiper-pagination-horizontal {
        display: block !important;
    }

    [dir='rtl'] .TopSliderMain .swiper-pagination-clickable .swiper-pagination-bullet-active i {
        right: 0;
    }

    [dir='rtl'] .TopSliderMain .swiper-pagination-clickable .swiper-pagination-bullet-active b {
        right: 0;
    }
}

.TopSliderMain .swiper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.TopSliderMain .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0;
    margin-right: 20px;
}

.TopSliderMain .swiper-pagination-bullet {
    position: relative;
    width: 156px;
    height: auto;
    text-align: left;
    border-radius: 0;
    opacity: 1;
    margin-right: 20px;
    background-color: transparent;
}

.TopSliderMain .swiper-pagination-bullet em {
    font-size: 15px;
    line-height: 28px;
    font-weight: bold;
    letter-spacing: -0.38px;
    color: #111;
}

.swiper-pagination-bullet-active b {
    animation-name: countingBar;
    animation-duration: 5s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
}

.TopSliderMain .swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.TopSliderMain
    .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    transform: translateX(0) !important;
}

@keyframes countingBar {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

[dir='rtl']
    .TopSliderMain
    .swiper-horizontal
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
[dir='rtl']
    .TopSliderMain
    .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    text-align: left;
}

[dir='rtl'] .TopSliderMain .sliderBox .textMain {
    left: auto;
    position: absolute;
    text-align: right;
}
