.iframe {
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    overflow: hidden;
}

.iframe ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
