.FeatureCarouselWrapper2 {
    position: relative;
    margin-top: 100px;
}

.FeatureCarouselWrapper2 .featureImgHldr {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    aspect-ratio: 1.71/1;
}

.FeatureCarouselWrapper2 .swiper {
    padding-bottom: 200px !important;
    padding-top: 0 !important;
}

.FeatureCarouselWrapper2 .featureImgHldr img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.FeatureCarouselWrapper2 .txtBoxHldr2 {
    position: absolute;
    top: 85%;
    left: 84px;
    background: #fff;
    padding: 25px 25px 40px 25px;
    width: 440px;
    border-radius: 10px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
}

.FeatureCarouselWrapper2 .txtBoxHldr2 h2 {
    font-size: 27px;
    font-weight: 500;
    color: var(--primary_color);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.FeatureCarouselWrapper2 .txtBoxHldr2 p {
    margin: 13px 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    color: var(--accent_color1);
}

.FeatureCarouselWrapper2 .txtBoxHldr2 .readingLink {
    font-size: 14px;
    font-weight: 600;
    padding: 8px 16px;
    background: var(--primary_color);
    color: var(--accent_color4);
    border: none;
    border-radius: 4px;
}

.FeatureCarouselWrapper2 .swiper-button-prev,
.FeatureCarouselWrapper2 .swiper-button-next {
    background: var(--primary_color);
    height: 60px !important;
    width: 60px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.FeatureCarouselWrapper2 .swiper-button-prev {
    bottom: 200px;
    top: auto !important;
    left: 524px !important;
}

.FeatureCarouselWrapper2 .swiper-button-prev::before {
    content: '\e931';
}

.FeatureCarouselWrapper2 .swiper-button-next {
    left: 584px;
    bottom: 200px;
    top: auto !important;
}

.FeatureCarouselWrapper2 .swiper-button-prev::before,
.FeatureCarouselWrapper2 .swiper-button-next::before {
    font-family: 'PrimeIcons';
    color: var(--accent_color4);
    font-size: 23px;
}

.FeatureCarouselWrapper2 .swiper-button-next::before {
    content: '\e932';
}

.FeatureCarouselWrapper2 .pi-angle-right:before {
    position: relative;
    top: 2px;
}

@media (max-width: 767.98px) {
    .FeatureCarouselWrapper2 {
        margin-top: 0;
    }

    .FeatureCarouselWrapper2 .featureImgHldr {
        height: auto;
    }

    .FeatureCarouselWrapper2 .swiper {
        padding-bottom: 0 !important;
    }

    .FeatureCarouselWrapper2 .txtBoxHldr2 {
        position: relative;
        left: 0;
        bottom: 0;
        width: 100%;
        border-radius: 0;
        box-shadow: none;
    }

    .FeatureCarouselWrapper2 .txtBoxHldr2 h2 {
        font-size: 30px;
    }

    .FeatureCarouselWrapper2 .txtBoxHldr2 p {
        font-size: 16px;
    }

    .FeatureCarouselWrapper2 .swiper-button-prev {
        display: block;
    }

    .FeatureCarouselWrapper2 .swiper-pagination {
        display: none;
    }

    .FeatureCarouselWrapper2 .swiper-button-next,
    .FeatureCarouselWrapper2 .swiper-button-prev {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .FeatureCarouselWrapper2 .swiper-button-prev {
        bottom: 0;
        top: 206px !important;
        left: auto !important;
        right: 44px !important;
    }

    .FeatureCarouselWrapper2 .swiper-button-next {
        left: auto;
        bottom: 0;
        top: 206px !important;
        right: 0;
    }

    .FeatureCarouselWrapper2 .swiper-button-prev,
    .FeatureCarouselWrapper2 .swiper-button-next {
        background: var(--primary_color);
        height: 44px !important;
        width: 44px !important;
    }
}
