.weekHistoryWrapper {
    position: relative;
    padding-top: 3em;
    padding-bottom: 3em;
}

.weekHistoryWrapper .title {
    color: #3182c3;
    font-size: 2em;
    font-style: normal;
    font-weight: 700;
    text-align: center;
}

.weekHistoryWrapper .body {
    text-align: center;
    font-size: 1.2rem;
    color: #3182c3;
    font-weight: 600;
}

.weekHistoryWrapper .date {
    color: #9a9ab0;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.08px;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
}

.weekHistoryWrapper .date span {
    padding: 2rem;
}

.weekHistoryWrapper .card-image img {
    width: 100%;
    margin: 0 auto;
    aspect-ratio: 1.4;
}

.weekHistoryWrapper .card-image .card-title {
    color: #11142d;
    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.072px;
}

.weekHistoryWrapper .card-image .card-body {
    width: 50%;
    margin: 0 auto;
}

.weekHistoryWrapper .card-image .card-description {
    color: #a6a2a2;
    text-align: center;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.08px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: normal;
}

.weekHistoryWrapper .card-image .date {
    color: #a6a2a2;
    text-align: center;
    padding: 1rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.08px;
}

.weekHistoryWrapper .active {
    color: #046f44;
}

.weekHistoryWrapper .swiper-button-prev,
.swiper-button-next {
    margin-top: 0;
}

.weekHistoryWrapper .swiper-button-next {
    margin-top: 0;
}

.weekHistoryWrapper .swiper-button-prev {
    top: 0% !important;
}

.weekHistoryWrapper .swiper-button-next {
    top: 0% !important;
}

@media (max-width: 767.98px) {
    .weekHistoryWrapper .card-image .card-title {
        font-size: 1rem;
        padding: 0;
    }

    .weekHistoryWrapper .card-image .card-description {
        font-size: 12px;
        margin-top: 10px;
    }

    .weekHistoryWrapper .card-image .date {
        font-size: 10px;
        padding: 0.6rem;
    }

    .weekHistoryWrapper .date span {
        padding: 1rem 1rem 0.5rem;
    }

    .weekHistoryWrapper .title {
        font-size: 2rem;
    }

    .weekHistoryWrapper .card-image {
        aspect-ratio: 1.4;
    }

    .weekHistoryWrapper .swiper {
        padding-top: 0 !important;
        padding-bottom: 20px !important;
    }
}
