.NewsLetterTitleHldr {
    padding-bottom: 3.5em;
    padding-top: 3.5em;
    background: rgb(248, 248, 248);
}

.NewsLetterFormWrapper {
    position: relative;
    padding-top: 1.5em;
    padding-bottom: 3em;
    margin: 0 auto;
    max-width: 1000px !important;
}

.NewsLetterFormWrapper h2 {
    font-size: 45px;
    font-weight: 500;
}

.NewsLetterFormWrapper p {
    margin-bottom: 15px;
}
.NewsLetterFormWrapper p:last-child {
    margin-bottom: 0;
}

.NewsLetterFormWrapper .contactImg {
    text-align: center;
}

.NewsLetterFormWrapper .contactImg img {
    width: 100%;
}

.NewsLetterFormWrapper .button {
    width: 150px;
    align-items: center;
    background: #fff;
    border: 1px solid #828282;
    border-radius: 0.625em;
    color: #828282;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    padding: 0.375em 1.5em;
    text-align: center;
    margin-left: auto;
}

.NewsLetterFormWrapper .button:hover {
    background: #007041;
    border: 1px solid #007041;
    color: #fff;
}

.NewsLetterFormWrapper sup {
    color: #007041;
}