.recentReleaseWrapper {
    position: relative;
    padding-top: 3em;
    padding-bottom: 3em;
    background: #eef7f4;
}

.recentReleaseWrapper .title {
    position: relative;
    padding: 0.9375em 1.25em 0.9375em 0em;
    display: flex;
    align-items: center;
}

.recentReleaseWrapper .title h3 {
    color: #046f44;
    font-weight: 700;
    display: flex;
    position: relative;
    padding-left: 0.8125em;
    font-size: 2em;
}

.recentReleaseWrapper .title h3::before {
    content: '';
    position: absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    background: #046f44;
}

.recentReleaseWrapper .programmesTagWrapper {
    position: relative;
    margin: 0;
    padding: 0;
}

.recentReleaseWrapper .programmesTagWrapper ul {
    list-style: none;
    margin: 0 0;
    display: flex;
    align-items: center;
    cursor: grabbing;
}

.recentReleaseWrapper .programmesTagWrapper ul li {
    position: relative;
    margin: 0.3125em 0.625em 0.3125em 0;
    padding: 0.325em 1em;
    display: inline-block;
    border-radius: 2em;
    cursor: pointer;
    color: #4d4d4d;
    font-size: 1rem;
    white-space: nowrap;
    border: 1px solid #56ab91;
    margin-bottom: 5px;
}

.recentReleaseWrapper .programmesTagWrapper .active {
    background: #56ab91;
    color: #fff;
    border: 1px solid var(--Secondary-Green, #56ab91);
}

.recentReleaseWrapper .newsCard {
    position: relative;
    border-radius: 0.5em;
    border: 1px solid #046f44;
    border-radius: 0.5em;
}

.recentReleaseWrapper .card {
    height: 100%;
    background: none;
}

.recentReleaseWrapper .card .card-body {
    border: none;
}

.recentReleaseWrapper .swiper-slide {
    height: auto;
}

.recentReleaseWrapper .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.4;
    display: flex;
}

.recentReleaseWrapper .newsCard .cardImg img {
    object-fit: cover;
    width: 100%;
    border-radius: 0.5em;
    animation: fadeIn2 1s;
    -webkit-animation: fadeIn2 1s;
    -moz-animation: fadeIn2 1s;
    -o-animation: fadeIn2 1s;
    -ms-animation: fadeIn2 1s;
}

.recentReleaseWrapper .newsCard .cardImg .tag {
    z-index: 2;
    position: absolute;
    bottom: 0.9375em;
    right: 0.9375em;
    padding: 0.375em 0.5625em;
    border: 1px solid #f2f2f3;
    border-radius: 4px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.8) 0, rgba(255, 255, 255, 0.55) 100%);
    box-shadow: 0 20px 40px 0 rgba(57, 124, 191, 0.1);
    backdrop-filter: blur(20px);
    color: #1d1d1d;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.75em;
    text-transform: uppercase;
}

.recentReleaseWrapper .newsCard .card-body {
    position: relative;
    padding: 1em;
    margin: 0;
}

.recentReleaseWrapper .newsCard .card-body .iconMain {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 0.625em;
}

.recentReleaseWrapper .newsCard .card-body .iconMain .mins {
    font-size: 0.875em;
    font-weight: 300;
    margin-right: auto;
}

.recentReleaseWrapper .newsCard .card-body .iconMain ul {
    position: relative;
    margin: 0;
    padding: 0;
}

.recentReleaseWrapper .newsCard .card-body .iconMain ul li {
    list-style: none;
    margin-right: 0.1875em;
    font-size: 1.375em;
    line-height: normal;
    display: inline-block;
}

.recentReleaseWrapper .newsCard .card-body .iconMain ul li a {
    color: #1d1d1d;
}

.recentReleaseWrapper .newsCard .card-body .iconMain ul li a.active,
.recentReleaseWrapper .newsCard .card-body .iconMain ul li a:hover {
    color: #4f887a;
}

.recentReleaseWrapper .newsCard .card-body h3 {
    font-size: 1.5em;
    margin: 0 0 0.1875em 0;
    padding: 0;
    font-weight: 600;
}

.recentReleaseWrapper .newsCard .card-body .date {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
    font-weight: 300;
    color: #000;
    text-align: start;
}

.recentReleaseWrapper .newsCard .card-body .textMain {
    position: relative;
    margin: 0.5em 0;
    padding: 0;
    font-size: 0.875em;
    color: #6b6b6b;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    height: 4em;
    margin-bottom: 20px;
}

.recentReleaseWrapper .newsCard .card-body .hashtag {
    position: relative;
    display: inline-block;
    padding: 0.1875em 0.9375em;
    border-radius: 0.25em;
    background: #eef7f4;
    color: #046f44;
    font-size: 0.875em;
    font-weight: 700;
}

.recentReleaseWrapper .button {
    border-radius: 6px;
    background-color: #046f44;
    border: 1px solid;
    color: white;
    margin: 0 1rem;
}

.recentReleaseWrapper .swiper-button-prev,
.swiper-button-next {
    margin-top: 0;
}

.recentReleaseWrapper .swiper-button-next {
    margin-top: 0;
}

.recentReleaseWrapper .swiper-button-prev {
    top: 1% !important;
}

.recentReleaseWrapper .swiper-button-next {
    top: 1% !important;
}

@media (max-width: 767.98px) {
    .recentReleaseWrapper .newsCardBig .card-body {
        width: auto;
    }

    .recentReleaseWrapper .newsCardBig .card-body .textMain {
        height: auto;
    }

    .recentReleaseWrapper .newsCard .card-body .textMain {
        height: auto;
    }

    .card-padding {
        padding: 0 0.75em !important;
    }

    .recentReleaseWrapper .scrollbar::-webkit-scrollbar-track {
        background-color: #e6e6e6;
    }

    .recentReleaseWrapper .scrollbar::-webkit-scrollbar {
        width: 5px;
        background-color: #e6e6e6;
    }

    .recentReleaseWrapper .scrollbar::-webkit-scrollbar-thumb {
        background-color: #046f44;
        border: 1px solid #046f44;
    }

    .recentReleaseWrapper .tagBox {
        position: relative;
        margin: 2.5em 0 0 0;
        padding: 0;
        list-style: none;
        height: 10.5em;
        overflow-y: scroll;
    }

    .recentReleaseWrapper .button {
        display: none;
    }
}

@keyframes fadeIn2 {
    0% {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

[dir='rtl'] .recentReleaseWrapper .title {
    padding: 0.9375em 0.25em 0.9375em 0em;
}

[dir='rtl'] .recentReleaseWrapper .title h3 {
    padding-left: 0;
    padding-right: 0.8125em;
}

[dir='rtl'] .recentReleaseWrapper .title h3::before {
    right: 0;
}

[dir='rtl'] .recentReleaseWrapper .programmesTagWrapper {
    padding: 0.9375em 0.25em 0.9375em 0em;
}

[dir='rtl'] .recentReleaseWrapper .programmesTagWrapper ul li {
    margin: 0.3125em 0em 0.3125em 0.625em;
}
