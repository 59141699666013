.heroMenuWrapper {
    position: relative;
    margin: 0;
    padding: 0;
    margin-bottom: 2em;
}

.heroMenuWrapper .heroImgMain {
    position: relative;
    width: 100%;
    height: 1000px;
    margin: 0;
    padding: 0 0 60px 0;
    overflow: hidden;
}

.heroMenuWrapper .heroImgMain img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.heroMenuWrapper .titleHldr {
    position: absolute;
    bottom: 140px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
}

.heroMenuWrapper .titleHldr h4 {
    font-weight: 400;
    font-size: 30px;
    color: var(--accent_color4);
    text-transform: uppercase;
    margin-bottom: 15px;
}

.heroMenuWrapper .titleHldr h2 {
    font-weight: 500;
    font-size: 64px;
    color: var(--accent_color4);
}

.heroMenuWrapper .rightMenuHldr {
    z-index: 2;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: 0px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5), var(--accent_color3)),
        var(--accent_color3);
    color: var(--accent_color4);
    width: 440px;
}

.heroMenuWrapper .rightMenuHldr ul {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    list-style: none;
    padding: 30px;
}

.heroMenuWrapper .rightMenuHldr ul li {
    margin: 0 0 30px 0;
    padding: 0;
    position: relative;
    color: var(--accent_color4);
    list-style: none;
}

.heroMenuWrapper .rightMenuHldr ul li:last-child {
    margin-bottom: 0;
}

.heroMenuWrapper .rightMenuHldr ul li.title {
    font-weight: 400;
    font-size: 24px;
    color: var(--accent_color4);
    text-transform: uppercase;
}

.heroMenuWrapper .rightMenuHldr ul li.links {
    font-weight: 500;
    font-size: 24px;
    color: var(--accent_color4);
    width: 100%;
    display: flex;
    align-items: center;
}

.heroMenuWrapper .rightMenuHldr ul li.links:hover {
    color: var(--accent_color1);
}

.heroMenuWrapper .rightMenuHldr ul li.links span {
    width: 100%;
}

.heroMenuWrapper .rightMenuHldr ul li.links i {
    margin-left: 10px;
}

.heroMenuDropHldr .p-dropdown {
    display: none;
}

@media (max-width: 575.98px) {
    .heroMenuWrapper {
        margin-bottom: 0;
    }

    .heroMenuWrapper .rightMenuHldr {
        display: none;
    }

    .heroMenuWrapper .heroImgMain {
        height: 300px;
        padding: 0;
    }

    .heroMenuWrapper .titleHldr {
        width: 90%;
        margin: 0 auto;
        bottom: 8%;
        left: 0;
    }

    .heroMenuWrapper .titleHldr h4 {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .heroMenuWrapper .titleHldr h2 {
        font-size: 30px;
    }

    .heroMenuDropHldr {
        margin-bottom: 2.5em;
    }

    .heroMenuDropHldr .p-dropdown {
        width: 100%;
        background: rgba(196, 163, 100, 1);
        color: var(--accent_color4);
        padding: 15px;
        border-radius: 0;
        border: none;
        display: flex;
        align-items: center;
    }

    .heroMenuDropHldr .p-dropdown .p-placeholder {
        color: var(--accent_color4);
        padding: 0;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .heroMenuDropHldr .p-dropdown .p-dropdown-label {
        padding: 0;
        color: var(--accent_color4);
    }

    .heroMenuDropHldr .p-dropdown .p-dropdown-trigger {
        color: var(--accent_color4);
    }
}
