.H5Pwrapper .heading {
    font-size: 1.5em;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
}

.H5Pwrapper .body p {
    font-size: 1em;
    font-weight: 400;
    text-align: center;
    display: flex;
    justify-content: center;
}
