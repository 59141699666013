.jobMainWrapper {
    position: relative;
    padding: 2em 0px;
    background-color: #f3f3f3;
}

.jobMainWrapper .form-group {
    position: relative;
    margin-bottom: 0;
}

.jobMainWrapper .p-inputtext {
    border: 2px solid var(--accent_color2);
}

.jobMainWrapper .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #e5e5e5;
}

.jobMainWrapper .p-inputtext:enabled:hover {
    border-color: #e5e5e5;
}

.jobMainWrapper .search-hldr {
    position: relative;
}

.jobMainWrapper .search-hldr .pi-search {
    position: absolute;
    top: 33%;
    left: 1.3em;
    color: rgba(67, 67, 67, 0.48);
    z-index: 1;
}

.jobMainWrapper .search1 {
    color: #bdbdbd;
    position: absolute;
    top: 15px !important;
    right: 1.5rem !important;
    font-size: 1em;
    z-index: 999;
}

.jobMainWrapper .search-hldr input {
    padding-left: 3.25em !important;
}

.jobMainWrapper .p-multiselect {
    width: 100%;
    border: 2px solid var(--accent_color2);
}

.jobMainWrapper .p-multiselect:not(.p-disabled):hover {
    border-color: #e5e5e5;
}

.jobMainWrapper .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none;
}

.jobSearchFilter {
    position: relative;
    padding: 24px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.jobSearchBoxWrapper {
    position: relative;
}

.jobSearchBoxWrapper .jobBoxHldr {
    position: relative;
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.jobSearchBoxWrapper .jobBoxHldr h5 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 700;
    color: var(--primary_color);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.jobSearchBoxWrapper .jobBoxHldr .txt-main-hldr {
    position: relative;
    flex: 1 1 auto;
    margin-bottom: 15px;
}

.jobSearchBoxWrapper .jobBoxHldr .txt-main-hldr .jobDag {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 700;
    color: var(--accent_color1);
}

.jobSearchBoxWrapper .jobBoxHldr .txt-main-hldr .timeLocHldr {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.jobSearchBoxWrapper .jobBoxHldr .txt-main-hldr .timeLocHldr .tagMain {
    background: var(--accent_color2);
    color: var(--accent_color1);
    padding: 4px 8px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.jobSearchBoxWrapper .jobBoxHldr .txt-main-hldr .timeLocHldr .tagMain:last-child {
    margin-right: 0;
}

.jobSearchBoxWrapper .jobBoxHldr .txt-main-hldr .timeLocHldr .tagMain i {
    font-size: 14px;
    margin-right: 8px;
}

.jobSearchBoxWrapper .jobBoxHldr .txt-main-hldr .applicationDate {
    position: relative;
    font-size: 14px;
    color: var(--accent_color1);
    font-weight: 400;
}

.jobSearchBoxWrapper .jobBoxHldr .btnMain {
    position: relative;
}

.jobSearchBoxWrapper .jobBoxHldr .btnMain .applyBtn {
    position: relative;
    height: 40px;
    border-radius: 6px;
    padding: 0 30px;
    border: none;
    background: var(--primary_color);
    font-size: 16px;
    font-weight: 500;
    color: var(--accent_color4);
}

.jobMainWrapper .p-paginator {
    background: none;
}

.jobMainWrapper .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--primary_color);
    border-color: var(--primary_color);
    color: var(--accent_color4);
}

@media (max-width: 767.98px) {
    .jobSearchFilter {
        padding: 15px;
        display: block;
    }

    .jobSearchBoxWrapper .jobBoxHldr h5 {
        -webkit-line-clamp: 2;
    }

    .jobSearchBoxWrapper .jobBoxHldr .txt-main-hldr .timeLocHldr .tagMain {
        padding: 3px 5px;
        font-size: 10px;
    }

    .jobSearchBoxWrapper .jobBoxHldr .txt-main-hldr .timeLocHldr .tagMain i {
        font-size: 10px;
    }

    .jobMainWrapper .search-hldr input {
        margin-bottom: 10px;
    }

    .jobMainWrapper .p-multiselect {
        margin-bottom: 10px;
    }

    .jobMainWrapper .search-hldr .pi-search {
        top: 28%;
    }
}
