.architectureSection {
    position: relative;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
}

.architectureSection .bigNewsImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0 0 0.9375em 0;
    padding: 0;
    height: 31.25em;
}

.architectureSection .bigNewsImg .backArrow {
    display: none;
}

.architectureSection .bigNewsImg img {
    height: 31.25em;
    object-fit: cover;
    width: 100% !important;
}

.architectureSection .date {
    color: #7c797e;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
}

.architectureSection .line {
    color: #3182c3;
    margin: 1rem 0 0 0;
    width: 15%;
    border-top: 7px solid;
}

.architectureSection .title h3 {
    color: #3182c3;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 35.2px;
    font-size: 2em;
}

.architectureSection .text p {
    color: #39353d;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 2rem 0 0 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
}

.architectureSection .button {
    border-radius: 4px;
    background-color: #046f44;
    border: 1px solid;
    color: white;
}

.architectureSection .width {
    width: 70%;
    margin: 0 auto;
}

@media (max-width: 767.98px) {
    .architectureSection .line {
        width: 50%;
    }

    .architectureSection .title h3 {
        font-size: 30px;
    }

    .architectureSection .bigNewsImg img {
        height: 16.25em;
    }

    .architectureSection .bigNewsImg {
        height: 16.25em;
    }

    .architectureSection .width {
        width: 100%;
        margin: 0 auto;
    }
}

[dir='rtl'] .architectureSection .button {
    float: right;
}
