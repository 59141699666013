.p-galleria-item {
    /* height: 70vh !important; */
}

.p-galleria .p-galleria-item-nav {
    background: none !important;
    border: 2px solid #666 !important;
}

.p-galleria-item-container .p-galleria-item-prev::before {
    content: '\e91a';
    font-family: 'PrimeIcons';
    color: #666 !important;
    font-size: 30px;
}

.p-galleria-item-container .p-galleria-item-next::before {
    content: '\e91b';
    font-family: 'PrimeIcons';
    color: #666 !important;
    font-size: 30px;
}

.p-galleria-item-container .p-galleria-item-prev svg,
.p-galleria-item-container .p-galleria-item-next svg {
    display: none !important;
}

.p-galleria-thumbnail-wrapper {
    margin: -50px auto 0;
    z-index: 1;
    width: auto;
}

.p-galleria .p-galleria-thumbnail-container {
    padding: 0.5rem !important;
    background: #ffffff !important;
    border-radius: 10px;
    box-shadow: 5px 4px 10px 0px rgba(68, 68, 68, 0.1);
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    display: none;
}

.p-galleria-thumbnail-item {
    padding: 0px 5px;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content img {
    width: 180px;
}

.p-galleria-thumbnail-items {
    transform: none !important;
    transition: none !important;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
    box-shadow: none !important;
}

.p-galleria-thumbnail-wrapper {
    overflow: visible !important;
}

.video-container {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 10px;
    overflow: hidden;
}

.video-container iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.thumbnail-container {
    position: relative;
    width: 100%;
    aspect-ratio: 1.77;
    overflow: hidden;
}

.thumbnail-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 767.98px) {
    .p-galleria-item {
        height: 34vh !important;
        align-items: start;
    }

    .p-galleria-thumbnail-wrapper {
        width: 87%;
    }

    .p-galleria .p-galleria-item-nav {
        width: 2rem !important;
        height: 2rem !important;
    }

    .p-galleria-item-container .p-galleria-item-prev::before {
        font-size: 14px;
    }

    .p-galleria-item-container .p-galleria-item-next::before {
        font-size: 14px;
    }

    .p-galleria-item-nav {
        top: 40% !important;
    }
}
