.instantSearch {
    padding: 3em;
}

.instantSearch .search-main {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 36px;
}

.instantSearch .search-main .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 38.5px;
    margin-left: 0;
    margin-bottom: 1em;
    color: #000;
}

.instantSearch .date {
    font-size: 0.75em;
}

.instantSearch .grid-filters-main {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
}

.instantSearch .grid-filter-label {
    font-weight: 400;
    font-size: 14px;
    color: #828282;
    padding: 1em;
    margin-bottom: 2px;
}

.instantSearch .search-main .search-box {
    position: relative;
}

.instantSearch .search-main .search-box .p-inputtext {
    width: 100%;
    background: var(--accent_color2);
    border: none;
    border-radius: 15px;
    color: var(--accent_color1);
}

.instantSearch .search-main .search-box .p-inputtext.p-inputtext-lg {
    padding: 0.9375rem 5rem !important;
}

.instantSearch .search-main .search-box .p-inputtext:enabled:focus {
    box-shadow: none;
}

.instantSearch .search-main .search-box .search-icon {
    position: absolute;
    top: 16px;
    color: #00558c;
    left: 20px;
    font-size: 27px;
    padding: 0px 16px 0px 0;
    border-right: 1px solid var(--accent_color3);
}

.instantSearch .search-main .search-box .close-icon {
    position: absolute;
    top: 16px;
    color: var(--accent_color1);
    right: 20px;
    font-size: 27px;
}

.instantSearch .grid-list-view .active {
    color: var(--primary_color);
}

.instantSearch .grid-list-view .active {
    color: var(--primary_color);
}

.instantSearch .grid-icon:hover {
    text-shadow: 0 0 5px #56ab91, 0 0 25px #56ab91, 0 0 50px #56ab91, 0 0 100px #56ab91;
}

.instantSearch .list-icon:hover {
    text-shadow: 0 0 5px #56ab91, 0 0 25px #56ab91, 0 0 50px #56ab91, 0 0 100px #56ab91;
}

.instantSearch .filters-main {
    margin-top: 2em;
}

.instantSearch .filters-main .filters-heading-main {
    display: flex;
}

.instantSearch .filters-main .filters-heading-main .heading {
    color: #828282;
    font-weight: 400;
    font-size: 17px;
}

.instantSearch .clear-filter {
    margin-left: auto;
    color: #00558c;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 2px;
}

.instantSearch .filters-items-main .filters-item .p-multiselect {
    border: none;
}

.instantSearch .filters-items-main .filters-item .p-multiselect .p-multiselect-label {
    padding: 0;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    overflow-y: auto;
}

.instantSearch .filters-items-main .filters-item .p-multiselect .p-multiselect-trigger {
    color: #282828;
    width: 2rem;
    position: relative;
    top: -1px;
}

.instantSearch .details-main {
    margin-top: 2em;
    border-left: 1px solid #e2e2e2;
    padding-left: 2em;
}

.instantSearch .details-main .total-records {
    font-weight: 500;
    font-size: 14px;
    color: #828282;
}

.instantSearch .details-main .sorting-main .sort-title {
    font-weight: 400;
    color: #000;
    font-size: 14px;
    justify-content: end;
    display: flex;
}

.instantSearch .p-dropdown .p-dropdown-trigger {
    width: 16px;
    color: var(--accent_color1);
}

.instantSearch .details-main .sorting-main .sort-title .p-dropdown {
    border: none !important;
}

.instantSearch .details-main .sorting-main .sort-title .p-inputtext {
    padding: 0px 13px !important;
}

.instantSearch
    .details-main
    .sorting-main
    .sort-title
    .p-inputtext
    .p-dropdown
    .p-dropdown-trigger {
    width: auto;
}

.instantSearch .details-main .sorting-main .sort-title .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none !important;
    border: none !important;
}

.instantSearch .details-main .sorting-main .sort-title .p-dropdown .p-dropdown-label {
    color: #000000;
    font-weight: 400;
    font-size: 14px;
}

.instantSearch .article-list {
    position: relative;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 2em;
}

.instantSearch .article-list.article-grid {
    position: relative;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 2em;
    display: grid;
    grid-template-columns: 32% 32% 32%;
    column-gap: 2%;
}

.instantSearch .article-list.article-grid .article-card {
    display: block;
}

.instantSearch .article-list.article-grid .article-card .article-img {
    width: 100%;
    border-radius: 7px 7px 0px 0px;
}

.instantSearch .article-list.article-grid .article-card .article-body {
    min-height: 160px;
}

.instantSearch .article-list .article-card {
    border: 1px solid rgba(227, 228, 232, 1);
    background: #fff;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    border-radius: 10px;
    margin-bottom: 30px;
    display: flex;
}

.instantSearch .article-list .article-card .article-img {
    aspect-ratio: 1.5;
    overflow: hidden;
    border-radius: 7px 0px 0px 7px;
    flex-shrink: 0 !important;
}

.instantSearch .article-list .article-card .article-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.instantSearch .article-list .article-card .article-body {
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.instantSearch .article-list .article-card .article-body .title-main {
    flex: 1 1 auto;
}

.instantSearch .article-list .article-card .article-body .title-main h5 {
    color: #00558c;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 5px;
}

.instantSearch .article-list .article-card .article-body .title-main h2 {
    color: #171821;
    font-weight: 400;
    font-size: 18px;
}

.instantSearch .article-list .article-card .article-body .article-tags {
    display: flex;
    align-items: center;
    color: #00558c;
    font-size: 0.75em;
}

.instantSearch .article-list .article-card .article-body .article-tags span {
    font-size: 14px;
    color: #83858f;
    padding-left: 5px;
    border-left: 1px solid #83858f;
    margin-left: 5px;
}

.instantSearch .filters-wrapper-mobile {
    display: none;
}

.instantSearch .filters-hldr {
    display: none;
}

.instantSearch .search .ais-SearchBox-input {
    background-color: rgba(8, 9, 97, 0.05) !important;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #666;
    border-radius: 0;
    padding: 2em 3em;
}

.instantSearch .ais-SearchBox-form::before {
    margin-top: 0;
}

.instantSearch .ais-SearchBox-loadingIndicator,
.instantSearch .ais-SearchBox-reset {
    top: 75%;
    right: 4rem;
}

.instantSearch .search-button {
    position: absolute;
    top: 18px;
    right: 32px;
    border: 0;
    height: 57px;
    background-color: rgb(8 76 97/ 1);
    width: 52px;
}

.instantSearch .search-button .icon {
    color: #fff;
}

.instantSearch .ais-Hits-item,
.instantSearch .ais-InfiniteHits-item {
    box-shadow: none;
}

.instantSearch .search-container {
    border: none;
    border-radius: 15px;
    border: 1px solid #e3e4e8;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}

.instantSearch .card .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.4;
    display: flex;
    border-radius: 15px 15px 0px 0px;
}

.instantSearch .card .cardImg img {
    width: 100%;
    object-fit: cover;
    border-radius: 0px 15px 0px 0px;
    animation: fadeIn2 1s;
    -webkit-animation: fadeIn2 1s;
    -moz-animation: fadeIn2 1s;
    -o-animation: fadeIn2 1s;
    -ms-animation: fadeIn2 1s;
}

.instantSearch .showMore {
    font-size: 1.1875em;
    font-weight: 400;
}

.instantSearch .showLess {
    font-size: 1.1875em;
    font-weight: 400;
}

.instantSearch .gridshowMore {
    font-size: 1.1875em;
    font-weight: 400;
    text-align: center;
}

.instantSearch .gridshowLess {
    font-size: 1.1875em;
    font-weight: 400;
    text-align: center;
}

.instantSearch .search-title {
    font-size: 15px;
}

.instantSearch .search-image {
    width: 100%;
    border-radius: 15px 15px 0 0;
}

.instantSearch .body-container {
    padding: 1em;
}

.instantSearch .body-container .microsite {
    color: var(--Primary-Green, #046f44);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.2px;
    letter-spacing: 2.1px;
    text-transform: uppercase;
}

.instantSearch .body-container .search-title {
    color: #171821;
    margin: 1em 0 2em 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.instantSearch .body-container .authorDateMain {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 0.75em;
    text-transform: uppercase;
}

.instantSearch .body-container .authorDateMain .author {
    color: var(--Primary-Green, #046f44);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.instantSearch .body-container .authorDateMain .date {
    color: #83858f;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.instantSearch .ais-Pagination {
    display: flex;
    justify-content: center;
}

.instantSearch .ais-Panel {
    margin-top: 2em;
}

.width-96 {
    max-width: 96%;
}

.p-multiselect-item span {
    width: 100%;
}

.count-pill .name {
    margin-right: auto;
}

.count-pill .count {
    background: #dee2e6;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    border-radius: 50px;
}

.genericfilters .more-button {
    margin-left: auto;
    background: #dee2e6;
    color: #000;
    padding: 2px 1em;
    border-radius: 10px;
    border: none;
}

.filters-items-main .p-multiselect-label {
    white-space: normal;
}

.filters-items-main .p-multiselect.p-multiselect-chip .p-multiselect-token {
    margin: 5px;
}

.filters-items-main .p-multiselect-label-container {
    width: auto;
}

.filters-items-main .p-multiselect {
    overflow: auto;
}

.filters-items-main .p-multiselect-label {
    text-overflow: unset;
}

.filters-items-main .filters-item .p-dropdown-label {
    padding: 0 !important;
}

.date-button {
    padding: 0.5em 1em;
    background-color: white;
    color: var(--primary_color);
    border: 1px solid #83858f;
    border-radius: 10px;
}

.date-button:hover {
    background-color: var(--primary_color);
    color: white;
}

.ais-ClearRefinements-button:disabled[disabled],
.ais-InfiniteHits-loadMore:disabled[disabled],
.ais-InfiniteHits-loadPrevious:disabled[disabled],
.ais-Pagination-item--disabled .ais-Pagination-link,
.ais-GeoSearch-redo--disabled,
.ais-GeoSearch-reset--disabled,
.ais-VoiceSearch-button:disabled {
    display: none;
}

.ais-ClearRefinements-button {
    display: none !important;
}

.custom-searchBox {
    width: 100%;
    padding: 14px;
}

.instantSearch .grid-filters-items-main {
    display: flex;
    align-items: center;
    position: relative;
    padding: 1em;
}

.instantSearch .grid-filters-items-main .p-multiselect-label-container {
    width: auto;
}

.instantSearch .grid-filters-items-main .filters-item .p-multiselect .p-multiselect-label {
    overflow: hidden;
}

.instantSearch .p-dropdown {
    width: auto !important;
    border: none;
}

.instantSearch .p-dropdown .p-dropdown-label.p-placeholder {
    padding: 0 13px;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    display: flex;
}

.instantSearch .p-dropdown .p-dropdown-trigger {
    color: #000000;
    width: 2rem;
    position: relative;
    top: -1px;
}

.instantSearch .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: none;
}

.instantSearch .grid-list-main {
    border-left: none;
    padding-left: 0;
}

.instantSearch .grid-filters-main .spacer {
    flex: 1;
}

.instantSearch .grid-filters-main .grid-sort {
    display: flex;
    align-items: center;
}

.instantSearch .grid-filters-main .grid-sort .sort-title {
    display: flex;
    align-items: center;
    margin-left: 30px;
}

.instantSearch .grid-filters-main .grid-sort .sort-title .p-dropdown {
    border: none;
}

.instantSearch .grid-search-main {
    border-bottom: 0;
    padding-bottom: 0;
}

.instantSearch .grid-filters-main .grid-sort .sort-title {
    font-weight: 400;
    color: #000;
    font-size: 14px;
}

.instantSearch .clear-filter-mobile {
    display: none;
}

.error-message {
    color: red;
}

.spaces::after {
    content: ' ';
    white-space: pre;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    top: 18px !important;
}

@media (max-width: 767.98px) {
    .instantSearch .grid-filters-main {
        display: none;
    }

    .instantSearch .clear-filter-mobile {
        margin-left: auto;
        color: var(--primary_color);
        font-weight: 400;
        font-size: 16px;
        display: block;
        text-align: end;
    }

    .instantSearch {
        padding: 1em 0;
    }

    .filters-wrapper {
        display: none;
    }

    .instantSearch .filters-wrapper-mobile .filters-main .filter-btn {
        padding: 1em;
    }

    .instantSearch .filters-wrapper-mobile .filters-main .filter-btn .button {
        background: white;
        padding: 8px 3em;
        border-radius: 10px;
    }

    .instantSearch .contPopup {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: #fff;
        z-index: 1000;
    }

    .instantSearch .contPopup .filterBox {
        position: relative;
        border-bottom: 1px solid #e0e0e0;
        align-items: center;
        margin: 0 10px;
        padding: 1em 0;
        display: flex;
    }

    .instantSearch .contPopup .filterBox .filterHeading {
        font-weight: 700;
        color: #000;
        font-size: 24px;
        width: 100%;
    }

    .instantSearch .contPopup .filterBox .clear-all-txt {
        margin-left: auto;
        font-weight: 400;
        color: #000;
        font-size: 16px;
        border-bottom: 1px solid #000;
        margin-left: 13em;
    }

    .instantSearch .contPopup .filterBox .close-icon {
        font-size: 20px;
        display: flex;
        justify-content: end;
    }

    .instantSearch .contPopup .tag-filters-main {
        border-bottom: 1px solid #e0e0e0;
        margin: 0 10px;
        padding-bottom: 1em;
    }

    .instantSearch .contPopup .tag-filters-main .p-multiselect {
        display: flex;
    }

    .p-multiselect:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }

    .instantSearch .contPopup .tag-filters-main .p-multiselect {
        border: none;
    }

    .instantSearch .contPopup .tag-filters-main .p-multiselect .p-multiselect-label {
        font-weight: 700;
        color: #000;
        font-size: 16px;
    }

    .filter-apply-btn {
        margin-top: 3em;
        display: flex;
        justify-content: end;
    }

    .filter-apply-btn .button {
        padding: 0.825em 3em;
        background-color: #00558c;
        color: #fff;
        border: none;
    }

    .visible {
        display: block;
    }

    .instantSearch .p-dialog .p-dialog-header {
        display: none;
    }

    .instantSearch .article-list .article-card .article-img {
        width: 130px;
        height: 130px;
    }

    .instantSearch .article-list .article-card .article-body {
        padding: 10px;
    }

    .instantSearch .article-list .article-card .article-body .title-main h2 {
        font-size: 14px;
    }

    .instantSearch .article-list .article-card .article-body .title-main h5 {
        font-size: 12px;
    }

    .instantSearch .filters-hldr {
        position: relative;
        margin: 0;
        padding: 0 0 1em 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
    }

    .instantSearch .filters-wrapper-mobile {
        position: relative;
        margin: 0;
        padding: 0;
        display: block;
    }

    .instantSearch .filters-wrapper-mobile .filter-btn {
        border: 1px solid;
        background: white;
        padding: 0.5em 1em;
        border-radius: 4px;
    }

    .instantSearch .search-main {
        border-bottom: none;
    }

    .instantSearch .sort-title {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .instantSearch .sort-title .p-dropdown {
        border: none !important;
        margin-top: 3px;
        margin-left: 2px;
    }

    .instantSearch .details-main {
        padding: 0;
        border: none;
    }

    .instantSearch .sorting-main {
        display: none;
    }

    .instantSearch .grid-list-view {
        display: none;
    }
}
