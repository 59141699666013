.iconLinks2Wrapper {
    position: relative;
    padding-top: 3em;
}

.iconLinks2Wrapper .titleMain {
    position: relative;
    background: none;
    padding: 0.9375em 1.25em 0.9375em 0;
    display: flex;
    align-items: center;
}

.iconLinks2Wrapper .titleMain h3 {
    color: var(--primary_color);
    font-size: 2em;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    padding-left: 0.8125em;
}

.iconLinks2Wrapper .titleMain h3::before {
    content: '';
    position: absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    background: var(--primary_color);
}

.iconLinks2Wrapper .newsCardHldr {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin: 0 8%;
}

.iconLinks2Wrapper .newsCardHldr .newsCard {
    position: relative;
    padding: 20px;
    margin-left: 1em;
    border-radius: 0;
    background: none;
    height: 100%;
    border-right: 2px solid var(--accent_color2);
    margin: 0;
    align-items: center;
}

.iconLinks2Wrapper .newsCardHldr .newsCard:last-child {
    border-right: none;
}

.iconLinks2Wrapper .newsCard .newslogoImg {
    position: relative;
    width: 50px;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    height: 50px;
}

.iconLinks2Wrapper .newsCardHldr .newslogoImg img {
    height: 100%;
    width: auto;
    object-fit: var(--object-fit);
    margin: 0 auto;
}

.iconLinks2Wrapper .newsCardHldr .newsTitle {
    color: var(--accent_color1);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    width: 100%;
}

@media (max-width: 767.98px) {
    .iconLinks2Wrapper .newsCardHldr {
        display: grid;
        grid-template-columns: 100%;
        margin: 0 0;
    }

    .iconLinks2Wrapper .newsCardHldr .newsCard {
        border-right: none;
    }
}

[dir='rtl'] .iconLinks2Wrapper .titleMain {
    padding: 0.9375em 0.25em 0.9375em 0;
}

[dir='rtl'] .iconLinks2Wrapper .titleMain h3 {
    padding-right: 0.8125em;
    padding-left: 0;
}

[dir='rtl'] .iconLinks2Wrapper .titleMain h3::before {
    right: 0;
}

[dir='rtl'] .iconLinks2Wrapper .newsCardHldr .newsCard {
    border-left: 2px solid var(--accent_color2);
    border-right: none;
}

[dir='rtl'] .iconLinks2Wrapper .newsCardHldr .newsCard:last-child {
    border-left: none;
}
