.infoPanel2Wrapper {
    position: relative;
    margin: 0;
    padding-top: 3em;
    padding-bottom: 3em;
}

.infoPanel2Wrapper h1 {
    font-size: 3.5em;
    color: var(--primary_color);
    position: relative;
    font-weight: 700;
}

.infoPanel2Wrapper p {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: var(--accent_color1);
    padding: 0 50px;
}

@media (max-width: 767.98px) {
    .infoPanel2Wrapper {
        padding: 20px 0;
    }

    .infoPanel2Wrapper p {
        padding: 0;
    }
}
