.gallerySection {
    position: relative;
}

.gallerySection .maintitle {
    color: #282828;
    font-weight: 600;
    display: block;
    position: relative;
    text-transform: capitalize;
}

.gallerySection .search-hldr {
    position: relative;
}

.gallerySection .fa-close {
    color: #bdbdbd;
    position: absolute;
    top: 41px;
    right: 2.5rem;
    font-size: 1em;
    z-index: 999;
}

.gallerySection .search-hldr input {
    padding-left: 2.25em;
}

.gallerySection .search-hldr i {
    position: absolute;
    top: 0.625em;
    left: 0.625em;
    color: rgba(67, 67, 67, 0.48);
    z-index: 1;
}

.gallerySection .button {
    border-radius: 5px;
    background-color: #56ab91;
    border: 1px solid;
    color: white;
    padding: 8px 40px;
}

.gallerySection .p-multiselect {
    position: relative;
    font-size: 1em;
    height: 2.5em;
    border-radius: 0.375em;
    border: 0;
    background: #fff;
    box-shadow: none;
}

.gallerySection .p-multiselect .p-multiselect-label {
    padding: 0.5rem 1rem;
}

.gallerySection .p-multiselect .p-multiselect-label.p-placeholder {
    color: #babfc4;
}

.gallerySection .card-padding {
    padding: 0 27px 0 27px !important;
}

.gallerySection .padding {
    padding: 0 !important;
}

.gallerySection .titleMain {
    position: relative;
    background: #fff;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    padding: 0.9375em 1.25em;
    display: flex;
    align-items: center;
}

.gallerySection .titleMain h3 {
    color: #046f44;
    font-weight: 700;
    display: block;
    position: relative;
    padding-left: 0.8125em;
}

.gallerySection .titleMain h3::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.25em;
    border-radius: 1em;
    height: 100%;
    background: #046f44;
}

.gallerySection .newsCardBig {
    position: relative;
    background: #fff;
    border-radius: 0.9375em;
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.03);
    border: 1px solid #e5eaf4;
}

.gallerySection .newsCardBig .NewsImg {
    position: relative;
    width: 100%;
    height: 27.75em;
}

.gallerySection .newsCardBig .NewsImg img {
    height: 27.75em;
    width: 100%;
    border-radius: 22px;
    padding: 0.75em;
    object-fit: cover;
}

.gallerySection .newsCardBig .card-body {
    position: relative;
    padding: 1.875em 0;
    margin: 0 auto;
    width: 80%;
}

.gallerySection .newsCardBig .card-body .card-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    margin: -9px 20px 10px;
}

.gallerySection .newsCardBig .card-body .card-title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
}

.gallerySection .newsCardBig .card-body .card-text {
    font-style: normal;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    line-height: normal;
}

.gallerySection .newsCard {
    position: relative;
    padding: 0.5em;
    border-radius: 0.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    margin: 1em;
    background: #fff;
    height: 28rem;
}

.gallerySection .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 14.5em;
}

.gallerySection .newsCard .cardImg img {
    height: 14.5em;
    /* object-fit: cover; */
    width: 100%;
    border-radius: 0;
    padding: 0px 5px 5px 5px;
}

.gallerySection .newsCard .sub-card-body {
    position: relative;
    padding: 0px 5px 0 5px;
    margin: 0;
}

.gallerySection .newsCard .dateName {
    position: relative;
    padding: 5px;
    display: flex;
    align-items: center;
}

.gallerySection .newsCard .dateName .name {
    font-size: 1em;
    font-weight: 500;
    color: #1d1d1d;
    margin-right: 0.9375em;
}

.gallerySection .newsCard .dateName .date {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 0.875em;
    color: #666;
}

.gallerySection .newsCard .dateName .date .dot {
    width: 0.5em;
    height: 0.5em;
    background-color: #046f44;
    margin-right: 0.625em;
    border-radius: 0.625em;
}

.gallerySection .newsCard .sub-card-body h3 {
    font-size: 1.1em;
    margin: 0 0 0.1875em 0;
    padding: 0;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.gallerySection .newsCard .sub-card-body .textMain {
    position: relative;
    margin: 0.5em 0;
    padding: 0;
    font-size: 0.875em;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: normal;
}

.gallerySection .newsCard .text {
    color: #046f44;
    font-weight: 700;
}

.gallerySection .p-paginator {
    background: none;
}

.gallerySection .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: #fff;
    border-color: #56ab91;
    background: #56ab91;
}

.gallerySection .p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 2px 9px 2px 9px;
}

.gallerySection .p-multiselect.p-multiselect-chip .p-multiselect-token {
    background: #56ab91;
    color: #fff;
}

.gallerySection .search1 {
    top: 12px !important;
    left: 3rem !important;
    right: 1.5rem !important;
    left: auto !important;
    float: right;
}

.gallerySection .fa-close {
    color: #bdbdbd;
    position: absolute;
    top: 41px;
    right: 2.5rem;
    font-size: 1em;
    z-index: 999;
}

@media (max-width: 767.98px) {
    .gallerySection .search-hldr {
        margin-bottom: 10px;
    }

    .gallerySection .button {
        margin-top: 10px;
        padding: 10px 7.9rem;
    }

    .gallerySection .newsCard {
        height: 25em;
    }
}
