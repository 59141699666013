.pageNotFoundSection {
    position: relative;
    padding-bottom: 4em;
    padding-top: 4em;
}

.pageNotFoundSection .mainContent {
    justify-content: center;
}

.pageNotFoundSection .mainContent .text-muted {
    font-size: 1.2em;
    font-weight: 800;
    letter-spacing: 0.15em;
    text-align: left;
    color: #bdbdbd !important;
    margin-top: 2em;
}

.pageNotFoundSection .mainContent .heading {
    font-size: 48px;
    font-weight: 800;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 10px;
}

.pageNotFoundSection .mainContent .body {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 10px;
}

.pageNotFoundSection .mainContent .search {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #046f44;
    text-decoration: underline;
    margin-top: 30px;
}

.pageNotFoundSection .mainContent .searchMain {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.pageNotFoundSection .mainContent .searchMain .search-icon i {
    color: #4bc8be;
}

.pageNotFoundSection .mainContent .searchMain .searchBox {
    position: relative;
    min-width: 15.625em;
}

.pageNotFoundSection .mainContent .searchMain .searchBox .search-form {
    display: flex;
    align-items: center;
}

.pageNotFoundSection .mainContent .searchMain .searchBox .search-form input {
    border: 0;
    font-size: 0.875em;
    color: #bdbdbd;
    border: none;
    padding: 0.75em 2.375em 0.75em 0.9375em;
    border-radius: 8px;
    transition: 0.3s;
    width: 100%;
    background: #f6f6f6;
}

.pageNotFoundSection .mainContent .searchMain .searchBox .search-form button {
    border: 0;
    padding: 0;
    margin-left: -45px;
    background: 0 0;
}

.pageNotFoundSection .mainContent .searchMain .searchBox .search-form button i {
    color: #4bc8be;
}

.pageNotFoundSection .mainContent .button {
    border-radius: 6px;
    background-color: #046f44;
    border: none;
    color: white !important;
    display: block;
    padding: 0.625rem 3rem 0.625rem 3rem;
}

.pageNotFoundSection .mainContent .btn-text {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: white;
}

@media (max-width: 767.98px) {
    .pageNotFoundSection .mainContent .heading {
        margin-top: 0;
        font-size: 20px;
    }

    .pageNotFoundSection .mainContent .text-muted {
        margin-top: 0;
    }

    .pageNotFoundSection .mainContent .body {
        font-size: 14px;
        margin-top: 5px;
    }

    .pageNotFoundSection .mainContent .search {
        font-size: 14px;
        margin-top: 15px;
    }

    .pageNotFoundSection .mainContent .button {
        padding: 0.325rem 1rem 0.325rem 1rem;
    }
}
