.programme-std-wrapper {
    position: relative;
    margin: 2.75em 0 3.75em 0;
    background-color: var(--accent_color2);
}

.programme-std-wrapper .job-img {
    position: relative;
    width: 100%;
    max-height: 17.5em;
    overflow: hidden;
    border-radius: 0.9375em;
    box-shadow: 5px 15px 20px rgba(0, 0, 0, 0.15);
}

.programme-std-wrapper .job-img img {
    width: 100%;
    border-radius: 0.9375em;
}

.programme-std-wrapper .jobTxt-hldr {
    position: relative;
}

.programme-std-wrapper .jobTxt-hldr .prod-dev {
    font-size: 1em;
    font-weight: 400;
    color: #4f4f4f;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin: 0 0 1.25em;
}

.programme-std-wrapper .jobTxt-hldr .job-title {
    position: relative;
    padding-left: 1.5625em;
    margin-bottom: 1.25em;
}

.programme-std-wrapper .jobTxt-hldr .job-title .line {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0.5em;
    background: #046f44;
    border-radius: 1.25em;
}

.programme-std-wrapper .jobTxt-hldr .job-title h2 {
    font-weight: 600;
    font-size: 2.25em;
    color: #393939;
}

.programme-std-wrapper .jobTxt-hldr p {
    font-size: 1.375rem;
    color: #4f4f4f;
    font-weight: 400;
}

@media (max-width: 767.98px) {
    .programme-std-wrapper .job-img {
        height: auto;
        border-radius: 0;
        box-shadow: none;
    }

    .programme-std-wrapper .job-img::after {
        content: '';
        z-index: 10;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: none;
    }

    .programme-std-wrapper .job-img img {
        border-radius: 0;
    }

    .programme-std-wrapper .jobTxt-hldr .prod-dev {
        margin-top: 0.9375em;
    }

    .programme-std-wrapper .jobTxt-hldr {
        margin-top: 1em;
    }

    .programme-std-wrapper .jobTxt-hldr .job-title h2 {
        font-size: 2.25em;
    }

    .programme-std-wrapper .jobTxt-hldr p {
        font-size: 1.125rem;
    }
}
