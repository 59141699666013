.InfoPanelBox {
    position: relative;
    margin: 0;
    padding: 50px;
    box-shadow: 5px 4px 10px 0px rgba(68, 68, 68, 0.1);
    border-radius: 30px;
    border: 1px solid var(--accent_color2);
    display: flex;
    margin-bottom: 2em;
}

.InfoPanelBox .InfologoMain {
    position: relative;
    width: 228px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
}

.InfoPanelBox .InfologoMain img {
    width: 100%;
    height: auto;
}

.InfoPanelBox .contMain {
    position: relative;
    margin: 0;
    padding: 0 0 0 50px;
    width: 100%;
}

.InfoPanelBox .contMain p {
    font-weight: 400;
    color: var(--accent_color1);
    font-size: 18px;
    margin-bottom: 30px;
}

.InfoPanelBox .contMain .infoLink {
    position: relative;
    display: inline-block;
    color: var(--primary_color);
    font-weight: 400;
    font-size: 18px;
    align-items: center;
}

.InfoPanelBox .contMain .infoLink i {
    margin-left: 8px;
}

[dir="rtl"] .InfoPanelBox .contMain {
    padding: 0 50px 0 0px;
    text-align: start;
}

@media (max-width: 767.98px) {
    .InfoPanelBox {
        padding: 24px;
        display: block;
        border-radius: 24px;
    }

    .InfoPanelBox .contMain {
        padding: 50px 0 0 0;
    }
}
