.HeroStaticWrapper {
    position: relative;
    margin: 0;
    height: 354px;
    overflow: hidden;
}

.HeroStaticWrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.HeroStaticWrapper::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 40.84%,
        rgba(255, 255, 255, 0.692913) 70.07%,
        #ffffff 92.81%
    );
}

.HeroStaticWrapper .contMain {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0%, -50%);
    z-index: 1;
    margin: 0 auto;
}

.HeroStaticWrapper .contMain h2 {
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 12px;
    color: var(--accent_color1);
}

.HeroStaticWrapper .contMain p {
    font-size: 20px;
    font-weight: 400;
    color: var(--accent_color1);
}

@media (max-width: 767.98px) {
    .HeroStaticWrapper .contMain h2 {
        font-size: 24px;
        font-weight: 500;
    }

    .HeroStaticWrapper .contMain p {
        font-size: 16px;
        font-weight: 500;
    }

    .HeroStaticWrapper::after {
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 40.84%,
            rgba(255, 255, 255, 0.692913) 70.07%,
            #ffffff 92.81%
        );
    }

    .HeroStaticWrapper .contMain {
        bottom: 0%;
        top: auto;
        padding: 0 9%;
    }
}
