.CountdownWrapper {
    position: relative;
    padding: 30px;
}

.CountdownWrapper .CountlogoMain {
    position: relative;
    width: 228px;
    padding: 10px;
}

.CountdownWrapper .CountlogoMain img {
    width: 100%;
    height: auto;
}

.CountdownWrapper .countDecHldr {
    position: relative;
}

.CountdownWrapper .countDecHldr h2 {
    position: relative;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 15px;
    color: var(--accent_color1);
}

.CountdownWrapper .countDecHldr .line {
    position: relative;
    width: 80px;
    height: 2px;
    margin: 0px 0;
    padding: 0;
    background: var(--primary_color);
}

.CountdownWrapper .countDecHldr p {
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
    color: var(--accent_color1);
}

.CountdownWrapper .countBoxHldr {
    position: relative;
    margin-top: 24px;
}

.counterBoxHldr {
    position: relative;
    margin-top: 26px;
}

.counterBoxHldr .countBoxMain {
    position: relative;
    display: inline-block;
    width: 118px;
    height: auto;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    background: var(--primary_color);
    overflow: hidden;
    margin-right: 20px;
}

.counterBoxHldr .countBoxMain .countMain {
    width: 100%;
    padding: 20px 10px;
    text-align: center;
    font-weight: 400;
    font-size: 40px;
    color: var(--accent_color4);
    line-height: 1;
}

.counterBoxHldr .countBoxMain .disMain {
    padding: 5px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: var(--primary_color);
    text-transform: uppercase;
    background: #fff;
}

.counterBoxHldr .countBoxMain:last-child {
    margin-right: 0;
}

@media (max-width: 767.98px) {
    .CountdownWrapper {
        padding: 0 0px 30px 0;
        text-align: center;
    }

    .CountdownWrapper .countDecHldr {
        text-align: center;
        margin-top: 30px;
    }

    .CountdownWrapper .countDecHldr .line {
        margin: 0 auto;
    }

    .counterBoxHldr .countBoxMain {
        width: 72px;
        border-radius: 8px;
        margin-right: 15px;
    }

    .counterBoxHldr .countBoxMain .countMain {
        font-size: 24px;
        padding: 12px 5px;
    }

    .counterBoxHldr .countBoxMain .disMain {
        font-size: 10px;
    }

    .CountdownWrapper .CountlogoMain {
        margin: 0 auto;
    }

    .CountdownWrapper .CountlogoMain {
        width: 120px;
    }
}
