.HeroStaticMain {
    position: relative;
    height: 85vh;
}

.HeroStaticMain .sliderBox {
    height: 100%;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: top center !important;
    background-size: cover !important;
}

.HeroStaticMain .sliderBox img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.HeroStaticMain .sliderBox .textMain {
    width: 100%;
    height: 100%;
    padding: 100px 0;
    position: absolute;
    z-index: 1;
    display: flex;
    top: 0;
}

.HeroStaticMain .sliderBox .textMain h3 {
    color: #fff;
    font-size: 2.5em;
    font-weight: 700;
    line-height: 1.2;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: end;
    text-shadow: 0 0 24px #000;
}

@media (max-width: 767.98px) {
    .HeroStaticMain .sliderBox .textMain {
        padding: 20px 0;
    }

    .HeroStaticMain {
        height: auto;
    }

    .HeroStaticMain .sliderBox img {
        height: auto;
    }

    .HeroStaticMain .sliderBox .textMain h3 {
        font-size: 2em;
    }
}
