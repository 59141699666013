.eventDetWrapper {
    position: relative;
    margin: 0;
    padding: 50px 0;
    background: #f8f8f8;
    border-bottom: 1px solid #bdbdbd;
}

.eventDetWrapper .eventDet-hldr {
    position: relative;
    display: flex;
    align-items: center;
}

.eventDetWrapper .eventDet-hldr .eventDet-text {
    position: relative;
    margin: 0;
    padding: 0;
}

.eventDetWrapper .eventDet-hldr .eventDet-text .tagMain {
    color: #046f44;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 0.5em;
}

.eventDetWrapper .eventDet-hldr .eventDet-text h3 {
    font-size: 40px;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    color: #1d1d1d;
    margin-bottom: 15px;
}

.eventDetWrapper .eventDet-hldr .eventDet-text p {
    font-size: 24px;
    font-weight: 400;
}

.eventDetWrapper .eventDet-hldr .eventDet-text ul.socialHldr {
    position: relative;
    margin: 10px 0px 0px 0px;
    padding: 0;
    list-style: none;
}

.eventDetWrapper .eventDet-hldr .eventDet-text ul.socialHldr li {
    display: inline-block;
    list-style: none;
    margin-right: 16px;
}

.eventDetWrapper .eventDet-hldr .eventDet-text ul.socialHldr li i {
    font-size: 16px;
    color: #828282;
}

.eventDetWrapper .eventDet-hldr .eventDet-img {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 400px;
    border-radius: 0.9375em;
}

.eventDetWrapper .eventDet-hldr .eventDet-img img {
    height: 31.25em;
    object-fit: cover;
    width: 100% !important;
}

.eventDet-TextSecation {
    position: relative;
    margin: 0;
    padding: 20px 0;
}

.eventDet-TextSecation .eventDet-textHldr {
    position: relative;
    margin: 0;
    padding: 0px;
}

.eventDet-TextSecation .eventDet-textHldr h4 {
    font-size: 20px;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    color: #1d1d1d;
    margin-bottom: 15px;
}

.eventDet-TextSecation .eventDet-textHldr p {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 15px;
}

.eventDet-TextSecation .RegisterBtn {
    position: relative;
    margin: 0;
    background: rgba(0, 85, 140, 1);
    border: none;
    color: #fff;
    font-weight: 400;
    padding: 10px 30px;
    border-radius: 10px;
    display: inline-flex;
}

.eventDet-TextSecation .dateTime-hldr {
    position: relative;
    margin: 0;
    padding: 0;
}

.eventDet-TextSecation .dateTime-hldr .dateTime-main {
    position: relative;
    margin-bottom: 30px;
}

.eventDet-TextSecation .dateTime-hldr .dateTime-main .title {
    font-weight: 400;
    font-size: 12px;
    color: rgba(130, 130, 130, 1);
    margin-bottom: 6px;
}

.eventDet-TextSecation .dateTime-hldr .dateTime-main .subTxt {
    font-weight: 400;
    font-size: 16px;
    color: #000;
}

.eventDet-TextSecation .dateTime-hldr .dateTime-main .link {
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 85, 140, 1);
    margin-top: 6px;
}

@media (max-width: 767.98px) {
    .eventDetWrapper {
        padding-top: 0;
    }

    .eventDet-img-hldr {
        padding: 0 !important;
        margin-bottom: 30px;
    }

    .eventDet-hldr .card-order {
        order: 1;
    }

    .eventDetWrapper .eventDet-hldr .eventDet-text h3 {
        font-size: 30px;
    }

    .eventDet-TextSecation .card-order {
        order: 1;
    }
}
