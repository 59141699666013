.FeaturedVideoSection {
    position: relative;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
}

.FeaturedVideoSection .titleMain {
    position: relative;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
}

.FeaturedVideoSection .titleMain h3 {
    color: #046f44;
    font-weight: 700;
    display: block;
    position: relative;
    padding-left: 0.8125em;
    font-size: 2em;
}

.FeaturedVideoSection .titleMain h3::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.25em;
    border-radius: 1em;
    height: 100%;
    background: #046f44;
}

.FeaturedVideoSection .videoImg img {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 23.75em;
}

.FeaturedVideoSection .videoImg {
    height: 23.75em;
    width: 100%;
}

.FeaturedVideoSection .textMain {
    position: relative;
    height: 100%;
    background: #046f44;
    padding: 2.5em;
}

.FeaturedVideoSection .textMain p {
    color: #fff;
    font-size: 1.125em;
    margin-bottom: 1.25em;
}

.FeaturedVideoSection .textMain a {
    color: #fff;
}

.FeaturedVideoSection .textMain.arrow-left::after {
    top: calc(50% - 24px);
    border: solid transparent;
    content: ' ';
    position: absolute;
    pointer-events: none;
    border-width: 30px;
    z-index: 99;
    left: -54px;
    border-right-color: #046f44;
}

@media (max-width: 767.98px) {
    .FeaturedVideoSection .videoImg img {
        height: 15.625em;
        margin-bottom: 1.875em;
    }

    .FeaturedVideoSection .videoImg {
        height: 15.625em;
        width: 100%;
    }

    .FeaturedVideoSection .textMain {
        padding: 0.9375em;
    }

    .FeaturedVideoSection .textMain.arrow-left::after {
        top: calc(-10% - 16px);
        bottom: 0;
        border-bottom-color: #046f44;
        left: 42%;
        border-right-color: transparent;
        height: 3.125em;
    }
}

[dir='rtl'] .FeaturedVideoSection .titleMain {
    padding: 0.9375em 0.25em 0.9375em 0em;
}

[dir='rtl'] .FeaturedVideoSection .titleMain h3 {
    padding-right: 0.8125em;
    padding-left: 0;
}

[dir='rtl'] .FeaturedVideoSection .titleMain h3::before {
    right: 0;
}

[dir='rtl'] .FeaturedVideoSection .btn-link {
    float: right;
}

[dir='rtl'] .FeaturedVideoSection .btn-link .btn-icon {
    padding-right: 0.675em !important;
}

[dir='rtl'] .FeaturedVideoSection .textMain.arrow-left::after {
    right: -54px;
    transform: rotate(180deg);
    left: auto;
}
