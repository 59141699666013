.jobDetailWrapper {
    position: relative;
    padding: 50px 0px;
}

.jobDetailWrapper .jobBoxHldr {
    position: relative;
    padding: 0px;
    background: #fff;
    height: 100%;
}

.jobDetailWrapper .heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;
}

.jobDetailWrapper .jobBoxHldr h5 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 700;
    color: var(--primary_color);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.jobDetailWrapper .jobBoxHldr .txt-main-hldr {
    position: relative;
    margin-bottom: 15px;
}

.jobDetailWrapper .jobBoxHldr .txt-main-hldr .jobDag {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 700;
    color: var(--accent_color1);
}

.jobDetailWrapper .jobBoxHldr .txt-main-hldr .timeLocHldr {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.jobDetailWrapper .jobBoxHldr .txt-main-hldr .timeLocHldr .tagMain {
    background: var(--accent_color2);
    color: var(--accent_color1);
    padding: 4px 8px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.jobDetailWrapper .jobBoxHldr .txt-main-hldr .timeLocHldr .tagMain:last-child {
    margin-right: 0;
}

.jobDetailWrapper .jobBoxHldr .txt-main-hldr .timeLocHldr .tagMain i {
    font-size: 14px;
    margin-right: 8px;
}

.jobDetailWrapper .jobBoxHldr .txt-main-hldr .applicationDate {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: var(--accent_color1);
}

.jobDescriptionHldr {
    position: relative;
}

.jobDescriptionHldr h4 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
    color: var(--accent_color1);
}

.jobDescriptionHldr p {
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    color: var(--accent_color1);
}

.jobDescriptionHldr ul {
    position: relative;
    margin-bottom: 20px;
}

.jobDescriptionHldr ul li {
    list-style: disc;
    margin-left: 14px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
}

.applicationHldr {
    position: relative;
}

.applicationHldr h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}

.applicationHldr .btnGreen {
    position: relative;
    height: 40px;
    border-radius: 6px;
    padding: 10px 50px;
    border: none;
    background: var(--primary_color);
    font-size: 16px;
    font-weight: 400;
    color: var(--accent_color4);
}

.jobDetailWrapper .input-group-text {
    border: none;
    position: absolute;
    top: 44px;
    right: 23px;
    background: var(--primary_color);
    border-radius: 7px !important;
    padding: 5px 15px;
    color: var(--accent_color4);
    z-index: 10;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.jobDetailWrapper .error-message {
    color: red;
    font-size: 0.875em;
}

@media (max-width: 767.98px) {
    .jobDetailWrapper .jobBoxHldr h5 {
        -webkit-line-clamp: 2;
    }

    .jobDetailWrapper .jobBoxHldr .txt-main-hldr .timeLocHldr .tagMain {
        padding: 2px 6px;
        font-size: 10px;
    }

    .jobDetailWrapper .jobBoxHldr .txt-main-hldr .timeLocHldr .tagMain i {
        font-size: 12px;
    }
}
