.articleHeading {
    position: relative;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
}

.articleHeading .heading {
    color: #046f44;
    font-size: 1.25em;
    font-weight: 500;
    line-height: 25px;
}

.articleHeading .description {
    color: #333333;
    font-size: 1.7em;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    line-height: 40px;
}
