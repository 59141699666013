.programme-std-wrapper {
    position: relative;
    margin: 0;
}

.programme-std-wrapper .job-img {
    position: relative;
    width: 100%;
    max-height: 17.5em;
    overflow: hidden;
    border-radius: 0.9375em;
    box-shadow: none;
}

.programme-std-wrapper .job-img img {
    width: 100%;
    border-radius: 0.9375em;
}

.programme-std-wrapper .jobTxt-hldr {
    position: relative;
}

.programme-std-wrapper .jobTxt-hldr .prod-dev {
    font-size: 1em;
    font-weight: 400;
    color: var(--accent_color3);
    text-transform: uppercase;
    letter-spacing: 5px;
    margin: 0 0 1.25em;
}

.prog-link-wrapper {
    border-top: 1px solid var(--accent_color2);
    border-bottom: 1px solid var(--accent_color2);
    padding: 1.875em 0;
}

.prog-link-wrapper ul a {
    margin: 0;
    display: block;
}

.prog-link-wrapper ul li span {
    position: relative;
    color: var(--primary_color);
    font-size: 1.25em;
    font-weight: 400;
    padding-bottom: 0.125em;
    padding: 0 1em;
}

.programme-std-wrapper .jobTxt-hldr .job-title {
    position: relative;
    padding-left: 1.5625em;
    margin-bottom: 1.25em;
}

.programme-std-wrapper .jobTxt-hldr .job-title .line {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0.5em;
    background: var(--primary_color);
    border-radius: 1.25em;
}

.programme-std-wrapper .jobTxt-hldr .job-title h1 {
    font-weight: 700 !important;
    font-size: 2.25em;
    color: var(--accent_color1);
}

.programme-std-wrapper .jobTxt-hldr p {
    font-size: 1.375rem;
    color: var(--accent_color1);
    font-weight: 400;
}

.programme-std-body-content p {
    font-size: 1.25em;
    margin: 1.5em 0;
    font-weight: 300 !important;
    color: var(--accent_color1);
}

.programme-std-body-content .btnHldr Button.link {
    color: var(--accent_color1);
    padding: 0.625em 1.4375em;
    border: 1px solid var(--primary_color);
    border-radius: 0.9375em;
    display: flex;
    align-items: center;
    margin-right: 1.5em;
    background: var(--accent_color4);
    font-size: 1.25em;
    font-weight: 500;
    text-align: left;
}

.prog-link-wrapper .desktop-std-menu {
    justify-content: center;
}

.programme-std-body-content .btnHldr Button.link {
    font-size: 16px !important;
    padding: 10px 22px !important;
}

.Get-touch-wrapper h2 {
    font-weight: 800;
}

.Get-touch-wrapper p {
    font-weight: 300;
}

.prog-link-wrapper .desktop-std-menu ul li {
    margin-right: 2em;
}

@media (max-width: 767.98px) {
    .programme-std-wrapper .job-img {
        height: auto;
        border-radius: 0;
        box-shadow: none;
    }

    .programme-std-wrapper .job-img::after {
        content: '';
        z-index: 10;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: none;
    }

    .programme-std-wrapper .job-img img {
        border-radius: 0;
    }

    .programme-std-wrapper .jobTxt-hldr .prod-dev {
        margin-top: 0.9375em;
    }

    .programme-std-wrapper .jobTxt-hldr {
        margin-top: 1em;
    }

    .programme-std-wrapper .jobTxt-hldr .job-title h1 {
        font-size: 2.25em;
    }

    .programme-std-wrapper .jobTxt-hldr p {
        font-size: 1.125rem;
    }

    .prog-link-wrapper .desktop-std-menu {
        display: flex;
        overflow: scroll;
        justify-content: left;
    }
}
