.mediaArticleMainSection {
    position: relative;
    padding: 2.125em 0;
    margin: 0;
}

.mediaArticleMainSection .container {
    max-width: 1100px;
}

.mediaArticleMainSection .tagMain {
    color: #333333;
    font-family: Poppins, sans-serif;
    font-size: 1em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3px;
}

.mediaArticleMainSection .iconHldr {
    position: relative;
    display: flex;
    align-items: center;
}

.mediaArticleMainSection .icon-btn {
    position: relative;
    margin: 1em 1em 0.5em 1em;
    padding: 0em 1em;
    display: inline-block;
    text-align: center;
    background: #f4f4f4;
    border-radius: 0.375em;
    cursor: pointer;
    color: #4f4f4f;
    font-size: 0.825em;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    font-family: Poppins, sans-serif !important;
}

.mediaArticleMainSection .icon-btn .fa-share-alt:before {
    opacity: 0.7;
}

.mediaArticleMainSection .iconHldr .dateMain {
    display: flex;
    align-items: center;
    margin-right: auto;
}

.mediaArticleMainSection .iconHldr .dateMain .dateTitleMain {
    position: relative;
    border-left: 1px solid #00a19a;
    padding-left: 0.625em;
}

.mediaArticleMainSection .iconHldr .dateMain .dateTitleMain h4 {
    font-size: 1em;
    font-weight: 600;
    line-height: 24px;
    font-family: Poppins, sans-serif;
    color: #333333;
}

.mediaArticleMainSection .iconHldr .dateMain .dateTitleMain .dateTime {
    position: relative;
    display: flex;
    align-items: center;
}

.mediaArticleMainSection .iconHldr .dateMain .dateTitleMain .dateTime span {
    font-size: 1em;
    color: #666666;
    font-weight: 600;
    font-family: Poppins, sans-serif;
}

.mediaArticleMainSection .iconHldr ul {
    position: relative;
    margin: 0;
    padding: 0;
}

.mediaArticleMainSection .iconHldr ul li {
    list-style: none;
    margin-right: 0.425em;
    font-size: 1.625em;
    line-height: normal;
    display: inline-block;
    opacity: 0.7;
    font-family: Poppins, sans-serif;
}

.mediaArticleMainSection h3 {
    font-size: 1.8em;
    font-family: Poppins, sans-serif;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: #1d1d1d;
}

.mediaArticleMainSection .sub-title {
    font-size: 1.2em;
    font-family: Poppins, sans-serif;
    padding: 0;
    font-style: normal;
    line-height: 32px;
    color: #333333;
}

.mediaArticleMainSection .nav-tabs .nav-item {
    margin-bottom: 0;
}

.mediaArticleMainSection .nav-tabs {
    border-bottom: none;
    padding: 0;
    font-size: 1em;
    font-family: Poppins, sans-serif;
}

.mediaArticleMainSection .nav-tabs .nav-link {
    color: #000000;
    font-size: 1em;
    font-weight: 500;
    padding: 0;
    line-height: 32px;
    margin-right: 15px;
    text-transform: capitalize;
    font-family: Poppins, sans-serif;
}

.mediaArticleMainSection .nav-tabs .nav-item.show .nav-link,
.mediaArticleMainSection .nav-tabs .nav-link.active {
    position: relative;
    border-color: transparent;
    font-size: 1em;
    background: 0 0;
    font-weight: 700;
    font-family: Poppins, sans-serif;
}

.mediaArticleMainSection .nav-tabs .nav-link.active::after {
    content: '';
    position: absolute;
    height: 2px;
    background-color: #d0d0d0;
    bottom: -1px;
    left: 0;
    width: 100%;
}

.mediaArticleMainSection .download-button {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e2dddd;
    padding: 3px 20px;
    color: #464646;
    font-size: 0.9em;
    line-height: 32px;
    font-family: Poppins, sans-serif;
}

.mediaArticleMainSection .download-icon {
    color: #046f44 !important;
    transition: all 0.6s ease;
    transform: rotate(0deg);
}

.mediaArticleMainSection .more-icon {
    color: #046f44 !important;
}

.mediaArticleMainSection .more-text {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.mediaArticleMainSection .summary {
    font-size: 1.1em;
    color: #000000;
    line-height: 32px;
    font-family: Poppins, sans-serif;
    text-align: justify;
}

.mediaArticleMainSection .summary p {
    margin-bottom: 1em;
}

.mediaArticleMainSection .documentName {
    color: #000000;
    font-size: 1em;
    line-height: 24px;
    font-weight: 600;
}

.mediaArticleMainSection .noResultFound {
    color: #000000;
    font-family: Poppins, sans-serif;
    text-align: center;
    font-weight: 600;
    text-align: center;
    font-size: 1.5em;
}

.mediaArticleMainSection img {
    animation: fadeIn2 2s;
    -webkit-animation: fadeIn2 2s;
    -moz-animation: fadeIn2 2s;
    -o-animation: fadeIn2 2s;
    -ms-animation: fadeIn2 2s;
}

.mediaArticleMainSection {
    overflow: hidden;
}

.mediaArticleMainSection.expanded .media-attachments {
    max-height: none;
}

.media-attachments {
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
}

.PhotoView-Portal {
    z-index: 99999 !important;
}

.mediaArticleMainSection .card {
    border: none;
    background-color: rgba(255, 255, 255, 0.7);
    /* Set the background color with transparency */
    transition: background-color 0.3s ease;
    /* Smooth transition */
}

.mediaArticleMainSection .card .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.4;
    display: flex;
}

.mediaArticleMainSection .card .cardImg img {
    width: 100%;
    object-fit: cover !important;
    animation: fadeIn2 1s;
    -webkit-animation: fadeIn2 1s;
    -moz-animation: fadeIn2 1s;
    -o-animation: fadeIn2 1s;
    -ms-animation: fadeIn2 1s;
}

.mediaArticleMainSection .card .cardImg img:hover {
    opacity: 0.4;
    /* Reduce opacity on hover */
    transition: opacity 0.3s ease;
    /* Smooth transition */
    background: url('../../../public/assets/images/hover-img-icon.svg') center no-repeat;
}

.mediaArticleMainSection .card:hover {
    background: url('../../../public/assets/images/hover-img-icon.svg') center no-repeat;
}

.mediaArticleMainSection .card .card-body {
    position: relative;
    padding: 1em 0.5em;
    border-radius: 0px 0px 6px 6px;
}

.mediaArticleMainSection .card .card-body h4 {
    font-size: 14px;
    color: #000000;
    margin: 0;
    font-family: 'Poppins';
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-bottom: 1em;
}

.mediaArticleMainSection .tagSection .download-Text {
    color: #464646;
    margin-left: auto;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 228.571% */
    text-decoration-line: underline;
}

.mediaArticleMainSection .tagSection .download-icon {
    color: #046f44;
}

.mediaArticleMainSection .card .card-body .download-Text i {
    color: #046f44;
}

.mediaArticleMainSection .summary-section .container {
    max-width: 900px;
}

.mediaArticleMainSection .container {
    max-width: 1300px;
}

.mediaArticleMainSection .modal {
    z-index: 100000;
}

.mediaArticleMainSection .modal.show .modal-dialog {
    max-width: 50%;
}

.mediaArticleMainSection .modal-header {
    border-bottom: none;
}

.mediaArticleMainSection .modal-main {
    border-radius: 10px;
    background: #fff;
    padding: 0 3em 3em 3em;
}

.mediaArticleMainSection .modal-main .download-txt {
    display: flex;
    justify-content: end;
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-right: 1em;
}

.mediaArticleMainSection .modal-main .download-modal-icon {
    color: #046f44;
}

.mediaArticleMainSection .modal-main .img-caption {
    color: #000;
    display: flex;
    margin-top: 1em;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.mediaArticleMainSection .modal-main img {
    width: 100%;
}

.mediaArticleMainSection .modal-main video {
    width: 100%;
}

.mediaArticleMainSection .modal-main .body {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.5);

    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* .mediaArticleMainSection .video-react .video-react-big-play-button {
    bottom: 10px !important;
    width: 1em;
    height: 1em;
    top: auto;
    border-radius: 1em;
}

.mediaArticleMainSection .video-react .video-react-control:before,
.video-react .video-react-big-play-button:before {
    top: -7px !important;
} */

.mediaArticleMainSection hr {
    margin: 15px 0;
    border-top: 2px solid #dadada;
}

.mediaArticleMainSection .timecodes {
    color: var(--Grey-3, #828282);
    padding-top: 1em;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 200% */
    text-transform: uppercase;
}

.mediaArticleMainSection .dateTime {
    color: var(--Grey-4, #4f4f4f);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.mediaArticleMainSection .timecodedesc {
    color: #000;

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.mediaArticleMainSection .modal-main iframe {
    height: 468px !important;
    width: 100% !important;
}

.mediaArticleMainSection .modal-main .download-icon {
    color: #fff !important;
    transition: none;
}

.mediaArticleMainSection .modal-main .download-button {
    background-color: #1c6c42;
    color: #fff;
    border-radius: 15px;
}

.mediaArticleMainSection .titleMain {
    position: relative;
    background: none;
    padding: 0.435em 0.25em 0.435em 0;
    display: flex;
    align-items: center;
}

.mediaArticleMainSection .titleMain h3 {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    padding-left: 0.8125em;
}

.mediaArticleMainSection .titleMain h3::before {
    content: '';
    position: absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    background: #046f44;
}

.mediaArticleMainSection .tagSection {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    padding-bottom: 7px;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    margin-top: 1.5em;
}

.mediaArticleMainSection .tagSection .tagName {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
}

.mediaArticleMainSection .GetTouchAccordion {
    position: relative;
}

.mediaArticleMainSection .GetTouchAccordion .accordion-item {
    border: none;
    margin-bottom: 1.25em;
    border-radius: 0.625em;
}

.mediaArticleMainSection .GetTouchAccordion .accordion-item h2 {
    margin: 0;
}

.mediaArticleMainSection .GetTouchAccordion .accordion-item h2 .accordion-button {
    background: #046f44;
    font-size: 1.1rem;
    font-weight: 800;
    color: #fff;
    border-radius: 0.625em;
    box-shadow: none;
}

.mediaArticleMainSection .GetTouchAccordion .accordion-item h2 .accordion-button::after {
    content: '\f4fe';
    background: 0 0;
    width: 1.5em;
    height: 1.5em;
    border: 2px solid #fff;
    text-align: center;
    font-size: 1.375em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
}

.mediaArticleMainSection
    .GetTouchAccordion
    .accordion-item
    h2
    .accordion-button:not(.collapsed)::after {
    content: '\f2ea';
    color: #fff;
    border: 2px solid #fff;
}

.mediaArticleMainSection .GetTouchAccordion .accordion-item h2 .accordion-button:not(.collapsed) {
    color: #fff;
    background-color: #046f44;
    border-radius: 0.625em 0.625em 0 0;
}

.mediaArticleMainSection .accordion-body {
    position: relative;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: #fff;
    padding: 1em;
}

.Overlay--close {
    height: 50px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-200%);
    transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.2s;
    transition-delay: 0s, 0s;
    width: 50px;
    z-index: 10000;
    opacity: 1;
    transform: none;
    transition-delay: 0.5s;
    border: 0;
    padding: 0;
    background: none;
}

.Overlay--close::before {
    border-top: 2px solid;
    content: '';
    font-size: 10px;
    left: 50%;
    margin: -1px -12px;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    transition: transform 0.25s cubic-bezier(0.42, 0, 0.175, 1);
    width: 24px;
}

.Overlay--close::after {
    border-top: 2px solid;
    content: '';
    font-size: 10px;
    left: 50%;
    margin: -1px -12px;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
    transition: transform 0.4s cubic-bezier(0.42, 0, 0.175, 1);
    width: 24px;
}

.Overlay--close:active::before,
.Overlay--close:hover::before {
    content: '';
    font-size: 10px;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
}

.Overlay--close:active::after,
.Overlay--close:hover::after {
    content: '';
    font-size: 10px;
    position: absolute;
    top: 50%;
    transform: rotate(-315deg);
}

.mediaArticleMainSection .modal-main .see-more-icon {
    color: #046f44;
}

.media-dialog-main img {
    width: 100%;
}

.media-dialog-main .download-txt {
    display: flex;
    justify-content: end;
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-right: 1em;
}

.media-dialog-main .download-modal-icon {
    color: #046f44;
}

.media-dialog-main .img-caption {
    color: #000;
    display: flex;
    margin-top: 1em;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.media-dialog-main .body {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.5);
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.p-dialog .p-dialog-header .p-dialog-header-icon svg {
    display: none;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    height: 50px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 10px;
    transform: translateY(-200%);
    transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.2s;
    transition-delay: 0s, 0s;
    width: 50px;
    z-index: 10000;
    opacity: 1;
    transform: none;
    transition-delay: 0.5s;
    border: 0;
    padding: 0;
    background: none;
    margin-right: 0.5rem !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon::before {
    border-top: 2px solid;
    content: '';
    font-size: 10px;
    left: 50%;
    margin: -1px -12px;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    transition: transform 0.25s cubic-bezier(0.42, 0, 0.175, 1);
    width: 24px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon::after {
    border-top: 2px solid;
    content: '';
    font-size: 10px;
    left: 50%;
    margin: -1px -12px;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
    transition: transform 0.4s cubic-bezier(0.42, 0, 0.175, 1);
    width: 24px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:active::before,
.p-dialog .p-dialog-header .p-dialog-header-icon:hover::before {
    content: '';
    font-size: 10px;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
}

.p-dialog .p-dialog-header .p-dialog-header-icon:active::after,
.p-dialog .p-dialog-header .p-dialog-header-icon:hover::after {
    content: '';
    font-size: 10px;
    position: absolute;
    top: 50%;
    transform: rotate(-315deg);
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    border: none !important;
    background: none !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none !important;
}

.media-dialog-main video {
    width: 100%;
}

.player {
    max-width: 100% !important;
}

.player .vp-video-wrapper .vp-preview {
    background-repeat: repeat !important;
}

.media-dialog-main hr {
    margin: 15px 0;
    border-top: 2px solid #dadada;
}

.media-dialog-main .timecodes {
    color: var(--Grey-3, #828282);
    padding-top: 1em;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
}

.media-dialog-main .dateTime {
    color: var(--Grey-4, #4f4f4f);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.media-dialog-main .timecodedesc {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.media-dialog-main iframe {
    height: 468px !important;
    width: 100% !important;
}

.media-dialog-main .download-button {
    background-color: #1c6c42;
    color: #fff;
    border-radius: 15px;
    padding: 10px;
    border: none;
}

.media-dialog-main .download-icon {
    color: #fff !important;
    transition: none;
}

.mediaArticleMainSection .p-accordion .p-accordion-tab {
    margin-bottom: 2em;
}

.mediaArticleMainSection .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem;
}

.mediaArticleMainSection .p-accordion .p-accordion-header .p-accordion-header-link {
    background: #046f44;
    border-radius: 0.625em;
    border: none;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 800;
}

.mediaArticleMainSection .p-accordion .p-accordion-content {
    padding: 0;
    border: none;
}

.mediaArticleMainSection
    .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight:hover
    .p-accordion-header-link {
    background: #046f44;
    color: #fff;
}

.mediaArticleMainSection
    .p-accordion
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link::after {
    color: #fff;
    border: 2px solid #fff;
}

.mediaArticleMainSection
    .p-accordion
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link {
    background: #046f44;
    color: #fff;
}

.mediaArticleMainSection
    .p-accordion
    .p-accordion-header:not(.p-disabled)
    .p-accordion-header-link:focus {
    box-shadow: none;
}

.mediaArticleMainSection
    .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link {
    background: #046f44;
    color: #fff;
}

.mediaArticleMainSection .p-accordion .p-accordion-header .p-accordion-header-link svg {
    display: none;
}

.mediaArticleMainSection .p-accordion .p-accordion-header .p-accordion-header-link::after {
    content: '\f4fe';
    background: 0 0;
    width: 1.5em;
    height: 1.5em;
    border: 2px solid #fff;
    text-align: center;
    font-size: 1.375em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
}

.mediaArticleMainSection
    .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link::after {
    content: '\f2ea';
    color: #fff;
    border: 2px solid #fff;
    transition: transform 0.2s ease-in-out;
    transform: rotate(-180deg);
}

@media (max-width: 767.98px) {
    .mediaArticleMainSection .nav-tabs {
        justify-content: center;
        margin-bottom: 1.5em;
    }

    .mediaArticleMainSection .card .cardImg {
        height: 6em;
    }

    .mediaArticleMainSection .card .cardImg img {
        height: 6em;
    }

    .media-dialog-main iframe {
        height: auto !important;
    }
}