.programmeServiceWrapper {
    position: relative;
    padding-top: 3em;
}

.programmeServiceWrapper .titleMain {
    position: relative;
    background: none;
    padding: 0.9375em 1.25em 0.9375em 0;
    display: flex;
    align-items: center;
}

.programmeServiceWrapper .titleMain h3 {
    color: var(--primary_color);
    font-weight: 700;
    display: block;
    position: relative;
    padding-left: 0.8125em;
    font-size: 2em;
}

.programmeServiceWrapper .titleMain h3::before {
    content: '';
    position: absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background: var(--primary_color);
}

.programmeServiceWrapper .programmesTagWrapper {
    position: relative;
    margin: 0;
    padding: 0;
}

.programmeServiceWrapper .programmesTagWrapper ul {
    list-style: none;
    margin: 0 0;
    margin-left: 1.4em;
}

.programmeServiceWrapper .programmesTagWrapper ul li {
    position: relative;
    margin: 0.3125em 0.625em 0.825em 0;
    padding: 0.325em 1em;
    display: inline-block;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    color: var(--accent_color1);
    font-size: 1.125em;
    white-space: nowrap;
    border: 1px solid var(--primary_color);
    font-weight: 700;
}

.programmeServiceWrapper .tagBox {
    position: relative;
    margin: 2.5em 0 0 0;
    padding: 1em;
    list-style: none;
    height: 12em;
    overflow-y: scroll;
}

@media (max-width: 767.98px) {
    .programmeServiceWrapper .programmesTagWrapper ul {
        margin-left: 0;
    }

    .programmeServiceWrapper .programmesTagWrapper ul li {
        font-size: 0.8125em;
    }
}

[dir='rtl'] .programmeServiceWrapper .titleMain {
    padding: 0.9375em 0.25em 0.9375em 0;
}

[dir='rtl'] .programmeServiceWrapper .titleMain h3::before {
    right: 0;
}

[dir='rtl'] .programmeServiceWrapper .titleMain h3 {
    padding-right: 0.8125em;
    padding-left: 0;
}

[dir='rtl'] .programmeServiceWrapper .programmesTagWrapper {
    padding: 0.9375em 0.25em 0.9375em 0;
}

[dir='rtl'] .programmeServiceWrapper .programmesTagWrapper ul li {
    margin: 0.3125em 0em 0.3125em 0.625em;
}

[dir='rtl'] .programmesWrapper .newsCard .card-body .date {
    text-align: right;
}
