.upcomEventWrapper {
    position: relative;
    padding-top: 3.5em;
}

.upcomEventWrapper h2 {
    font-size: 36px;
    font-weight: 500;
    padding-bottom: 10px;
}

.upcomEventWrapper h2 span {
    color: rgba(0, 85, 140, 1);
}

.upcomEventWrapper .findRecord {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 15px;
}

.upcomEventWrapper .findRecord span {
    text-decoration: underline;
    cursor: pointer;
}

.upcomEventWrapper .tabMainHldr {
    position: relative;
    margin: 0;
    padding: 0;
}

.upcomEventWrapper .tabMainHldr ul {
    position: relative;
    margin: 0 0 25px 0;
    padding: 0;
    display: flex;
    align-items: center;
    list-style: none;
    overflow: auto;
}

.upcomEventWrapper .tabMainHldr ul li {
    position: relative;
    padding: 8px 20px;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid rgba(127, 170, 198, 1);
    border-radius: 30px;
    margin-right: 15px;
    cursor: pointer;
}

.upcomEventWrapper .tabMainHldr ul li:last-child {
    margin-right: 0;
}

.upcomEventWrapper .tabMainHldr ul li:hover {
    border: 1px solid rgba(0, 85, 140, 1);
    background: rgba(0, 85, 140, 1);
    color: #fff;
}

.upcomEventWrapper .tabMainHldr ul li.active {
    border: 1px solid rgba(0, 85, 140, 1);
    background: rgba(0, 85, 140, 1);
    color: #fff;
}

.upcomEventWrapper .searchFilter {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 30px 0;
    padding: 0;
}

.upcomEventWrapper .searchFilter .searchHldr {
    position: relative;
    margin: 0;
    padding: 0;
    width: 60%;
}

.upcomEventWrapper .searchFilter .searchHldr .searchIcon {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 14px;
    left: 15px;
    z-index: 1;
}

.upcomEventWrapper .searchFilter .searchHldr .searchMain {
    position: relative;
    width: 100%;
    padding: 6px 20px 6px 46px;
    background: #f8f8f8;
    border-radius: 10px;
    min-height: 45px;
    border: none;
}

.upcomEventWrapper .searchFilter .filterHldr {
    position: relative;
    margin-left: auto;
    padding: 0;
    display: flex;
    align-items: center;
}

.upcomEventWrapper .searchFilter .filterHldr .gridListHldr {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.upcomEventWrapper .searchFilter .filterHldr .PrimaryAudience {
    position: relative;
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.upcomEventWrapper .searchFilter .filterHldr .PrimaryAudience span {
    font-weight: 700;
    font-size: 14px;
    margin-right: 8px;
}

.upcomEventWrapper .searchFilter .filterHldr .dateHldr {
    position: relative;
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.upcomEventWrapper .searchFilter .filterHldr .dateHldr span {
    font-weight: 500;
    font-size: 14px;
    margin-right: 8px;
}

.upcomEventWrapper .newsCard {
    position: relative;
    height: 100%;
    border-radius: 0.5em;
    margin: 0;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    border: none;
}

.upcomEventWrapper .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.4;
    display: flex;
}

.upcomEventWrapper .newsCard .cardImg img {
    object-fit: cover;
    width: 100%;
    border-radius: 15px 15px 0px 0px;
}

.upcomEventWrapper .newsCard .cardImg .tag {
    z-index: 2;
    position: absolute;
    bottom: 0.9375em;
    right: 0.9375em;
    padding: 0.375em 0.5625em;
    border: 1px solid #f2f2f3;
    border-radius: 4px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.8) 0, rgba(255, 255, 255, 0.55) 100%);
    box-shadow: 0 20px 40px 0 rgba(57, 124, 191, 0.1);
    backdrop-filter: blur(20px);
    color: #1d1d1d;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.75em;
    text-transform: uppercase;
}

.upcomEventWrapper .newsCard .card-body {
    position: relative;
    padding: 0.935em;
    margin: 0;
}

.upcomEventWrapper .newsCard .card-body .iconMain {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 0.625em;
}

.upcomEventWrapper .newsCard .card-body .iconMain .mins {
    font-size: 0.875em;
    font-weight: 300;
    margin-right: auto;
}

.upcomEventWrapper .newsCard .card-body .iconMain ul {
    position: relative;
    margin: 0;
    padding: 0;
}

.upcomEventWrapper .newsCard .card-body .iconMain ul li {
    list-style: none;
    margin-right: 0.1875em;
    font-size: 1.375em;
    line-height: normal;
    display: inline-block;
}

.upcomEventWrapper .newsCard .card-body .iconMain ul li a {
    color: #1d1d1d;
}

.upcomEventWrapper .newsCard .card-body .iconMain ul li a.active,
.upcomEventWrapper .newsCard .card-body .iconMain ul li a:hover {
    color: #4f887a;
}

.upcomEventWrapper .newsCard .card-body h3 {
    font-size: 16px;
    margin: 0 0 0.1875em 0;
    padding: 0;
    font-weight: 400;
}

.upcomEventWrapper .newsCard .card-body .date {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
    font-weight: 600;
    color: #046f44;
}

.upcomEventWrapper .newsCard .card-body .eventDate {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
}

.upcomEventWrapper .newsCard .card-body .eventLocation {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
}

.upcomEventWrapper .newsCard .card-body .tag-heading {
    color: #046f44;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
    letter-spacing: 2.1px;
    text-transform: uppercase;
}

.upcomEventWrapper .newsCard .card-body .textMain {
    position: relative;
    margin: 0.5em 0;
    padding: 0;
    margin-bottom: 20px;
}

.upcomEventWrapper .newsCard .card-body .textMain {
    font-size: 0.875em;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: normal;
}

.upcomEventWrapper .newsCard .card-body .eventBtn {
    bottom: 0.3em;
    left: 0;
}

.upcomEventWrapper .newsCard .card-body .hashtag {
    position: relative;
    display: inline-block;
    padding: 0.1875em 0.9375em;
    border-radius: 0.25em;
    background: #eef7f4;
    color: #046f44;
    font-size: 0.875em;
    font-weight: 500;
}

.upcomEventWrapper .button {
    border-radius: 4px;
    background: #4f4f4f;
    border: none;
    color: white;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    padding: 3px 18px;
    width: 162px;
    border-radius: 4px;
}

.upcomEventWrapper .p-inputtext {
    border: none;
}

.upcomEventWrapper .p-dropdown {
    border: none;
}

.upcomEventWrapper .p-calendar .p-inputtext {
    width: 6em;
    font-weight: 700;
    font-size: 14px;
    margin-right: 0px;
}

.upcomEventWrapper .p-inputtext:enabled:focus {
    box-shadow: none;
    border: none;
}

.upcomEventWrapper .p-dropdown:not(.p-disabled):hover {
    box-shadow: none;
    border: none;
}

.eventListView {
    position: relative;
}

.eventListView .newsCard {
    display: flex;
    margin: 0;
}

.eventListView .newsCard .cardImg {
    width: 253px;
    height: 100%;
    flex-shrink: 0 !important;
}

.eventListView .newsCard .cardImg img {
    border-radius: 15px 0px 0px 15px;
    object-fit: cover !important;
    height: 100%;
    width: 100%;
}

.upcomEventWrapper .searchFilter .mobileSearchHldr {
    display: none;
}

.upcomEventWrapper .searchFilter .filterHldr .PrimaryAudience span {
    margin-right: 0;
}

.upcomEventWrapper .p-dropdown .p-dropdown-trigger {
    width: auto;
}

.upcomEventWrapper .fa-close {
    color: #000;
    position: absolute;
    top: 12px;
    right: 2.5rem;
    font-size: 1em;
    z-index: 999;
}

.upcomEventWrapper .p-inputtext {
    color: var(--primary_color);
}

.upcomEventWrapper .showMore {
    font-size: 1.1875em;
    line-height: 33.25px;
    text-align: center;
}

@media (max-width: 575.98px) {
    .upcomEventWrapper .searchFilter {
        border-top: 2px solid #d9d9d9;
        border-bottom: 2px solid #d9d9d9;
        padding: 10px 0;
    }

    .upcomEventWrapper .searchFilter .searchHldr,
    .upcomEventWrapper .searchFilter .filterHldr .gridListHldr {
        display: none;
    }

    .upcomEventWrapper .searchFilter .filterHldr .PrimaryAudience span,
    .upcomEventWrapper .searchFilter .filterHldr .PrimaryAudience {
        margin-right: 0;
    }

    .upcomEventWrapper .searchFilter .mobileSearchHldr {
        display: block;
        position: relative;
        background: #f9f8f8;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 36px;
    }

    .upcomEventWrapper .searchFilter .mobileSearchHldr i {
        color: #1d1d1d;
    }

    .openInputSearch {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        background: #fff;
        z-index: 99;
        display: flex;
        align-items: center;
    }

    .openInputSearch i {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        color: #000;
    }

    .openInputSearch .SearchInputHldr {
        position: relative;
        width: 100%;
        margin-left: 10px;
    }

    .openInputSearch .SearchInputHldr input {
        border: none;
        width: 100%;
        padding: 6px 20px 6px 46px;
        background: #f8f8f8;
        border-radius: 10px;
        min-height: 45px;
    }

    .openInputSearch .SearchInputHldr i {
        position: absolute;
        top: 8px;
        left: 6px;
        font-size: 16px;
    }
}
