.importantLinksWrapper {
    position: relative;
    padding-top: 3em;
    padding-bottom: 1em;
}

.importantLinksWrapper .titleMain {
    position: relative;
    background: none;
    padding: 0.9375em 1.25em 0.9375em 0;
    display: flex;
    align-items: center;
}

.importantLinksWrapper .titleMain h3 {
    color: var(--primary_color);
    font-size: 2em;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    padding-left: 0.8125em;
}

.importantLinksWrapper .titleMain h3::before {
    content: '';
    position: absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    background: var(--primary_color);
}

.importantLinksWrapper .newsCard {
    position: relative;
    padding: 20px;
    margin-left: 1em;
    border-radius: 10px;
    background: var(--background_color);
    box-shadow: 5px 5px 25px 0px #0000001a;
    height: 100%;
    display: flex;
    align-items: center;
}

.importantLinksWrapper .newsCard .cardImg {
    position: relative;
    overflow: hidden;
    margin: 0 15px 0 0;
    padding: 0;
    height: 50px;
    flex-shrink: 0;
}

.importantLinksWrapper .newsCard img {
    height: 3em;
    border-radius: 0.5em;
}

.importantLinksWrapper .newsCard .text {
    color: var(--accent_color1);
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    width: 100%;
}

[dir='rtl'] .importantLinksWrapper .titleMain {
    padding: 0.9375em 0.25em 0.9375em 0;
}

[dir='rtl'] .importantLinksWrapper .titleMain h3 {
    padding-right: 0.8125em;
    padding-left: 0;
}

[dir='rtl'] .importantLinksWrapper .titleMain h3::before {
    right: 0;
}
