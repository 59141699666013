.GlimpseSecation {
    position: relative;
    background: #eef7f4;
    margin-top: 3em;
    margin-bottom: 3em;
}

.GlimpseSecation .videoImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 34.75em;
}

.GlimpseSecation .videoImg img {
    height: 34.75em;
    width: 100%;
    object-fit: cover;
}

.GlimpseSecation .textMain {
    position: relative;
    margin-left: 1em;
    padding: 1em;
}

.GlimpseSecation .textMain h3 {
    color: var(--Primary-Green, #046f44);
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding: 5em 1em 0em 1em;
}

.GlimpseSecation .textMain p {
    color: #1d1d1d;
    font-size: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: normal;
    line-height: 1.5em;
    padding: 1em 1em 0em 1em;
    font-weight: 400;
    line-height: 28px;
}

.GlimpseSecation .arrow-left::after {
    top: calc(50% - 24px);
    border: solid transparent;
    content: ' ';
    position: absolute;
    pointer-events: none;
    border-width: 30px;
    z-index: 99;
    left: -54px;
    border-right-color: red;
}

.GlimpseSecation .btn-link {
    color: #046f44;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 3em 1em 0em 1em;
    margin-left: 1.5em;
}

@media (max-width: 767.98px) {
    .GlimpseSecation {
        position: relative;
        padding: 0 0 1.25em 0;
        margin-bottom: 1.875em;
    }

    .GlimpseSecation .videoImg {
        margin-bottom: 0.9375em;
        height: 18.75em;
    }

    .GlimpseSecation .videoImg img {
        height: 18.75em;
        object-fit: cover;
    }

    .GlimpseSecation .textMain {
        padding: 1.25em;
        text-align: center;
    }

    .GlimpseSecation .textMain h3 {
        font-size: 1.375em;
        margin-bottom: 0.9375em;
        height: auto;
        padding: 0;
    }

    .GlimpseSecation .textMain p {
        font-size: 1em;
        padding: 0em 1em 0em 1em;
    }

    .GlimpseSecation .textMain {
        margin-left: 0;
    }

    .GlimpseSecation .btn-link {
        padding: 1em 1em 0em 1em;
    }

    [dir='rtl'] .GlimpseSecation .textMain {
        margin-right: 0;
        margin-left: 0;
    }
}
