.featuredEvents2Section {
    position: relative;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
}

.featuredEvents2Section .row > div {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.featuredEvents2Section .textMainHldr {
    position: relative;
    margin-right: 25%;
    margin-left: 5%;
}

.featuredEvents2Section .textMainHldr .heading {
    font-size: 1em;
    line-height: 19.5px;
    text-align: left;
    font-weight: 400;
    color: #007041;
}

.featuredEvents2Section .textMainHldr .sub-heading {
    font-size: 3em;
    line-height: 58.51px;
    text-align: left;
    font-weight: 500;
    color: #000000;
    margin-top: 15px;
    margin-bottom: 20px;
}

.featuredEvents2Section .textMainHldr .content-body {
    font-weight: 400;
    color: #323232;
    font-size: 1.125em;
}

.featuredEvents2Section .textMainHldr .button {
    border-radius: 4px;
    background: #04656f;
    border: none;
    color: white;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    padding: 10px 18px;
    width: 100%;
    border-radius: 10px;
}

.featuredEvents2Section .newsCard {
    position: relative;
    border-radius: 0.5em;
    margin: 4px 4px 0 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    border: none;
    height: 100%;
}

.featuredEvents2Section .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.4;
    display: flex;
    align-items: center;
}

.featuredEvents2Section .newsCard .cardImg img {
    object-fit: cover;
    width: 100%;
    border-radius: 15px 15px 0px 0px;
}

.featuredEvents2Section .newsCard .card-body {
    position: relative;
    padding: 0.935em;
    margin: 0;
}

.featuredEvents2Section .newsCard .card-body .day {
    color: #000000;
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: 500;
}

.featuredEvents2Section .newsCard .card-body h3 {
    font-size: 1em;
    font-weight: 400;
    line-height: 19.5px;
    color: #000000;
}

.featuredEvents2Section .newsCard .card-body .eventDate {
    font-size: 0.75em;
    color: #4f4f4f;
    font-weight: 400;
}

.featuredEvents2Section .swiper {
    padding-top: 0 !important;
    padding-bottom: 70px !important;
}

.featuredEvents2Section .swiper-button-prev,
.featuredEvents2Section .swiper-button-next {
    top: auto !important;
    bottom: 0;
    padding: 22px;
    background: var(--primary_color);
    border-radius: 50%;
    color: var(--accent_color4);
}

.featuredEvents2Section .swiper-button-prev::before,
.featuredEvents2Section .swiper-button-next::before {
    font-family: 'PrimeIcons';
}

.featuredEvents2Section .swiper-button-prev {
    left: 0 !important;
}

.featuredEvents2Section .swiper-button-next {
    right: auto !important;
    left: 54px !important;
}

.featuredEvents2Section .swiper-button-next::before {
    content: '\e932';
    color: var(--accent_color4);
    font-size: 22px;
}

.featuredEvents2Section .swiper-button-prev::before {
    content: '\e931';
    color: var(--accent_color4);
    font-size: 22px;
}

@media (max-width: 767.98px) {
    .featuredEvents2Section .textMainHldr {
        margin: 0 0 30px 0;
    }

    .featuredEvents2Section .swiper-pagination-bullet-active {
        background: var(--primary_color) !important;
    }
}
