.institutionsSecation {
    position: relative;
}

.institutionsSecation .search-hldr {
    position: relative;
}

.institutionsSecation .pi-times {
    color: #bdbdbd;
    position: absolute;
    top: 1.1em;
    right: 1.3em;
    font-size: 1em;
    z-index: 999;
}

.institutionsSecation .search-hldr input {
    padding-left: 2.25em;
    padding-right: 2em;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    box-shadow: inset 0 0 0 1px rgba(67, 67, 67, 0.2);
    border-radius: 0.375em;
}

.institutionsSecation .search-hldr i {
    position: absolute;
    top: 0.8em;
    left: 1.1em;
    color: rgba(67, 67, 67, 0.48);
    z-index: 1;
}

.institutionsSecation ul.leftLink {
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0;
}

.institutionsSecation ul.leftLink li {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid rgba(4, 111, 68, 0.1);
    text-align: left;
    -webkit-transition: all 0s ease-in-out;
    -moz-transition: all 0s ease-in-out;
    -o-transition: all 0s ease-in-out;
    transition: all 0s ease-in-out;
}

.institutionsSecation ul.leftLink li span {
    font-size: 1em;
    display: block;
    color: #434343;
    border-radius: 4px;
    padding: 1em 0.625em;
    -webkit-transition: all 0s ease-in-out;
    -moz-transition: all 0s ease-in-out;
    -o-transition: all 0s ease-in-out;
    transition: all 0s ease-in-out;
    font-weight: 500;
}

.institutionsSecation ul.leftLink li span i {
    position: relative;
    width: 1.5625em;
    font-size: 1.125em;
    vertical-align: middle;
    top: -0.0625em;
    display: inline-block;
    color: var(--primary_color);
    -webkit-transition: all 0s ease-in-out;
    -moz-transition: all 0s ease-in-out;
    -o-transition: all 0s ease-in-out;
    transition: all 0s ease-in-out;
}

.institutionsSecation ul.leftLink li span.active,
.institutionsSecation ul.leftLink li span:hover {
    background-color: var(--primary_color);
    color: var(--accent_color4);
}

.institutionsSecation ul.leftLink li span.active i,
.institutionsSecation ul.leftLink li span:hover i {
    color: var(--accent_color4);
}

.institutionsSecation .Council-Addr-Hldr {
    position: relative;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.institutionsSecation .Council-Addr-Hldr .logoMain {
    position: relative;
    width: 6.25em;
    height: 6.25em;
    margin-right: 0.9375em;
    overflow: hidden;
}

.institutionsSecation .Council-Addr-Hldr .logoMain img {
    width: 100%;
    height: 6.25em;
}

.institutionsSecation .Council-Addr-Hldr .Council-address-main {
    position: relative;
    display: grid;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.institutionsSecation .Council-Addr-Hldr .Council-address-main.grid-size-50 {
    grid-template-columns: 50% 50%;
}

.institutionsSecation .Council-Addr-Hldr .Council-address-main .council {
    position: relative;
}

.institutionsSecation .Council-Addr-Hldr .Council-address-main .council h2 {
    font-size: 1.375em;
    font-weight: 600;
}

.institutionsSecation .Council-Addr-Hldr .Council-address-main .council p {
    color: grey;
}

.institutionsSecation .addressMain {
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
}

.institutionsSecation .addressMain .textMain {
    position: relative;
}

.institutionsSecation .addressMain .textMain h2 {
    font-size: 1.375em;
    font-weight: 600;
}

.institutionsSecation .addressMain .textMain p {
    color: grey;
}

.tableMainWrapper {
    position: relative;
}

.tableMainWrapper .TitleWrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
}

.tableMainWrapper .TitleWrapper h2 {
    margin: 0;
    padding-bottom: 0.6666666667em;
    display: block;
    font-size: 1.375em;
    font-weight: 600;
}

.tableMainWrapper .my-req-filters {
    white-space: nowrap;
    display: flex;
}

.tableMainWrapper .my-req-filters .selectDropdown {
    display: flex;
}

.tableMainWrapper .my-req-filters .selectDropdown:first-child {
    width: 22%;
}

.tableMainWrapper .table-listing {
    width: 100%;
}

.tableMainWrapper .table-listing thead {
    background: var(--primary_color);
    border-radius: 0.25em;
}

.tableMainWrapper .table-listing thead th {
    vertical-align: bottom;
    border: none;
    font-size: 0.875em;
    font-weight: 700;
    color: var(--accent_color4);
    text-transform: uppercase;
}

.tableMainWrapper .table-listing td,
.tableMainWrapper .table-listing th {
    padding: 1em 1em;
    vertical-align: middle;
    border-bottom: 1px solid rgba(4, 111, 68, 0.1);
    color: #666;
}

.tableMainWrapper .table-listing td .action-icon,
.tableMainWrapper .table-listing th .action-icon {
    font-size: 1.5em;
    line-height: 1;
}

.tableMainWrapper .table-listing td {
    color: rgba(102, 102, 102, 0.75);
    font-size: 0.875em;
    position: relative;
}

.tableMainWrapper .table-listing td .downArrow {
    display: none;
}

.tableMainWrapper .table-listing td .userImg {
    min-width: 2.5em;
    width: 2.5em;
    height: 2.5em;
    border-radius: 6.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.9375em;
}

.tableMainWrapper .table-listing td .userImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.tableMainWrapper .table-listing td .userInfo {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.tableMainWrapper .table-listing td .userInfo .userName {
    color: var(--accent_color1);
    font-size: 14px;
    line-height: 1;
    margin-bottom: 0.4375em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding-right: 0.75em;
    font-weight: 400;
}

.tableMainWrapper .table-listing td .userInfo .text-muted {
    font-size: 0.8125em;
    line-height: 1;
    color: var(--accent_color3);
    text-align: right;
}

.table-link {
    font-weight: 400;
    color: var(--accent_color3) !important;
    font-size: 14px;
}

.tableMainWrapper .table-listing td h4 {
    color: #666;
    padding-bottom: 0.1875em;
    font-size: 1em;
}

.tableMainWrapper .table-listing td h4 a {
    color: inherit;
}

.tableMainWrapper .table-listing td .small-text {
    font-size: 0.875em;
    color: rgba(102, 102, 102, 0.48);
}

.tableMainWrapper .table-listing td .small-text a {
    color: inherit;
}

.tableMainWrapper .table-listing td .table-link {
    color: var(--accent_color1);
    font-size: 0.875em;
}

.tableMainWrapper .table-listing td .descImg {
    width: 3em;
    height: 3em;
    display: inline-block;
    border-radius: 3.75em;
    margin-right: 0.75em;
}

.tableMainWrapper .table-listing .btn-link {
    color: var(--primary_color);
    text-transform: uppercase;
}

.tableMainWrapper .table-listing tr .approval-actions {
    background: rgba(237, 245, 246, 0.46);
}

.tableMainWrapper .table-listing tr .action-icon {
    color: inherit;
}

.tableMainWrapper .table-listing tr .action-icon .arrow-up {
    display: none;
}

.tableMainWrapper .table-listing tr .action-icon .arrow-down {
    display: inline-block;
}

.tableMainWrapper .table-listing tr:hover td {
    background: rgba(4, 111, 68, 0.02);
}

.tableMainWrapper .table-listing tr.active-main td {
    background: #edf5f6;
}

.tableMainWrapper .table-listing tr.active-main .action-icon .arrow-up {
    display: inline-block;
}

.tableMainWrapper .table-listing tr.active-main .action-icon .arrow-down {
    display: none;
}

.tableMainWrapper .table-listing tr.active td {
    background: rgba(237, 245, 246, 0.46);
}

.tableMainWrapper .table-listing tr.active .approval-actions {
    display: table-cell;
}

.tableMainWrapper .table-pagination {
    margin-top: 1.875em;
}

.tableMainWrapper .table-pagination .pagination {
    justify-content: center;
    font-size: 1em;
}

.tableMainWrapper .table-pagination .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--primary_color);
    border-color: var(--primary_color);
    color: var(--accent_color4);
}

.tableMainWrapper .table-pagination .pagination .page-item {
    margin-left: 0;
    margin-right: 0;
}

.tableMainWrapper .table-pagination .pagination .page-item .page-link {
    color: #666;
    border-color: rgba(32, 72, 108, 0.24);
    text-align: center;
    min-width: 2.5em;
    font-size: 1em;
    line-height: 2;
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-transition: all 0s ease-in-out;
    -moz-transition: all 0s ease-in-out;
    -o-transition: all 0s ease-in-out;
    transition: all 0s ease-in-out;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -o-border-radius: 6px;
    -ms-border-radius: 6px;
    border-radius: 6px;
}

.institutionsSecation .p-paginator {
    background: none;
}

.institutionsSecation .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--primary_color);
    border-color: var(--primary_color);
    color: var(--accent_color4);
}

.tableMainWrapper .table-pagination .pagination .page-item + .page-item {
    margin-left: 0.625em;
}

.tableMainWrapper .table-pagination .pagination .page-item:first-child {
    margin-left: 0.625em;
}

.tableMainWrapper .table-pagination .pagination .page-item:hover .page-link {
    color: #666;
    background: rgba(4, 111, 68, 0.16);
}

.tableMainWrapper .table-pagination .pagination .page-item.active .page-link {
    color: #fff;
    border-color: #56ab91;
    background: #56ab91;
}

.tableMainWrapper .userDataWrapper {
    position: relative;
    margin: 0;
    padding: 0 0 0.625em 0;
}

.tableMainWrapper .userDataWrapper ul {
    list-style: none;
    margin: 0 -0.75em;
    display: flex;
    width: 100%;
}

.tableMainWrapper .userDataWrapper ul li {
    position: relative;
    margin: 0 0.75em;
    padding: 1.25em;
    display: inline-block;
    background: #fff;
    height: 100%;
    border-radius: 0.25em;
    border: 1px solid #fff;
    cursor: pointer;
    min-width: 33.33%;
}

.tableMainWrapper .userDataWrapper ul li h2 {
    font-size: 1.25em;
    line-height: normal;
    padding-bottom: 0.125em;
    color: var(--accent_color1);
    font-weight: 500;
}

.tableMainWrapper .userDataWrapper ul li p {
    font-size: 0.875em;
}

.tableMainWrapper .p-dropdown {
    position: relative;
    font-size: 1em;
    height: 2.5em;
    border-radius: 0.375em;
    border: 0;
    background: #fff;
    box-shadow: inset 0 0 0 1px rgba(67, 67, 67, 0.2);
    width: 100%;
}

.institutionsSecation .search {
    top: 0.755em !important;
    right: 2em !important;
}

.custom-scrollbar {
    scrollbar-width: thin;
    --scrollbar-color: rgba(0, 0, 0, 0.1);
    --scrollbar-track-color: #f3f3f3;
    --scrollbar-size: 6px;
    --scrollbar-size-firefox: thin;
    --scrollbar-color-hover: rgba(0, 0, 0, 0.1);
    --scrollbar-color-active: rgba(0, 0, 0, 0.1);
    scrollbar-color: var(--scrollbar-color) var(--scrollbar-track-color);
    scrollbar-width: var(--scrollbar-size-firefox);
}

.custom-scrollbar::-webkit-scrollbar {
    height: var(--scrollbar-size);
    width: var(--scrollbar-size);
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color-hover);
}

.custom-scrollbar::-webkit-scrollbar-thumb:active {
    background-color: var(--scrollbar-color-active);
}

.institutionsSecation .floating-select {
    box-shadow: inset 0 0 0 1px var(--accent_color3);
    color: var(--accent_color3);
}

.institutionsSecation .p-inputtext {
    padding: 0.5em 0.75rem;
}

.institutionsSecation .btn .btn-icon {
    font-size: 1em;
}

.institutionsSecation .search-hldr1 input {
    padding-left: 2em;
    padding-right: 2.25em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    box-shadow: inset 0 0 0 1px rgba(67, 67, 67, 0.2);
    border-radius: 0.375em;
}

.institutionsSecation .search-hldr1 {
    position: relative;
}

.institutionsSecation .search-hldr1 i {
    position: absolute;
    top: 0.60em;
    left: 0.9em;
    color: rgba(67, 67, 67, 0.48);
    z-index: 1;
}

@media (max-width: 767.98px) {
    .institutionsSecation .infoDropMain {
        margin-top: 1.5em;
    }

    .institutionsSecation .Council-Addr-Hldr {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .institutionsSecation .Council-Addr-Hldr .logoMain {
        margin-right: 0;
        margin-bottom: 0.9375em;
    }

    .institutionsSecation .Council-Addr-Hldr .Council-address-main .council h2 {
        margin-bottom: 0.25em;
    }

    .institutionsSecation .Council-Addr-Hldr .Council-address-main .council p {
        margin-bottom: 0.25em;
    }

    .institutionsSecation .addressMain {
        display: block;
    }

    .institutionsSecation .addressMain .textMain {
        margin-bottom: 0.75em;
    }

    .institutionsSecation .addressMain .textMain:last-child {
        margin-bottom: 0;
    }

    .institutionsSecation .tableMainWrapper .table-listing thead {
        display: none;
    }

    .institutionsSecation .tableMainWrapper .table-listing tbody tr {
        border-bottom: 1px solid rgba(4, 111, 68, 0.1);
        display: grid;
        margin-bottom: 0.625em;
        padding-bottom: 0.625em;
    }

    .institutionsSecation .tableMainWrapper .table-listing tbody tr td {
        border-bottom: none;
        padding: 0.3125em;
    }

    .tableMainWrapper .table-listing td .downArrow {
        display: block;
        position: absolute;
        top: 12px;
        right: 4px;
        font-size: 1.5em;
    }

    .tableMainWrapper .table-listing tr .action-icon .arrow-down {
        display: inline-block;
        display: none;
    }

    .institutionsSecation .tableMainWrapper .table-listing tbody tr td.arrowMain {
        display: none;
    }

    .tableMainWrapper .userDataWrapper ul li {
        min-width: 100%;
    }

    .tableMainWrapper .my-req-filters .selectDropdown {
        display: block;
        width: 100%;
    }

    .tableMainWrapper .my-req-filters {
        display: block;
    }

    .institutionsSecation .search-hldr1 i {
        top: 12px;
    }
}
