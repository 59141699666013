.paragraphMedia {
    position: relative;
}

.paragraphMedia .heading {
    font-size: 1.75em;
    font-weight: 700;
    color: #333333;
    line-height: 32px;
}

.paragraphMedia .wrapping-img {
    float: left;
    margin-right: 30px;
    width: 50%;
}

.paragraphMedia .only-img {
    margin: 0 auto;
}

.paragraphMedia .wrapping-img .imgtext {
    color: #333333;
    padding: 0.625em;
    font-size: 0.875em;
    font-weight: 500;
    line-height: 24px;
}

.paragraphMedia .wrapping-img .acknowledgementText {
    color: #828282;
    padding: 0.625em;
    font-size: 0.875em;
    font-weight: 500;
    line-height: 24px;
    font-style: italic;
}

.paragraphMedia .only-img .imgtext {
    color: #333333;
    padding: 0.625em;
    font-size: 0.875em;
    font-weight: 500;
    line-height: 24px;
}

.paragraphMedia .only-img .acknowledgementText {
    color: #828282;
    padding: 0.625em;
    font-size: 0.875em;
    font-weight: 500;
    line-height: 24px;
    font-style: italic;
}

.paragraphMedia .wrapping-img img {
    width: 100%;
    border-radius: 15px;
}

.paragraphMedia .only-img img {
    border-radius: 15px;
}

.paragraphMedia .text p {
    font-size: 1.25em;
    margin-bottom: 1em;
    line-height: 32px;
    font-weight: 400;
}

.paragraphMedia .text p a {
    color: var(--primary_color) !important;
    text-decoration: underline !important;
}

@media (max-width: 767.98px) {
    .paragraphMedia .wrapping-img {
        float: none;
        width: 100%;
        margin-right: 0;
    }
}
