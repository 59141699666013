.programme-std-body-container {
    position: relative;
    padding: 2.125em 0 2.125em;
}

.programme-std-body-content {
    display: none;
}

.programme-std-body-content p {
    font-size: 1.25em;
    margin-bottom: 1.5em;
    font-weight: 400;
    line-height: 40px;
}

.programme-std-body-content p a {
    color: var(--primary_color) !important;
    text-decoration: underline !important;
}

.programme-std-body-content p strong {
    font-weight: 700;
}

.programme-std-body-content .btnHldr {
    position: relative;
    display: flex;
    align-items: center;
}

.programme-std-body-content .btnHldr Button.link {
    color: #282828;
    padding: 0.625em 1.4375em;
    border: 1px solid #046f44;
    border-radius: 0.9375em;
    display: flex;
    align-items: center;
    margin-right: 1.5em;
    background: white;
    font-size: 1.25em;
    font-weight: 500;
    text-align: left;
}

.programme-std-body-content .btnHldr Button.link i {
    margin-left: 0.5em;
    font-size: 1.175em;
}

.programme-std-body-content.current {
    display: inherit;
}

@media (max-width: 767.98px) {
    .programme-std-body-container {
        padding-top: 0;
        padding-bottom: 0;
    }

    .programme-std-body-content .btnHldr {
        display: block;
    }

    .programme-std-body-content .btnHldr a.link {
        margin-right: 0;
        margin-top: 1.5em;
    }

    .programme-std-body-content p {
        font-size: 0.875em;
    }

    .programme-std-body-content .btnHldr Button.link {
        font-size: 0.875em;
        margin-top: 1em;
    }
}

[dir='rtl'] .programme-std-body-content .pi-angle-right:before {
    content: '\e931';
}

[dir='rtl'] .programme-std-body-content .btnHldr Button.link {
    margin-left: 1.5em;
    margin-right: 0;
}
