.CulturalSecation {
    position: relative;
}

.CulturalSecation .card {
    border: none;
    border-radius: 0.5em;
    height: auto;
    padding: 20px;
}

.CulturalSecation .card:hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.CulturalSecation .card .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.4;
    display: flex;
}

.CulturalSecation .titleMain {
    position: relative;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0.9375em 1.25em 0.9375em 0;
}

.CulturalSecation .titleMain h3 {
    font-size: 2em;
    font-style: normal;
    font-weight: 700;
    line-height: 35.2px;
    color: var(--primary_color);
    padding-left: 0.8125em;
}

.CulturalSecation .titleMain h3::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    border-radius: 1em;
    background: var(--primary_color);
}

.CulturalSecation .card .cardImg img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    animation: fadeIn2 1s;
    -webkit-animation: fadeIn2 1s;
    -moz-animation: fadeIn2 1s;
    -o-animation: fadeIn2 1s;
    -ms-animation: fadeIn2 1s;
}

.CulturalSecation .card .card-body {
    position: relative;
    border: none;
    flex: initial;
    padding: 20px 0 0;
}

.CulturalSecation .card .card-body h3 {
    font-size: 1.375em;
    margin: 0;
    padding: 0;
    line-height: normal;
    text-align: center;
    height: auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 700;
}

.CulturalSecation .swiper {
    padding-top: 26px !important;
}

.CulturalSecation .swiper-button-prev,
.swiper-button-next {
    margin-top: 0;
}

.CulturalSecation .swiper-button-next {
    margin-top: 0;
}

.CulturalSecation .swiper-button-prev {
    top: 2% !important;
}

.CulturalSecation .swiper-button-next {
    top: 2% !important;
}

@media (max-width: 767.98px) {
    .CulturalSecation .card {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        transition: all 0.5s ease-in-out;
    }

    .swiper-pagination-bullet-active {
        background: var(--primary_color) !important;
    }
}
