.latestWrapper {
    position: relative;
    padding-bottom: 3em;
    padding-top: 3em;
}

.latestWrapper .titleMain {
    position: relative;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0.9375em 1.25em 0.9375em 0;
}

.latestWrapper .titleMain h3 {
    color: #333;
    font-size: 2em;
    font-style: normal;
    font-weight: 700;
    line-height: 35.2px;
    color: var(--primary_color);
    padding-left: 0.8125em;
}

.latestWrapper .titleMain h3::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    border-radius: 1em;
    background: var(--primary_color);
}

.latestWrapper .card-titleMain {
    position: relative;
    background: none;
    padding: 0;
    top: 30px;
}

.latestWrapper .card-titleMain h3 {
    color: #333;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    color: var(--primary_color);
}

.latestWrapper .sub-title {
    color: #000;
    font-size: 0.8em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.latestWrapper .newsCardBig {
    position: relative;
    background: #fff;
    border-radius: 0.9375em;
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.03);
    border: 1px solid #e5eaf4;
}

.latestWrapper .newsCardBig .NewsImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.latestWrapper .newsCardBig .NewsImg img {
    aspect-ratio: 1.4;
    width: 100%;
    border-radius: 0.9375em 0 0 0.9375em;
    object-fit: cover;
}

.latestWrapper .newsCardBig .card-body {
    position: relative;
    padding: 1.875em 0;
    margin: 0 auto;
    width: 80%;
}

.latestWrapper .newsCardBig .card-body .microsite {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
}

.latestWrapper .newsCardBig .card-body .microsite::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    border-radius: 1em;
    background: red;
}

.latestWrapper .newsCardBig .card-body .iconMain {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 0.625em;
}

.latestWrapper .newsCardBig .card-body .iconMain .mins {
    font-size: 0.875em;
    font-weight: 300;
    margin-right: auto;
}

.latestWrapper .newsCardBig .card-body .iconMain ul {
    position: relative;
    margin: 0;
    padding: 0;
}

.latestWrapper .newsCardBig .card-body .iconMain ul li {
    list-style: none;
    margin-right: 0.1875em;
    font-size: 1.375em;
    line-height: normal;
    display: inline-block;
}

.latestWrapper .newsCardBig .card-body .iconMain ul li a {
    color: #1d1d1d;
}

.latestWrapper .newsCardBig .card-body .iconMain ul li a.active,
.latestWrapper .newsCardBig .card-body .iconMain ul li a:hover {
    color: #4f887a;
}

.latestWrapper .newsCardBig .card-body h3 {
    font-size: 1.5em;
    margin: 0 0 0.1875em 0;
    padding: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    height: 2.5em;
    font-weight: 700;
}

.latestWrapper .newsCardBig .card-body .date {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
    font-weight: 300;
}

.latestWrapper .newsCardBig .card-body .author {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
    font-weight: 300;
}

.latestWrapper .newsCardBig .card-body .textMain {
    position: relative;
    margin: 1.5em 0;
    padding: 0;
    font-size: 0.875em;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    line-height: normal;
    height: 7.5em;
}

.latestWrapper .card .card-body .tag-heading {
    color: #046f44;
    font-size: 0.625em;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
    letter-spacing: 2.1px;
    text-transform: uppercase;
}

.latestWrapper .newsCardBig .card-body .hashtag {
    position: relative;
    display: inline-block;
    padding: 0.1875em 0.9375em;
    border-radius: 0.25em;
    background: #eef7f4;
    color: #046f44;
    font-size: 0.875em;
    font-weight: 700;
}

.latestWrapper .newsCard {
    position: relative;
    border-radius: 0.5em;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #e3e4e8;
}

.latestWrapper .card {
    height: 100%;
}

.latestWrapper .card .card-body {
    border: none !important;
}

.latestWrapper .swiper-slide {
    height: auto;
    margin-right: 10px;
    margin-left: 0;
    margin-bottom: 0px;
    margin-top: 20px;
}

.latestWrapper .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.5;
    display: flex;
}

.latestWrapper .newsCard .cardImg img {
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 100%;
    object-fit: cover;
}

.latestWrapper .newsCard .cardImg .tag {
    z-index: 2;
    position: absolute;
    bottom: 0.9375em;
    right: 0.9375em;
    padding: 0.375em 0.5625em;
    border: 1px solid #f2f2f3;
    border-radius: 4px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.8) 0, rgba(255, 255, 255, 0.55) 100%);
    box-shadow: 0 20px 40px 0 rgba(57, 124, 191, 0.1);
    backdrop-filter: blur(20px);
    color: #1d1d1d;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.75em;
    text-transform: uppercase;
}

.latestWrapper .newsCard .card-body {
    position: relative;
    padding: 1.25em;
    margin: 0;
}

.latestWrapper .newsCard .card-body .iconMain {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 0.625em;
}

.latestWrapper .newsCard .card-body .iconMain .mins {
    font-size: 0.875em;
    font-weight: 300;
    margin-right: auto;
}

.latestWrapper .newsCard .card-body .iconMain ul {
    position: relative;
    margin: 0;
    padding: 0;
}

.latestWrapper .newsCard .card-body .iconMain ul li {
    list-style: none;
    margin-right: 0.1875em;
    font-size: 1.375em;
    line-height: normal;
    display: inline-block;
}

.latestWrapper .newsCard .card-body .iconMain ul li a {
    color: #1d1d1d;
}

.latestWrapper .newsCard .card-body .iconMain ul li a.active,
.latestWrapper .newsCard .card-body .iconMain ul li a:hover {
    color: #4f887a;
}

.latestWrapper .newsCard .card-body h3 {
    color: #171821;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1.5em;
}

.latestWrapper .button {
    color: var(--Grey-3, var(--Grey-3, #828282));
    text-align: center;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    border: 1px solid var(--Grey-3, #828282);
    border-radius: 0.625em;
    background: var(--White, #fff);
    display: inline-flex;
    padding: 0.375em 1.5em;
    align-items: center;
    gap: 10px;
}

.latestWrapper .newsCard .card-body .date {
    color: #83858f;
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.latestWrapper .newsCard .card-body .author {
    color: #046f44;
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.latestWrapper .newsCard .card-body .textMain {
    position: relative;
    margin: 0.5em 0;
    padding: 0;
    font-size: 0.875em;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    height: 4em;
    margin-bottom: 20px;
}

.latestWrapper .newsCard .card-body .hashtag {
    position: relative;
    display: inline-block;
    padding: 0.1875em 0.9375em;
    border-radius: 0.25em;
    background: #eef7f4;
    color: #046f44;
    font-size: 0.875em;
    font-weight: 700;
}

.card-padding {
    padding: 0 27px 0 27px !important;
}

.latestWrapper .swiper-button-prev,
.swiper-button-next {
    margin-top: 0;
}

.latestWrapper .swiper-button-next {
    margin-top: 0;
}

.latestWrapper .swiper-button-prev {
    top: 1% !important;
}

.latestWrapper .swiper-button-next {
    top: 1% !important;
}

.latestWrapper .swiper {
    padding-top: 30px !important;
}

.latestWrapper .swiper-button-prev,
.latestWrapper .swiper-button-next {
    top: -5px !important;
}

.latestWrapper .btn-icon {
    margin-top: 1px;
}

.latestWrapper .swiper-pagination-bullet-active {
    width: 43px !important;
    height: 7px !important;
    border-radius: 10px !important;
    background: var(--primary_color) !important;
}

@media (max-width: 767.98px) {
    .latestWrapper .swiper-slide {
        margin: 0;
    }

    .padding {
        padding: 15px;
    }

    .latestWrapper .newsCardBig .NewsImg img {
        border-radius: 0;
    }

    .latestWrapper .newsCardBig {
        border-radius: 0;
        box-shadow: none;
        border: none;
    }

    .latestWrapper .newsCardBig .card-body {
        width: auto;
    }

    .latestWrapper .newsCardBig .card-body .textMain {
        height: auto;
    }

    .latestWrapper .newsCard {
        margin: 0;
        padding: 10px;
        border: none;
    }

    .latestWrapper .newsCard .card-body .textMain {
        height: auto;
    }

    .card-padding {
        padding: 0 0.75em !important;
    }

    .eventWrapper .swiper {
        padding-top: 40px !important;
    }

    .latestWrapper .sub-title {
        line-height: 25.2px;
    }

    .latestWrapper .titleMain h3 {
        font-size: 2em;
    }

    .latestWrapper .sub-title {
        font-size: 1em;
        line-height: normal;
    }

    .latestWrapper .newsCard {
        display: flex;
        flex-direction: row;
        margin: 0px 10px;
        box-shadow: 3px 3px 5px 0px #0000001a;
    }

    .latestWrapper .newsCard .cardImg {
        width: 150px;
        flex-shrink: 0 !important;
        height: 90px;
        display: block;
    }

    .latestWrapper .newsCard .cardImg img {
        border-radius: 10px;
        height: 100%;
    }

    .latestWrapper .newsCard .card-body {
        padding: 0.375em 0.9375em;
    }

    .latestWrapper .card .card-body .tag-heading {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px;
    }

    .latestWrapper .newsCard .card-body h3 {
        font-size: 12px;
        margin-bottom: 0;
        margin-top: 5px;
    }

    .latestWrapper .newsCard .card-body .author,
    .latestWrapper .newsCard .card-body .date {
        font-size: 0.6em;
    }

    .latestWrapper .swiper-pagination-fraction,
    .latestWrapper .swiper-pagination-custom,
    .latestWrapper .swiper-horizontal > .swiper-pagination-bullets,
    .latestWrapper .swiper-pagination-bullets.swiper-pagination-horizontal {
        display: block;
    }

    .latestWrapper .swiper-button-prev {
        display: none;
    }

    .latestWrapper .swiper-button-next {
        display: none;
    }

    .swiper-slide .grid-container {
        margin: 0 -30px;
    }
    .swiper-slide .grid-container .grid-item {
        margin: 0px 22px 24px;
    }

    .latestWrapper .swiper {
        padding-bottom: 25px !important;
    }

    .latestWrapper .button {
        margin-top: 1em;
    }

    .latestWrapper .authorDateMain {
        margin-top: 25px;
    }
}

[dir='rtl'] .latestWrapper .titleMain h3 {
    padding-left: 0;
    padding-right: 0.8125em;
}

[dir='rtl'] .latestWrapper .titleMain h3::before {
    right: 0;
}

[dir='rtl'] .latestWrapper .swiper-slide {
    margin-right: auto;
}

[dir='rtl'] .latestWrapper .pi-arrow-right::before {
    content: '\e91a';
}
