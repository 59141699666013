.FeturesTabComponent {
    position: relative;
    padding-top: 5em;
}

.FeturesTabComponent .additional-padding {
    padding: 0 5%;
}

.FeturesTabComponent .p-tabview .p-tabview-nav {
    border: none !important;
    justify-content: center !important;
}

.FeturesTabComponent .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: #046f44;
    color: var(--Grey-2, #4f4f4f);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

.FeturesTabComponent .p-tabview-nav-container {
    padding-bottom: 10px;
}

.FeturesTabComponent .p-tabview .p-tabview-panels {
    padding: 0 !important;
}

.FeturesTabComponent .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: #046f44;
}

.FeturesTabComponent .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.FeturesTabComponent .p-tabview .p-tabview-nav-btn.p-link {
    box-shadow: none;
}

.FeturesTabComponent .p-tabview .p-tabview-title {
    font-size: 20px;
    font-weight: 400;
    color: var(--accent_color3) !important;
    line-height: 28px;
}

.FeturesTabComponent .FeturesTabComponent .p-tabview .p-tabview-nav-btn.p-link {
    box-shadow: none;
}

.FeturesTabComponent .p-tabview .p-tabview-nav-btn.p-link {
    color: #046f44;
}

.FeturesTabComponent .p-tabview .p-tabview-nav-btn.p-link:focus {
    box-shadow: none;
}

.FeturesTabComponent .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: var(--Grey-2, #4f4f4f);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

.FeturesTabComponent .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    padding-left: 0;
    padding-right: 0;
}

.FeturesTabComponent .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #046f44;
    bottom: 0;
    left: 0;
    right: 0;
}

.FeturesTabComponent .right-fade {
    position: absolute;
    z-index: 23;
    right: 55px;
    top: 13px;
    width: 118px;
    height: 30px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 7.41%, #ffffff 100%);
}

.FeturesTabComponent .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    position: relative;
    padding: 0;
    padding-bottom: 1em;
    color: #4f4f4f;
}

.FeturesTabComponent .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 3px;
    height: 2px;
    width: 100%;
    background: var(--primary_color) !important;
}

.FeturesTabComponent .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    display: none !important;
}

.FeturesTabComponent .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: none !important;
}

.FeturesTabComponent .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0;
    padding-bottom: 1em;
}

.FeturesTabComponent .p-tabview .p-tabview-nav li {
    margin: 0 1em !important;
}

.FeturesTabComponent {
    position: relative;
}

.FeturesTabComponent .Componenthldr {
    position: relative;
    margin: 0;
    padding: 0;
    height: 30em;
    width: 100%;
    overflow: hidden;
}

.FeturesTabComponent .Componenthldr img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.FeturesTabComponent .Componenthldr .carousel-caption {
    position: relative;
    background: rgba(31, 33, 43, 0.7);
    width: 37.5em;
    height: auto;
    padding: 1.5em;
    border-radius: 1.25em;
    text-align: left;
    bottom: auto;
    top: 0;
    color: #fff;
    z-index: 1;
    left: 0;
}

.FeturesTabComponent .Componenthldr .carousel-caption h3 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 0.3125em;
}

.FeturesTabComponent .Componenthldr .carousel-caption p {
    font-size: 22px;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 12px;
    margin-top: 8px;
}

.FeturesTabComponent .Componenthldr .carousel-caption .discoverMain {
    position: relative;
    text-align: left;
}

.FeturesTabComponent .Componenthldr .carousel-caption .discoverMain .discover-experiences-Btn {
    position: relative;
    padding: 0.625em 1.125em;
    border: none;
    background: #00558c;
    border-radius: 3.125em;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}

.fade-in {
    animation-name: fade-in;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: 767.98px) {
    .FeturesTabComponent .Componenthldr .carousel-caption {
        width: 100%;
        top: auto;
        left: 0;
        bottom: 0;
    }

    .FeturesTabComponent .Componenthldr .carousel-caption h3 {
        font-size: 1.25em;
    }

    .FeturesTabComponent .Componenthldr .carousel-caption p {
        font-size: 0.875em;
    }

    .FeturesTabComponent .Componenthldr .carousel-caption .discoverMain .discover-experiences-Btn {
        font-size: 1em;
        width: 100%;
    }

    .FeturesTabComponent .p-tabview .p-tabview-title {
        font-size: 1em;
    }

    .FeturesTabComponent .p-tabview .p-tabview-nav {
        justify-content: normal !important;
    }
}
