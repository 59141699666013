.SportscategoryWrapper {
    position: relative;
}

.SportscategoryWrapper .titleHldr {
    position: relative;
    margin-bottom: 50px;
}

.SportscategoryWrapper .titleHldr h2 {
    font-size: 32px;
    margin: 0;
    padding: 0;
    color: var(--primary_color);
    text-align: center;
}

.SportscategoryWrapper .titleHldr p {
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
    line-height: normal;
    color: var(--accent_color1);
}

.SportscategoryWrapper .sportCard {
    position: relative;
    margin: 0;
    padding: 0;
    height: 100%;
}

.SportscategoryWrapper .sportCard .sportCardImage {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.5;
    display: flex;
    border-radius: 15px 15px 0px 0px;
}

.SportscategoryWrapper .sportCard .sportCardImage img {
    width: 100%;
    height: 100%;
    transition: transform 1s cubic-bezier(.11,.74,.35,.92);
}

.SportscategoryWrapper .sportCard .sportCardImage img:hover {
    transform: scale(1.05);
}

.SportscategoryWrapper .no-image {
    align-items: center;
    justify-content: center;
    display: flex;
}

.SportscategoryWrapper .sportCard .sport-body {
    position: relative;
    padding: 18px;
    border-radius: 0px;
}

.SportscategoryWrapper .sportCard .sport-body h3 {
    font-size: 24px;
    margin: 0 0 0.75em 0;
    padding: 0;
    line-height: normal;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: center;
    color: var(--accent_color1);
}

.SportscategoryWrapper .sportCard .sport-body p {
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    text-align: center;
    color: var(--accent_color1);
    font-weight: 400;
}

.SportscategoryWrapper .button {
    color: var(--accent_color4);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    border: 1px solid var(--primary_color);
    border-radius: 6px;
    background: var(--primary_color);
    display: inline-flex;
    padding: 0.375em 1.5em;
    align-items: center;
    gap: 10px;
}

.SportscategoryWrapper .showMore {
    color: var(--accent_color4);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    border-radius: 6px;
    background: #fff;
    text-align: center;
    cursor: pointer;
}
