.mailingSection {
    position: relative;
    padding-bottom: 1.5em;
    padding-top: 3em;
}

.mailingSection .heading h3 {
    color: #282828;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: capitalize;
}

.mailingSection .body {
    font-weight: 400;
    font-size: 14px;
    line-height: 24.5px;
    padding: 10px 0;
}

.mailingSection .card .card-body {
    border: 2px solid rgba(255, 255, 255, 0.6);
}

.mailingSection .boxWrapper {
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2.5em rgba(4, 111, 68, 0.06);
    border-radius: 0.5em;
    padding: 1.5em;
    height: 36rem;
}

.mailingSection .card .card-body .boxWrapper h3 {
    color: #282828;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 1rem;
    padding: 0;
    height: auto;
}

.mailingSection .card .card-body .boxWrapper span {
    color: #282828;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 2rem;
    padding: 0;
}

.mailingSection .card .card-body .boxWrapper .sub-heading {
    color: #56ab91;
    font-size: 1em;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0 0 2rem;
    padding: 0;
}

.mailingSection .p-paginator {
    padding: 0.5rem 0rem;
}

.mailingSection .p-paginator .p-paginator-first,
.mailingSection .p-paginator .p-paginator-prev,
.mailingSection .p-paginator .p-paginator-next,
.mailingSection .p-paginator .p-paginator-last {
    margin: 0;
}

.mailingSection .p-checkbox .p-checkbox-box {
    border: 1px solid var(--accent_color3) !important;
}

.mailingSection .p-checkbox .p-checkbox-box.p-highlight {
    background-color: var(--accent_color4) !important;
    border: 1px solid var(--primary_color) !important;
}

.mailingSection .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: var(--primary_color) !important;
}

.mailingSection .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    background: var(--accent_color4) !important;
}

.mailingSection .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: none !important;
}

.mailingSection .button {
    border-radius: 6px;
    background-color: #56ab91;
    border: 1px solid;
    color: white;
    display: block;
    padding: 0.625rem 3rem 0.625rem 3rem;
    font-weight: 500;
}

.mailingSection .button .btn-text {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.mailingSection .btn {
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid;
    color: #56ab91;
    display: block;
    font-size: 12px;
    text-align: center;
    margin-left: 10px;
    padding: 7px;
    font-weight: 600;
}

.mailingSection .btn .btn-text1 {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.mailingSection .bodyText p {
    font-size: 1em;
    font-weight: 400;
    color: var(--accent_color1);
    margin-bottom: 0.625em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: normal;
    line-height: 175%;
}

.mailingSection .p-paginator {
    justify-content: end;
}

.mailingSection .padding {
    padding-left: 1.1rem;
}

.mailingSection .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: var(--accent_color4);
    border-color: var(--primary_color);
    background: var(--primary_color);
}

.nav-tabs .nav-item {
    margin-bottom: 0;
}

.nav-tabs {
    border-bottom: 1px solid rgba(67, 67, 67, 0.24);
    padding: 0 0.9375em;
    font-size: 1em;
}

.mailingSection .nav-tabs .nav-link {
    color: rgba(67, 67, 67, 0.64);
    font-size: 1em;
    font-weight: 700;
    padding: 0.625em;
    line-height: normal;
    margin: 0 1em;
}

.mailingSection .nav-tabs .nav-link:focus,
.mailingSection .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.mailingSection .nav-tabs .nav-item.show .nav-link,
.mailingSection .nav-tabs .nav-link.active {
    position: relative;
    border-color: transparent;
    font-size: 1em;
    color: var(--primary_color);
    background: 0 0;
}

.nav-tabs .nav-link.active::after {
    content: '';
    position: absolute;
    height: 2px;
    background-color: #56ab91;
    bottom: -1px;
    left: 0;
    width: 100%;
}

.mailingSection .commonTable {
    width: 100%;
    margin-bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25em;
    background: #fff;
}

.mailingSection .commonTable td,
.mailingSection .commonTable th {
    padding: 0.75em;
    vertical-align: top;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 1em;
    position: relative;
}

.mailingSection .commonTable th {
    font-weight: 600;
}

.mailingSection .commonTable td {
    font-weight: 300;
    vertical-align: middle;
}

.mailingSection .tableText {
    color: var(--grey-700, #4d4d4d);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
}

.mailingSection .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--primary_color) !important;
}

.mailingSection .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: var(--primary_color) !important;
}

.mailingSection .statusHldr {
    display: flex;
}

.mailingSection .mailing-card-padding {
    padding: 0 27px;
}

@media (max-width: 767.98px) {
    .mailingSection .check {
        margin-top: 20px;
    }

    .mailingSection .boxWrapper {
        height: auto;
        margin-bottom: 1rem;
    }

    .padding {
        padding: 0;
    }

    .nav-tabs .nav-link {
        font-size: 10px;
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        font-size: 11px;
    }

    .mailingSection .nav-tabs .nav-link {
        margin: 0;
        font-size: 14px;
    }

    .mailingSection .nav-tabs .nav-item.show .nav-link,
    .mailingSection .nav-tabs .nav-link.active {
        font-size: 14px;
    }

    .mailingSection .statusHldr {
        display: block;
    }

    .mailingSection .card .card-body .boxWrapper span {
        font-size: 14px;
    }

    .mailingSection .card .card-body .boxWrapper .sub-heading {
        font-size: 14px;
    }

    .mailingSection .mailing-card-padding {
        padding: 0 5px;
    }

    .mailingSection .p-paginator {
        justify-content: center;
    }
}
