.mailingList .commonTable {
    width: 100%;
    margin-bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25em;
    background: #fff;
}

.mailingList .commonTable td,
.mailingList .commonTable th {
    padding: 0.75em;
    vertical-align: top;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 1em;
    position: relative;
}

.mailingList .commonTable th {
    font-weight: 700;
    color: var(--accent_color1);
}

.mailingList .commonTable td {
    font-weight: 300;
    vertical-align: middle;
}

.mailingList .commonTable tr.unread {
    background: #f1f1f1;
}

.mailingList .commonTable tr.unread td {
    font-weight: 600;
    color: var(--accent_color1);
    line-height: 28px;
}

.mailingList .commonTable tr.read {
    background: #fff;
}

.mailingList .commonTable tr.read td {
    font-weight: 400;
    color: var(--accent_color1);
    line-height: 28px;
}

.mailingList .commonTable .form-check input {
    margin-left: 0;
    margin-top: 0.375em;
}

.mailingList .pagination {
    display: flex;
    align-items: center;
    border-radius: 0;
}

.mailingList .pagination .page-item {
    position: relative;
}

.mailingList .pagination .page-item .page-link {
    display: flex;
    align-items: center;
    padding: 0.3125em 0.75em;
    height: 2.125em;
    line-height: 1;
    background: 0 0;
    border: none;
    color: #1d1d1d;
}

.mailingList .pagination .page-item .page-link.active,
.mailingList .pagination .page-item .page-link:hover {
    color: #046f44;
}

.mailingList .pagination .page-item .page-link i {
    font-size: 1.125em;
}

.mailingList .btn {
    position: relative;
    font-size: 1em;
    box-shadow: none;
    white-space: normal;
    padding: 0.5em 1.5em;
    border-radius: 0.25em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    text-decoration: none;
    font-weight: 600;
}

.mailingList .btn-orange-line {
    color: var(--primary_color);
    background: var(--accent_color4);
    border: 1px solid var(--primary_color);
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.mailingList .btn-orange-line .btn-icon {
    padding-left: 0.625em;
    padding-right: auto;
    -webkit-transition: transform 0.5s ease-in-out;
    -moz-transition: transform 0.5s ease-in-out;
    -o-transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
}

.mailingList .btn-orange-line .btn-icon.btn-icon-before {
    padding-left: 0;
    padding-right: 0.625em;
}

.mailingList .btn-orange-line:hover {
    color: var(--accent_color4);
    background-color: var(--primary_color);
}

.mailingList .btn-orange-line:hover .btn-icon:not(.btn-icon-before) {
    transform: translateX(0.75em);
}

.mailingList .table-pagination .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--primary_color);
    border-color: var(--primary_color);
    color: var(--accent_color4);
}

.mailingList .form-check-input:checked {
    background-color: var(--primary_color);
    border-color: var(--primary_color);
}

.mailingList .title {
    font-size: 2em;
    padding: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    font-weight: 600;
}

.mailingList .titleText {
    font-size: 1em;
    padding: 0;
    line-height: normal;
    font-weight: 600;
    word-break: break-all;
}

.mailingList .date {
    font-size: 1em;
    padding: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    font-weight: 600;
    text-align: end;
}

.mailingList .bodyText p {
    font-size: 1em;
    font-weight: 400;
    color: #666;
    margin-bottom: 0.625em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: normal;
}

.mailingList .detail-message {
    font-weight: 400;
}

.mailingList .msg {
    color: #56ab91;
}

.mailingList .msg:hover {
    color: #a5ccc0;
}

.mailingList .p-paginator {
    background: none;
    justify-content: end;
}

.mailingList .text {
    color: #56ab91;
    text-align: center;
    font-weight: 600;
}

.mailingList .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: var(--accent_color4);
    border-color: var(--primary_color);
    background: var(--primary_color);
}

.mailingList .p-checkbox .p-checkbox-box.p-highlight {
    background-color: var(--accent_color4);
    border: 1px solid var(--primary_color);
}

.mailingList .p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--primary_color);
    background: var(--primary_color);
}

.mailingList .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--primary_color);
    background: var(--primary_color);
}

.mailingList .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: var(--primary_color);
}

.mailingList .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: none;
    border-color: var(--primary_color);
}

@media (max-width: 767.98px) {
    .mailingList .btn {
        font-size: 0.9em;
    }

    .mailingList .btnMain {
        display: flex;
        justify-content: center;
    }
}
