.relaredCard1Wrapper {
    position: relative;
    padding-bottom: 3em;
    padding-top: 3em;
}

.relaredCard1Wrapper .titleMain {
    position: relative;
    background: #fff;
    padding: 0;
    display: flex;
    align-items: center;
    padding-bottom: 1em;
}

.relaredCard1Wrapper .titleMain h3 {
    color: #00558c;
    font-size: 2em;
    font-style: normal;
    font-weight: 400;
    line-height: 35.2px;
}

.relaredCard1Wrapper .titleMain .line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 140px;
    height: 2px;
    background-color: #00558c;
}

.relaredCard1Wrapper .card .card-body .tag-heading {
    color: #046f44;
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
    letter-spacing: 2.1px;
    text-transform: uppercase;
    margin-bottom: 0.6em;
}

.relaredCard1Wrapper .newsCard {
    position: relative;
    border-radius: 0.5em;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #e3e4e8;
}

.relaredCard1Wrapper .card {
    height: 100%;
}

.relaredCard1Wrapper .card .card-body {
    border: none !important;
}

.relaredCard1Wrapper .swiper-slide {
    height: auto;
    margin-right: 10px;
    margin-left: 0;
    margin-bottom: 20px;
    margin-top: 20px;
}

.relaredCard1Wrapper .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.5;
    display: flex;
}

.relaredCard1Wrapper .newsCard .cardImg img {
    object-fit: cover;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.relaredCard1Wrapper .newsCard .cardImg .tag {
    z-index: 2;
    position: absolute;
    bottom: 0.9375em;
    right: 0.9375em;
    padding: 0.375em 0.5625em;
    border: 1px solid #f2f2f3;
    border-radius: 4px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.8) 0, rgba(255, 255, 255, 0.55) 100%);
    box-shadow: 0 20px 40px 0 rgba(57, 124, 191, 0.1);
    backdrop-filter: blur(20px);
    color: #1d1d1d;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.75em;
    text-transform: uppercase;
}

.relaredCard1Wrapper .newsCard .card-body {
    position: relative;
    padding: 1.25em;
    margin: 0;
}

.relaredCard1Wrapper .newsCard .card-body h3 {
    color: #171821;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1em;
}

.relaredCard1Wrapper .button {
    color: var(--Grey-3, var(--Grey-3, #828282));
    text-align: center;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    border: 1px solid var(--Grey-3, #828282);
    border-radius: 0.625em;
    background: var(--White, #fff);
    display: inline-flex;
    padding: 0.375em 1.5em;
    align-items: center;
    gap: 10px;
}

.relaredCard1Wrapper .newsCard .card-body .date {
    color: #83858f;
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
}

.relaredCard1Wrapper .newsCard .card-body .author {
    color: #046f44;
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.relaredCard1Wrapper .newsCard .card-body .textMain {
    position: relative;
    margin: 0.5em 0;
    padding: 0;
    font-size: 0.875em;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    height: 4em;
    margin-bottom: 20px;
    text-align: start;
}

.card-padding {
    padding: 0 27px 0 27px !important;
}

.relaredCard1Wrapper .swiper-button-prev,
.swiper-button-next {
    margin-top: 0;
}

.relaredCard1Wrapper .swiper-button-next {
    margin-top: 0;
}

.relaredCard1Wrapper .swiper {
    padding-top: 50px !important;
    margin-top: -50px;
}

.relaredCard1Wrapper .swiper-button-prev {
    top: 4% !important;
}

.relaredCard1Wrapper .swiper-button-next {
    top: 4% !important;
}

.relaredCard1Wrapper .swiper-pagination-bullet-active {
    width: 43px !important;
    height: 7px !important;
    border-radius: 10px !important;
    background: var(--primary_color) !important;
}

@media (max-width: 767.98px) {
    .relaredCard1Wrapper .swiper-slide {
        margin: 0;
    }

    .padding {
        padding: 15px;
    }

    .relaredCard1Wrapper .newsCard {
        margin: 0;
        padding: 0;
    }

    .relaredCard1Wrapper .newsCard .card-body .textMain {
        height: auto;
    }

    .card-padding {
        padding: 0 0.75em !important;
    }

    .eventWrapper .swiper {
        padding-top: 40px !important;
    }

    .relaredCard1Wrapper .sub-title {
        line-height: 25.2px;
    }

    .relaredCard1Wrapper .titleMain h3 {
        font-size: 1.375em;
    }

    .relaredCard1Wrapper .sub-title {
        font-size: 0.75em;
        line-height: normal;
    }

    .relaredCard1Wrapper .newsCard {
        display: flex;
        flex-direction: row;
    }

    .relaredCard1Wrapper .newsCard .cardImg {
        width: 8em;
        flex-shrink: 0 !important;
        height: 100%;
    }

    .relaredCard1Wrapper .newsCard .cardImg img {
        border-radius: 0.325em 0 0 0.325em;
        height: 100%;
    }

    .relaredCard1Wrapper .newsCard .card-body {
        padding: 0.375em 0.9375em;
    }

    .relaredCard1Wrapper .titleMain {
        margin-bottom: 24px;
    }

    .relaredCard1Wrapper .card .card-body .tag-heading {
        line-height: normal;
        font-size: 0.5em;
        margin-bottom: 0;
    }

    .relaredCard1Wrapper .newsCard .card-body h3 {
        font-size: 0.7em;
        margin-bottom: 0;
        margin-top: 5px;
    }

    .relaredCard1Wrapper .newsCard .card-body .author,
    .relaredCard1Wrapper .newsCard .card-body .date {
        font-size: 0.6em;
    }

    .relaredCard1Wrapper .swiper-pagination-fraction,
    .relaredCard1Wrapper .swiper-pagination-custom,
    .relaredCard1Wrapper .swiper-horizontal > .swiper-pagination-bullets,
    .relaredCard1Wrapper .swiper-pagination-bullets.swiper-pagination-horizontal {
        display: block;
    }

    .relaredCard1Wrapper .swiper-button-prev {
        display: none;
    }

    .relaredCard1Wrapper .swiper-button-next {
        display: none;
    }
}

[dir='rtl'] .relaredCard1Wrapper .titleMain h3 {
    padding-left: 0;
}

[dir='rtl'] .relaredCard1Wrapper .titleMain h3::before {
    right: 0;
}

[dir='rtl'] .relaredCard1Wrapper .swiper-slide {
    margin-right: auto;
}

[dir='rtl'] .relaredCard1Wrapper .titleMain .line {
    right: 0;
    left: auto;
    margin-right: 0.50em;
}
