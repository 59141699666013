.breadcrumb {
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: 25.2px;
}

.breadcrumb a {
    color: #282828;
    transition: 0.3s;
}

.breadcrumb a:hover {
    color: var(--primary_color);
}

.breadcrumb .icon-fa-fa {
    margin: 2px 14px;
    color: var(--primary_color);
}

.breadcrumb .breadcrumb-item {
    color: var(--Grey-500, #808080);
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.breadcrumb .margin-top {
    margin-top: 3px;
}

.breadcrumb .active {
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: 25.2px;
    color: #046f44;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: none;
}

[dir='rtl'] .pi-angle-right::before {
    content: '\e931';
}