.loginModal .modal-header {
    border-bottom: none;
}

.loginModal .modal-header .btn-close {
    position: absolute;
    right: 10px;
    top: 10px;
}

.loginModal .modal-header img {
    object-fit: contain;
    width: 100%;
    height: 150px;
}

.loginModal h3 {
    font-size: 1em;
    font-weight: 600;
    text-align: center;
    color: #4f887a;
    margin-top: 1em;
}

.loginModal .icon {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #ced4da;
}

.loginModal .modal-content {
    height: 350px;
    padding: 1em;
}

.loginModal .button {
    padding: 5px 10px 5px 10px;
    display: block;
    margin: 0 auto;
    background-color: #56ab91;
    border: 1px solid #56ab91;
    color: #fff;
    border-radius: 10px;
}
