.mediaHeader {
    padding: 1.25em 0;
    margin: 0;
    background: #fff;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
    z-index: 999 !important;
}

.mediaHeader .logoMain {
    position: relative;
    padding: 0;
    margin-right: 4.375em;
}

.mediaHeader .mainHeader {
    width: auto;
    display: flex;
    text-align: center;
}

.mediaHeader .logoMain img {
    width: 100%;
    height: 60px;
}

.mediaHeader .mainHeader .headerLink {
    text-align: end;
    font-family: Poppins;
    color: #000000;
    font-size: 23px;
    font-weight: 400;
}

.mediaHeader .navbar-toggler {
    border: none;
    padding: 0;
}

.mediaHeader .navbar-toggler .navbar-toggler-icon {
    color: #796f6f !important;
}

.mediaHeader .userMain {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.mediaHeader .userMain img {
    width: 2.5em;
    height: 2.5em;
    border-radius: 5em;
}

.mediaHeader .userMain .contPopup {
    position: absolute;
    top: 2.5em;
    right: 0;
    width: 13.75em;
    height: auto;
    background: #fff;
    padding: 0;
    z-index: 10;
    border-radius: 0.5em;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.05);
}

.mediaHeader .userMain .contPopup .titleBox {
    position: relative;
    padding: 0.9375em 0.9375em;
}

.mediaHeader .userMain .contPopup h3 {
    font-size: 1em;
}

.mediaHeader .userMain .contPopup .line {
    background: #e3e4e8;
    height: 1px;
    text-align: center;
    margin: 0.9375em 0;
}

.mediaHeader .userMain .contPopup .line span {
    background: #fff;
    padding: 0 0.9375em;
    position: relative;
    top: -12px;
    color: #c9c9c9;
    font-size: 0.875em;
}

.mediaHeader .userMain .contPopup .contMain {
    position: relative;
    padding: 0;
    width: 100%;
    overflow: auto;
    border-top: 1px solid #e3e4e8;
}

.mediaHeader .userMain .contPopup .contMain ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}

.mediaHeader .userMain .contPopup .contMain ul li {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    line-height: normal;
    border-bottom: 1px solid #e3e4e8;
}

.mediaHeader .userMain .contPopup .contMain ul li {
    padding: 0.5em 0.9375em;
    position: relative;
    display: block;
    font-size: 0.875em;
}

.mediaHeader .userMain .contPopup .contMain ul li:hover {
    background: #e3e3e3;
}

.mediaSubHeader {
    position: sticky;
    margin: 0;
    padding: 15px 0;
    background: #f8f8f8;
    z-index: 99;
    display: flex;
    justify-content: center;
    width: 100%;
}

.mediaSubHeader .navbar-nav .nav-item {
    font-size: 0.925em;
    color: #000000 !important;
    padding: 0 15px 0 15px;
    margin: 0;
    text-transform: uppercase;
    border-right: 1px solid #000;
    letter-spacing: 2.7px;
    font-family: Poppins, sans-serif;
    font-weight: 400;
}

.mediaSubHeader .navbar-nav .nav-item:last-child {
    border-right: none;
    padding-right: 0;
}

.mediaSubHeader .navbar-nav .nav-item:hover {
    color: #3f1a1a !important;
}

@media (max-width: 767.98px) {
    .mediaHeader .mainHeader .headerLink {
        display: none;
    }

    .mediaHeader .userMain {
        position: absolute;
        right: 45px;
        top: 6px;
    }

    .mediaHeader .userMain img {
        width: 2em;
        height: 2em;
    }

    .mediaHeader .logoMain img {
        height: 25px;
    }

    .mediaSubHeader {
        display: none;
    }

    .mediaSubHeader .navbar-collapse {
        margin-top: 0.9375em;
    }

    .mediaSubHeader .navbar-collapse .navbar-nav .nav-item .nav-link {
        padding: 0.625em 1.25em;
        margin: 0;
        font-size: 1.25em;
    }

    .mediaSubHeader .navbar-collapse .navbar-nav .nav-item .nav-link.menuArrow::after {
        top: 15px;
        right: 0;
    }

    .mediaSubHeader .navbar-collapse .navbar-nav .nav-item .nav-link.menuArrowMobile::after {
        top: 15px;
        right: 0;
    }

    .mediaHeader .logoMain {
        margin-right: 0;
    }
}