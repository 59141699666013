.iconTextLinksWrapper {
    position: relative;
    padding: 1em 0em;
}

.iconTextLinksWrapper .p-tabview .p-tabview-panels,
.iconTextLinksWrapper .p-tabview .p-tabview-panels .col-12 {
    padding: 0;
}

.iconTextLinksWrapper .titleMain {
    position: relative;
    background: none;
    padding: 0.3125em 1.25em 0.3125em 0;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.iconTextLinksWrapper .titleMain h3 {
    margin: 0 0 0 0.8em;
    padding: 0;
    line-height: 38px;
    font-weight: 400;
    font-size: 28px;
}

.iconTextLinksWrapper .titleMain .icon-line {
    content: '';
    position: absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #046f44;
}

.iconTextLinksWrapper .sub-title {
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: 35.2px;
    margin-left: 0;
    font-size: 1rem;
}

.iconTextLinksWrapper .line {
    width: 100%;
    height: 1px;
    background: rgba(224, 224, 224, 1);
    margin: 0.625em 0em 0.9375em 0em;
}

.iconTextLinksWrapper .nav-tabs .nav-item {
    margin-bottom: 0;
}

.iconTextLinksWrapper .nav-tabs {
    border-bottom: none;
    padding: 0 0.9375em;
    font-size: 1em;
}

.iconTextLinksWrapper .nav-tabs .nav-link {
    color: #4f4f4f;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 1em;
}

.iconTextLinksWrapper .nav-tabs .nav-link:first-child {
    padding: 1em 1em 1em 0;
}

.iconTextLinksWrapper .nav-tabs .nav-link:focus,
.iconTextLinksWrapper .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.iconTextLinksWrapper .nav-tabs .nav-item.show .nav-link,
.iconTextLinksWrapper .nav-tabs .nav-link.active {
    position: relative;
    border-color: transparent;
    background: 0 0;
}

.iconTextLinksWrapper .nav-tabs .nav-link.active::after {
    content: '';
    position: absolute;
    height: 3px;
    background-color: #046f44;
    bottom: -1px;
    left: 0;
    width: 100%;
}

.iconTextLinksWrapper .links {
    padding: 1em 0;
}

.iconTextLinksWrapper ul.linkListHldr {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    /*display: grid;
   grid-template-columns: repeat(auto-fit, calc(100% / 8)); */
    font-size: 0.9rem;
    justify-content: flex-start;
}

.iconTextLinksWrapper ul.linkListHldr li {
    list-style: none;
    margin: 0;
    padding: 2em 15px 0 15px;
    text-align: center;
    width: 10rem;
}

.iconTextLinksWrapper ul.linkListHldr li i {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 68px;
    height: 68px;
    border: 1px solid var(--accent_color4);
    margin: 0 auto;
    margin-bottom: 1.25em;
    border-radius: 100%;
}

.iconTextLinksWrapper ul.linkListHldr li i:hover {
    background: var(--primary_color) !important;
    border: 1px solid var(--background_color) !important;
    color: white;
}

.iconTextLinksWrapper ul.linkListHldr li i img {
    width: 2em;
    height: auto;
    transition: filter 0.3s ease;
}

.iconTextLinksWrapper ul.linkListHldr li i img {
    width: 2em;
    height: auto;
}

.iconTextLinksWrapper ul.linkListHldr li i:hover img {
    filter: brightness(0) invert(1);
}

.iconTextLinksWrapper ul.linkListHldr li p {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--accent_color1) !important;
    text-align: center;
}

.iconTextLinksWrapper .p-dropdown {
    width: 100%;
    margin-top: 1em;
}

.iconTextLinksWrapper .p-dropdown {
    border: 1px solid #046f44;
}

.iconTextLinksWrapper .p-inputtext {
    font-family: Montserrat;
}

.iconTextLinksWrapper .p-dropdown:not(.p-disabled).p-focus {
    border-color: #046f44;
    box-shadow: none;
}

.iconTextLinksWrapper .p-dropdown:not(.p-disabled):hover {
    border-color: #046f44;
}

.iconTextLinksWrapper .text {
    color: #046f44;
    text-align: center;
    font-weight: 600;
    margin-top: 1em;
}

.iconTextLinksWrapper .bi-arrow-right::before {
    font-size: 20px;
}

.iconTextLinksWrapper .p-dropdown .p-dropdown-trigger {
    color: var(--accent_color3);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: var(--accent_color2) !important;
    color: var(--accent_color1) !important;
}

.iconTextLinksWrapper .p-tabview-title {
    line-height: 2;
}

@media (max-width: 768px) {
    .iconTextLinksWrapper ul.linkListHldr {
        display: block;
    }

    .iconTextLinksWrapper ul.linkListHldr li {
        position: relative;
        text-align: left;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(239, 239, 239, 1);
        padding: 0px;
        width: 100%;
    }

    .iconTextLinksWrapper ul.linkListHldr li::after {
        position: absolute;
        top: 22%;
        right: 0;
        content: '\e932';
        font-family: 'PrimeIcons';
        font-size: 1.5em;
    }

    .iconTextLinksWrapper ul.linkListHldr li div {
        display: flex;
        align-items: center;
    }

    .iconTextLinksWrapper ul.linkListHldr li a {
        display: flex;
        align-items: center;
    }

    .iconTextLinksWrapper ul.linkListHldr li i {
        margin: 0px;
        border: 1px solid var(--accent_color4);
    }

    .iconTextLinksWrapper .sub-title {
        font-size: 1em;
        line-height: normal;
    }

    .iconTextLinksWrapper .p-dropdown-label {
        font-size: 1em;
        text-align: start;
    }

    [dir='rtl'] .iconTextLinksWrapper ul.linkListHldr li::after {
        left: 0;
        right: auto;
        content: '\e931';
    }
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav {
    border: none !important;
}

.iconTextLinksWrapper .p-tabview-title {
    font-size: 0.75rem;
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: #046f44;
    color: var(--accent_color3);
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: #046f44;
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav-btn.p-link {
    box-shadow: none;
}

.iconTextLinksWrapper .iconTextLinksWrapper .p-tabview .p-tabview-nav-btn.p-link {
    box-shadow: none;
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav-btn.p-link {
    color: var(--primary_color) !important;
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav-btn.p-link:focus {
    box-shadow: none;
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: var(--accent_color3) !important;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    padding-left: 0;
    padding-right: 0;
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--primary_color) !important;
    bottom: 0;
    left: 0;
    right: 0;
}

.iconTextLinksWrapper .right-fade {
    position: absolute;
    z-index: 23;
    right: 55px;
    top: 13px;
    width: 118px;
    height: 30px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 7.41%, #ffffff 100%);
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    position: relative;
    padding: 0;
    padding-bottom: 1em;
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2px;
    height: 2px;
    width: 100%;
    background: var(--primary_color) !important;
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    display: none !important;
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: none !important;
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0;
    padding-bottom: 1em;
}

.iconTextLinksWrapper .p-tabview .p-tabview-nav li {
    margin: 0 1.5em 0 0em !important;
}

.iconTextLinksWrapper .p-tabview-nav-btn {
    top: 0;
    background: white !important;
    height: 38px;
    padding-bottom: 20px;
}

[dir='rtl'] .iconTextLinksWrapper .titleMain .icon-line {
    right: 0;
}

.iconTextLinksWrapper .tabs-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

.iconTextLinksWrapper .tabs-wrapper {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    white-space: nowrap;
    flex-grow: 1;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.iconTextLinksWrapper .tabs-wrapper::-webkit-scrollbar {
    display: none;
}

.iconTextLinksWrapper .tab {
    padding: 0;
    margin: 0 1.5em 0 0;
    color: #83858f;
    cursor: pointer;
    white-space: nowrap;
    flex: 0 0 auto;
    font-size: 0.75em;
    font-weight: 400;
    padding-bottom: 0.5em;
}

.iconTextLinksWrapper .active {
    border-bottom: 2px solid var(--primary_color);
}

.iconTextLinksWrapper .left {
    left: 0;
}

.iconTextLinksWrapper .right {
    right: 0;
}

.iconTextLinksWrapper .pi-angle-right:before,
.iconTextLinksWrapper .pi-angle-left:before {
    color: var(--primary_color);
}

.iconTextLinksWrapper .arrowMain {
    position: relative;
    width: 30px;
    background: #fff;
    top: -3px;
}

.iconTextLinksWrapper .arrowLeft {
    text-align: left;
}

.iconTextLinksWrapper .arrowRight {
    text-align: right;
}

[dir='rtl'] .iconTextLinksWrapper .tab {
    margin: 0 0 0 1.5em;
}

[dir='rtl'] .iconTextLinksWrapper .pi-arrow-right:before {
    content: '\e91a';
}