.feature-right {
    position: relative;
}

.feature-right .feature-img-Hldr {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 40em;
}

.feature-right .feature-img-Hldr img {
    width: 100%;
    height: 100%;
}

.feature-right .feature-txt-main {
    position: relative;
    margin: 0;
    padding: 1.875em 0;
}

.feature-right .feature-txt-main h2 {
    font-size: 2.5em;
    color: var(--primary_color);
    font-weight: 400;
    line-height: 48.76px;
}

.feature-right .feature-txt-main .feature-bodyTxt {
    font-size: 1.125em;
    font-weight: 400;
    margin: 1.625em 0;
    color: var(--accent_color1);
}

.feature-right .feature-txt-main .feature-bodyTxt p {
    line-height: 28px;
}

.feature-right .feature-txt-main span {
    position: relative;
    color: var(--primary_color);
    font-size: 1.125em;
    font-weight: 400;
}

.feature-right .feature-txt-main span i {
    margin-left: 0.375em;
}

@media (max-width: 767.98px) {
    .feature-right .feature-img-Hldr {
        height: auto;
    }

    .feature-right .feature-txt-main {
        padding: 1.875em 1em;
    }

    .feature-right .feature-txt-main h2 {
        font-size: 1.5em !important;
    }

    .feature-right .feature-txt-main p,
    .feature-right .feature-txt-main span {
        font-size: 1em !important;
    }
}

[dir='rtl'] .feature-right .pi-angle-right:before {
    content: '\e931';
}

[dir='rtl'] .feature-right .feature-txt-main span i {
    margin-right: 0.375em;
    margin-left: 0;
}

[dir='rtl'] .feature-right .feature-txt-main span {
    width: 100%;
    display: flex;
}
