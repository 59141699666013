.heroSectionLeftCardWrapper {
    position: relative;
    padding-top: 2em;
}

.heroSectionLeftCardWrapper .swiper {
    padding-top: 60px !important;
    padding-bottom: 50px !important;
}

.heroSectionLeftCardWrapper .newsCardBig {
    position: relative;
}

.heroSectionLeftCardWrapper .featuredCard {
    margin-top: 5em;
}

.heroSectionLeftCardWrapper .newsCardBig .card-body {
    position: relative;
}

.heroSectionLeftCardWrapper .newsCardBig .card-body .heading {
    position: relative;
    padding: 0;
    font-size: 2.5em;
    color: var(--primary_color);
    font-weight: 400;
    font-style: normal;
    line-height: 48.76px;
}

.heroSectionLeftCardWrapper .main-heading {
    color: var(--primary_color);
    font-size: 1.4em;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    position: absolute;
}

.heroSectionLeftCardWrapper .newsCardBig .card-body .textMain {
    position: relative;
    padding: 0;
    font-size: 2em;
    color: var(--accent_color1);
    line-height: normal;
    font-weight: 400;
}

.heroSectionLeftCardWrapper .newsCardBig .card-body .summaryText p {
    position: relative;
    padding: 0;
    font-size: 1.125em;
    color: var(--accent_color1);
    margin-top: 1.5em;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 28px;
}

.heroSectionLeftCardWrapper .newsCardBig .NewsImg {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    border-radius: 15px;
    box-shadow: 5px 15px 20px 0px rgba(0, 0, 0, 0.15);
}

.heroSectionLeftCardWrapper .newsCardBig .NewsImg img {
    width: 100%;
    border-radius: 15px;
    height: 100%;
}

.heroSectionLeftCardWrapper .button {
    background-color: var(--background_color);
    color: var(--primary_color);
    border: 1px solid var(--primary_color);
    font-weight: 600;
    padding: 1em 1.5em;
    margin-top: 2em;
    border-radius: 10px;
}

.heroSectionLeftCardWrapper .arrow-left::after {
    top: calc(50% - 24px);
    border: solid transparent;
    content: ' ';
    position: absolute;
    pointer-events: none;
    border-width: 30px;
    z-index: 99;
    left: -54px;
    border-right-color: var(--primary_color);
}

.heroSectionLeftCardWrapper .swiper-button-prev {
    top: 6% !important;
}

.heroSectionLeftCardWrapper .swiper-button-next {
    top: 6% !important;
}

.heroSectionLeftCardWrapper .newsCardBig .card-order {
    padding-right: 25px;
}

@media (max-width: 767.98px) {
    .heroSectionLeftCardWrapper .card-order {
        order: 1;
        margin-top: 28px !important;
        text-align: start;
        line-height: 36.57px !important;
    }

    .heroSectionLeftCardWrapper .newsCardBig .card-body .summaryText {
        line-height: 28px !important;
    }

    .heroSectionLeftCardWrapper .button {
        padding: 1em;
    }

    .heroSectionLeftCardWrapper .newsCardBig .NewsImg {
        width: 100%;
    }

    .heroSectionLeftCardWrapper .swiper {
        padding-top: 60px !important;
        padding-bottom: 50px !important;
    }

    .heroSectionLeftCardWrapper .newsCardBig .card-body .textMain {
        margin-top: 1em;
    }

    .heroSectionLeftCardWrapper .newsCardBig .card-order {
        padding-right: 16px;
    }

    .heroSectionLeftCardWrapper .newsCardBig .card-body .heading {
        font-size: 2em;
    }

    .heroSectionLeftCardWrapper .newsCardBig .card-body .summaryText p {
        display: block;
        overflow: visible;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
    }
}

[dir='rtl'] .heroSectionLeftCardWrapper .newsCardBig .card-order {
    padding-left: 25px !important;
    padding-right: 0.75em !important;
}

[dir='rtl'] .heroSectionLeftCardWrapper .button {
    margin-right: 0 !important;
}
