.FeatureCarouselWrapper {
    position: relative;
}

.FeatureCarouselWrapper .featureImgHldr {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    aspect-ratio: 1.5;
}

.FeatureCarouselWrapper .featureImgHldr img {
    object-fit: cover;
    border-radius: 10px;
}

.FeatureCarouselWrapper .txtBoxHldr {
    position: absolute;
    bottom: 0;
    left: 84px;
    background: #fff;
    padding: 25px;
    width: 440px;
    border-radius: 10px 10px 0px 0px;
}

.FeatureCarouselWrapper .txtBoxHldr h2 {
    font-size: 24px;
    font-weight: 500;
    color: var(--primary_color);
}

.FeatureCarouselWrapper .txtBoxHldr p {
    margin: 13px 0;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: 28px;
    color: var(--accent_color1);
}

.FeatureCarouselWrapper .txtBoxHldr .readingLink {
    font-size: 14px;
    font-weight: 600;
    padding: 8px 16px;
    background: var(--primary_color);
    color: var(--accent_color4);
    border: none;
    border-radius: 4px;
}

.FeatureCarouselWrapper .swiper {
    padding-bottom: 20px !important;
}

.FeatureCarouselWrapper .swiper-button-prev,
.FeatureCarouselWrapper .swiper-button-next {
    background: var(--primary_color);
    height: 60px !important;
    width: 60px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.FeatureCarouselWrapper .swiper-button-prev {
    bottom: 31px;
    top: auto !important;
    left: 524px !important;
}

.FeatureCarouselWrapper .swiper-button-prev::before {
    content: '\e931';
}

.FeatureCarouselWrapper .swiper-button-next {
    left: 584px;
    bottom: 31px;
    top: auto !important;
}

.FeatureCarouselWrapper .swiper-button-prev::before,
.FeatureCarouselWrapper .swiper-button-next::before {
    font-family: 'PrimeIcons';
    color: var(--accent_color4);
    font-size: 23px;
}

.FeatureCarouselWrapper .swiper-button-next::before {
    content: '\e932';
}

.FeatureCarouselWrapper .pi-angle-right:before {
    position: relative;
    top: 2px;
}

[dir='rtl'] .FeatureCarouselWrapper .swiper-rtl .swiper-button-next {
    left: 584px;
}

@media (max-width:  767.98px) {
    .FeatureCarouselWrapper .featureImgHldr {
        height: 570px;
    }

    .FeatureCarouselWrapper .featureImgHldr img {
        height: 280px;
    }

    .FeatureCarouselWrapper .txtBoxHldr {
        position: relative;
        left: 0;
        width: 100%;
    }

    .FeatureCarouselWrapper .txtBoxHldr h2 {
        font-size: 25px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .FeatureCarouselWrapper .txtBoxHldr p {
        font-size: 16px;
    }

    .FeatureCarouselWrapper .swiper-button-prev {
        display: block;
    }

    .FeatureCarouselWrapper .swiper-pagination {
        display: none;
    }

    .FeatureCarouselWrapper .swiper-button-next,
    .FeatureCarouselWrapper .swiper-button-prev {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .FeatureCarouselWrapper .swiper-button-prev {
        bottom: 0;
        top: 258px !important;
        left: auto !important;
        right: 44px !important;
    }

    .FeatureCarouselWrapper .swiper-button-next {
        left: auto;
        bottom: 0;
        top: 258px !important;
        right: 0;
    }

    .FeatureCarouselWrapper .swiper-button-prev,
    .FeatureCarouselWrapper .swiper-button-next {
        background: var(--primary_color);
        height: 44px !important;
        width: 44px !important;
    }
}
