.txtLinkWrapper {
    position: relative;
    padding-top: 3em;
}

.txtLinkWrapper h2 {
    position: relative;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 22px;
    color: var(--accent_color1);
}

.txtLinkWrapper .line {
    position: relative;
    width: 116px;
    height: 2px;
    display: block;
    margin-top: 15px;
    background: var(--primary_color);
}

.txtLinkWrapper .txtLinkHldr {
    position: relative;
    margin: 0 0 20px 0;
    padding: 0 0 12px 0;
    border-bottom: 1px solid var(--accent_color2);
}

.txtLinkWrapper .txtLinkHldr h3 {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: var(--primary_color);
}

.txtLinkWrapper .txtLinkHldr h2 {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px;
}

.txtLinkWrapper .txtLinkHldr .minut {
    position: relative;
    font-weight: 400;
    font-size: 12px;
    color: var(--accent_color1);
}

.txtLinkWrapper .txtLinkHldr .minut span {
    color: var(--accent_color3);
}
