.articleMainSecation {
    position: relative;
    padding: 2.125em 0;
    margin: 0;
}

.articleMainSecation .container {
    max-width: 715px;
}

.articleMainSecation h1 {
    font-size: 1.75em;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #1d1d1d;
}

.articleMainSecation .iconHldr {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
}

.articleMainSecation .iconHldr-mobile {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1em 2em;
    background-color: rgba(8, 76, 97, 0.05);
}

.articleMainSecation .icon-btn {
    position: relative;
    margin: 0 0.1875em;
    padding: 0em;
    display: inline-block;
    text-align: center;
    background: #f8f8f8;
    border-radius: 0.375em;
    cursor: pointer;
    color: #4f4f4f;
    font-size: 0.75em;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
}

.articleMainSecation .dropdown-item {
    font-size: 0.625em;
}

.articleMainSecation .icon-btn .fa-share-alt:before {
    opacity: 0.7;
}

.articleMainSecation .iconHldr .dateMain {
    display: flex;
    align-items: center;
    margin-right: auto;
}

.articleMainSecation .tagMain {
    color: #046f44;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.articleMainSecation .iconHldr .dateMain .dateTitleMain {
    position: relative;
    border-left: 2px solid var(--accent_color1);
    padding-left: 0.625em;
}

.articleMainSecation .iconHldr .dateMain .dateTitleMain h4 {
    font-size: 0.9375em;
    font-weight: 600;
}

.articleMainSecation .iconHldr .dateMain .dateTitleMain .dateTime {
    position: relative;
    display: flex;
    align-items: center;
}

.articleMainSecation .iconHldr .dateMain .dateTitleMain .dateTime span {
    font-size: 0.875em;
    color: #666;
    font-weight: 400;
}

.articleMainSecation .iconHldr ul {
    position: relative;
    margin: 0;
    padding: 0;
    gap: 4px;
}

.articleMainSecation .iconHldr ul li {
    list-style: none;
    margin: 0 0.1875em;
    font-size: 1em;
    line-height: normal;
    display: inline-block;
    opacity: 0.7;
}

.articleMainSecation .iconHldr-mobile ul {
    position: relative;
}

.articleMainSecation .iconHldr-mobile ul li {
    list-style: none;
    font-size: 1.625em;
    line-height: normal;
    display: inline-block;
    opacity: 0.7;
}

.articleMainSecation .language-dropdown {
    border: none;
    background: none;
    color: #7d787b;
    margin: 0.5em 0 0em 0em;
    padding: 1em !important;
    border-bottom: 1px solid !important;
    border-radius: 0 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.14px;
    text-transform: capitalize;
}

.articleMainSecation .language-dropdown-mobile {
    border: none;
    background: none;
    color: #7d787b;
    margin: 0.5em 0 0em 0em;
    padding: 1em !important;
    border-bottom: 1px solid !important;
    border-radius: 0 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.14px;
    text-transform: capitalize;
}

.articleMainSecation .iconHldr ul li:last-child {
    margin-right: 0;
}

.articleMainSecation .iconHldr ul li a {
    color: #1d1d1d;
}

.articleMainSecation .iconHldr ul li a:hover {
    color: #4f887a;
}

.articleMainSecation .iconHldr-mobile ul li:last-child {
    margin-right: 0;
}

.articleMainSecation .iconHldr-mobile ul li a {
    color: #1d1d1d;
}

.articleMainSecation .iconHldr-mobile ul li a:hover {
    color: #4f887a;
}

.articleMainSecation .articleImageMain {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 0;
    height: auto;
}

.articleMainSecation .articleImageMain img {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
}

.articleMainSecation .articleImageMain .imgtext {
    color: #333333;
    padding: 0.625em;
    font-weight: 400;
    font-size: 0.775rem;
}

.titleSecation {
    position: relative;
    margin: 0;
    font-size: 1.5em;
    font-weight: 700;
}

.titleSecation .summary p {
    color: var(--primary_color);
    font-weight: 400;
    position: relative;
    font-size: 20px;
    padding: 1em 0em 1em 0;
    margin-bottom: 25px;
    letter-spacing: 0px;
    line-height: 28px;
    border-left: none;
}

.KeyTakeawaysSecation {
    position: relative;
    padding: 2.5em 0;
    margin: 0;
    background: rgba(226, 226, 226, 0.37);
}

.KeyTakeawaysSecation h3 {
    position: relative;
    font-size: 1.5em;
    margin: 0 0 0.9375em 0;
    padding: 0 1.875em 0.5em 0;
    font-weight: 700;
    color: #046f44;
    display: inline-block;
}

.KeyTakeawaysSecation h3::after {
    content: '';
    height: 1px;
    background: #56ab91;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.KeyTakeawaysSecation p {
    margin-bottom: 1.5625em;
    font-size: 1.125em;
}

.KeyTakeawaysSecation p b {
    font-weight: 800;
    margin-bottom: 0.5em;
    display: block;
}

.KeyTakeawaysSecation p:last-child {
    margin-bottom: 0;
}

.KeyTakeawaysSecation .container {
    max-width: 1320px;
}

.articleTextSecation {
    position: relative;
    margin: 0;
    padding: 0em 0 2.5em 0;
    line-height: 28px;
}

.articleTextSecation p {
    margin-bottom: 25px;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
}

.articleTextSecation ul li {
    list-style: disc;
    margin-bottom: 25px;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 28px;
    color: #000;
}

.articleMainSecation .icon-btn .ShareSocial {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 0.25em;
    background: #fff;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    z-index: 10;
}

.articleMainSecation .icon-btn .ShareSocial span {
    margin: 0 1em;
    font-size: 1.25em;
    display: inline-block;
    display: flex;
    align-items: center;
}

.articleMainSecation .icon-btn .ShareSocial span:hover {
    color: #046f44;
}

.photoGallerySecation {
    position: relative;
    margin: 0 auto;
    padding: 2.5em 0;
}

.photoGallerySecation h3 {
    font-size: 2.5em;
    margin: 0 0 0.625em 0;
    padding: 0;
    font-weight: 700;
    color: #1d1d1d;
    text-align: center;
}

.gallery-container {
    background-color: #fff;
    color: #35373a;
    padding: 0 0;
}

.tz-gallery {
    padding: 0;
}

.row > div {
    position: relative;
    padding: 0;
}

.image {
    width: 100%;
    height: 100% !important;
}

.lightbox {
    width: 100% !important;
    border-radius: 0;
    position: relative;
}

.lightbox .bigImg {
    width: 100% !important;
    border-radius: 0;
    position: relative;
}

.p-image-preview-container > img {
    height: 100%;
}

.photoGallerySecation .container {
    max-width: 1320px;
}

.TagsSecation {
    position: relative;
}

.TagsSecation h1 {
    font-size: 1.5em;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.625em;
}

.TagsSecation P {
    font-size: 1.125em;
}

.TagsSecation .newsTagWrapper {
    position: relative;
    margin: 0;
    padding: 0;
}

.TagsSecation .newsTagWrapper ul {
    list-style: none;
    margin: 0 0;
    display: flex;
    align-items: center;
}

.TagsSecation .newsTagWrapper ul li {
    position: relative;
    margin: 0.3125em 0.625em 0.3125em 0;
    padding: 0.375em 0.5em;
    display: inline-block;
    text-align: center;
    background: #f8f8f8;
    border-radius: 0.25em;
    cursor: pointer;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
}

.TagsSecation .hash {
    color: #4f4f4f;
    text-align: center;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
}

.cardWrapper {
    position: relative;
    padding: 2em 0;
}

.cardWrapper {
    position: relative;
}

.cardWrapper .titleMain {
    position: relative;
    background: #fff;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    padding: 0.9375em 1.25em 0.9375em 0;
    display: flex;
    align-items: center;
}

.cardWrapper .titleMain h3 {
    color: #046f44;
    font-weight: 700;
    display: block;
    position: relative;
    padding-left: 0.8125em;
}

.cardWrapper h3 {
    font-weight: 700;
}

.cardWrapper .titleMain h3::before {
    content: '';
    position: absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    background: #046f44;
}

.cardWrapper .newsCardBig {
    position: relative;
    background: #fff;
    border-radius: 0.9375em;
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.03);
    border: 1px solid #e5eaf4;
}

.cardWrapper .newsCardBig .NewsImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 23.75em;
}

.cardWrapper .newsCardBig .NewsImg img {
    height: 23.75em;
    width: 100%;
    border-radius: 0.9375em 0 0 0.9375em;
    object-fit: cover;
}

.cardWrapper .newsCardBig .card-body {
    position: relative;
    padding: 1.875em 0;
    margin: 0 auto;
    width: 80%;
}

.cardWrapper .newsCardBig .card-body .iconMain {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 0.625em;
}

.cardWrapper .newsCardBig .card-body .iconMain .mins {
    font-size: 0.875em;
    font-weight: 300;
    margin-right: auto;
}

.cardWrapper .newsCardBig .card-body .iconMain ul {
    position: relative;
    margin: 0;
    padding: 0;
}

.cardWrapper .newsCardBig .card-body .iconMain ul li {
    list-style: none;
    margin-right: 0.1875em;
    font-size: 1.375em;
    line-height: normal;
    display: inline-block;
}

.cardWrapper .newsCardBig .card-body .iconMain ul li a {
    color: #1d1d1d;
}

.cardWrapper .newsCardBig .card-body .iconMain ul li a.active,
.cardWrapper .newsCardBig .card-body .iconMain ul li a:hover {
    color: #4f887a;
}

.cardWrapper .newsCardBig .card-body h3 {
    font-size: 1.5em;
    margin: 0 0 0.1875em 0;
    padding: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    height: 2.5em;
    font-weight: 700;
}

.cardWrapper .newsCardBig .card-body .date {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
    font-weight: 300;
}

.cardWrapper .newsCardBig .card-body .textMain {
    position: relative;
    margin: 1.5em 0;
    padding: 0;
    font-size: 0.875em;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    line-height: normal;
    height: 7.5em;
}

.cardWrapper .newsCardBig .card-body .hashtag {
    position: relative;
    display: inline-block;
    padding: 0.1875em 0.9375em;
    border-radius: 0.25em;
    background: #eef7f4;
    color: #046f44;
    font-size: 0.875em;
    font-weight: 700;
}

.cardWrapper .newsCard {
    position: relative;
    padding: 0.5em;
    border-radius: 0.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    margin: 1em;
}

.cardWrapper .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 12.5em;
}

.cardWrapper .newsCard .cardImg img {
    height: 12.5em;
    object-fit: cover;
    width: 100%;
    border-radius: 0.5em;
}

.cardWrapper .newsCard .cardImg .tag {
    z-index: 2;
    position: absolute;
    bottom: 0.9375em;
    right: 0.9375em;
    padding: 0.375em 0.5625em;
    border: 1px solid #f2f2f3;
    border-radius: 4px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.8) 0, rgba(255, 255, 255, 0.55) 100%);
    box-shadow: 0 20px 40px 0 rgba(57, 124, 191, 0.1);
    backdrop-filter: blur(20px);
    color: #1d1d1d;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.75em;
    text-transform: uppercase;
}

.cardWrapper .newsCard .card-body {
    position: relative;
    padding: 0.625em 0 0 0;
    margin: 0;
}

.cardWrapper .newsCard .card-body .iconMain {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 0.625em;
}

.cardWrapper .newsCard .card-body .iconMain .mins {
    font-size: 0.875em;
    font-weight: 300;
    margin-right: auto;
}

.cardWrapper .newsCard .card-body .iconMain ul {
    position: relative;
    margin: 0;
    padding: 0;
}

.cardWrapper .newsCard .card-body .iconMain ul li {
    list-style: none;
    margin-right: 0.1875em;
    font-size: 1.375em;
    line-height: normal;
    display: inline-block;
}

.cardWrapper .newsCard .card-body .iconMain ul li a {
    color: #1d1d1d;
}

.cardWrapper .newsCard .card-body .iconMain ul li a.active,
.cardWrapper .newsCard .card-body .iconMain ul li a:hover {
    color: #4f887a;
}

.cardWrapper .newsCard .card-body h3 {
    font-size: 1.5em;
    margin: 0 0 0.1875em 0;
    padding: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    height: 2.5em;
    font-weight: 600;
}

.cardWrapper .newsCard .card-body .date {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
    font-weight: 300;
}

.cardWrapper .newsCard .card-body .textMain {
    position: relative;
    margin: 0.5em 0;
    padding: 0;
    font-size: 0.875em;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    height: 4em;
    margin-bottom: 20px;
}

.cardWrapper .newsCard .card-body .hashtag {
    position: relative;
    display: inline-block;
    padding: 0.1875em 0.9375em;
    border-radius: 0.25em;
    background: #eef7f4;
    color: #046f44;
    font-size: 0.875em;
    font-weight: 700;
}

.cardWrapper .container {
    max-width: 1320px;
}

.card-padding {
    padding: 0 27px 0 27px !important;
}

.articleMainSecation .carouselSection .heading {
    font-weight: 600;
    color: #333333;
    font-size: 1.625em;
}

.articleMainSecation .card {
    border: none;
    border-radius: 0.5em;
}

.articleMainSecation .card .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: flex;
}

.articleMainSecation .card .cardImg img {
    width: 100%;
    object-fit: cover !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    animation: fadeIn2 1s;
    -webkit-animation: fadeIn2 1s;
    -moz-animation: fadeIn2 1s;
    -o-animation: fadeIn2 1s;
    -ms-animation: fadeIn2 1s;
}

.articleMainSecation .card .card-body {
    position: relative;
    padding: 0.9375em;
    border: 1px solid #e3e4e8;
    border-radius: 0 0 0.5em 0.5em;
}

.articleMainSecation .card .card-body .tagText {
    font-size: 0.625em;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
    letter-spacing: 2.1px;
    text-transform: uppercase;
    color: #046f44;
}

.articleMainSecation .card .card-body h3 {
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1.5em;
    line-height: normal;
    color: #333333;
}

.articleMainSecation .card .card-body .authorDateMain {
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.articleMainSecation .card .card-body .authorDateMain .author {
    color: #046f44;
}

.articleMainSecation.card .card-body .authorDateMain .date {
    color: #666;
}

.articleMainSecation .card .card-body .dateMain {
    z-index: 2;
    position: absolute;
    width: 4em;
    height: 4em;
    border-radius: 5px;
    background: #c9b198;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    top: -2.5em;
    right: 0.9375em;
    text-align: center;
    color: #fff;
    padding: 0.125em 0.9375em;
}

.articleMainSecation .card .card-body .dateMain h2 {
    font-size: 1.5em;
    font-weight: 700;
    line-height: normal;
}

.articleMainSecation .card .card-body .dateMain .month {
    font-size: 0.75em;
    font-weight: 600;
    line-height: normal;
}

.articleMainSecation .card {
    height: 100% !important;
}

@media (max-width: 767.98px) {
    .titleSecation .summary p {
        margin-bottom: 25px;
        font-size: 16px;
        padding: 1em 0em 1em 0;
        font-weight: 400;
    }

    .titleSecation {
        padding: 0;
    }

    .articleMainSecation .icon-btn {
        margin: 0;
    }

    .articleMainSecation .articleImageMain {
        margin: 0;
    }

    .articleMainSecation .language-dropdown {
        display: none;
    }

    .articleTextSecation {
        padding: 0;
    }

    .articleMainSecation .tagMain {
        font-size: 0.625em;
        font-weight: 500;
    }

    .articleMainSecation h1 {
        font-size: 1.25em;
        list-style: 24.38px;
    }

    .articleMainSecation .iconHldr ul {
        display: none;
    }

    .TagsSecation .newsTagWrapper ul {
        display: inline-block;
    }

    .articleTextSecation ul li {
        font-size: 1em;
    }

    .articleTextSecation p {
        font-size: 16px;
    }
}

[dir='rtl'] .articleMainSecation .iconHldr-mobile ul li:last-child {
    margin-right: 1.25rem;
}
