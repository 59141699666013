.conciliationArbritationSection {
    position: relative;
    padding-top: 50px;
    border-top: 1px solid var(--accent_color2);
    border-bottom: 1px solid var(--accent_color2);
}

.conciliationArbritationSection .HelpWrapper {
    position: relative;
    padding: 0;
}

.conciliationArbritationSection .HelpWrapper .TxtMain {
    position: relative;
}

.conciliationArbritationSection .HelpWrapper .TxtMain h2 {
    font-size: 3em;
    color: var(--primary_color);
    margin-bottom: 0.3125em;
    font-weight: 700;
}

.conciliationArbritationSection .HelpWrapper .TxtMain .helplineTxt {
    font-size: 1.125em;
    color: #1d1d1d;
    margin-bottom: 1.875em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.conciliationArbritationSection .HelpWrapper .TxtMain i {
    font-size: 2em;
    padding-left: 0;
}

.conciliationArbritationSection .HelpWrapper .TxtMain h3 {
    font-size: 2em;
    color: var(--primary_color);
    margin-bottom: 0.3125em;
    font-weight: 700;
}

.conciliationArbritationSection .HelpWrapper .btn-link {
    color: var(--primary_color);
    font-size: 0.95rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
}

@media (max-width: 767.98px) {
    .conciliationArbritationSection {
        position: relative;
        padding-top: 0.9375em;
        padding-bottom: 0.9375em;
    }

    .conciliationArbritationSection .HelpWrapper .mobileImg {
        display: none;
    }

    .conciliationArbritationSection .HelpWrapper .TxtMain h2 {
        text-align: center;
        font-size: 1.9rem;
    }

    .conciliationArbritationSection .HelpWrapper .TxtMain .helplineTxt {
        text-align: start;
        font-size: 0.8125em;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .conciliationArbritationSection .HelpWrapper .btn-link {
        font-size: 0.7rem;
    }

    .conciliationArbritationSection .HelpWrapper .TxtMain h3 {
        font-size: 1.625em;
    }
}

[dir='rtl'] .conciliationArbritationSection .HelpWrapper .btn-link {
    float: right;
}

[dir='rtl'] .conciliationArbritationSection .HelpWrapper .btn-link .btn-icon {
    padding-right: 0.675em !important;
}
