.funeralListingSection {
    position: relative;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
}

.funeralListingSection .heading h3 {
    color: #282828;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
}

.funeralListingSection .sub-heading h4 {
    color: #282828;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    text-transform: none;
}

.funeralListingSection .card {
    background: #fff;
}

.funeralListingSection .card .card-body {
    border: none !important;
}

.funeralListingSection .card .card-body h3 {
    height: auto;
}

.funeralListingSection .boxWrapper {
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2.5em rgba(4, 111, 68, 0.06);
    border-radius: 0.5em;
    padding: 1.5em;
    height: 36rem;
}

.funeralListingSection .card .card-body .boxWrapper h3 {
    color: #282828;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 1rem;
    padding: 0;
    height: auto;
}

.funeralListingSection .card .card-body .boxWrapper span {
    color: #282828;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 2rem;
    padding: 0;
}

.funeralListingSection .card .card-body .boxWrapper .sub-heading {
    color: #56ab91;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 2rem;
    padding: 0;
}

.funeralListingSection .capitalize-first-letter::first-letter {
    text-transform: capitalize;
}

.funeralListingSection .form-label {
    color: #808080;
}

.modal-header-border {
    border-bottom: none !important;
}

.modal-icon {
    margin: 0 auto;
    font-size: 2em !important;
    color: #bdbdbd;
    margin-bottom: 0.5em;
}

.funeralListingSection .button {
    border-radius: 6px;
    background-color: #046f44;
    border: 1px solid;
    color: white;
    width: 100%;
    display: block;
    font-weight: 600;
    padding: 0.625rem 3rem 0.625rem 3rem;
}

.funeralListingSection .cross-icon {
    font-size: 12px;
    padding-right: 10px;
}

.funeralListingSection .button .btn-text {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.funeralListingSection .btn {
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid;
    color: #56ab91;
    display: block;
    font-size: 17px;
    text-align: center;
    margin-left: 10px;
    padding: 5px;
}

.funeralListingSection .btn .btn-text1 {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.funeralListingSection .bodyText p {
    font-size: 1em;
    font-weight: 400;
    color: #666;
    margin-bottom: 0.625em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: normal;
    line-height: 175%;
}

.funeralListingSection .padding {
    padding-left: 0.5rem;
}

.funeralListingSection .form-check-input:checked {
    background-color: #56ab91;
    border-color: #56ab91;
}

.funeralListingSection .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: var(--accent_color4);
    border-color: var(--primary_color);
    background: var(--primary_color);
}

.funeralListingSection .nav-tabs .nav-item {
    margin-bottom: 0;
}

.funeralListingSection .nav-tabs {
    border-bottom: 1px solid rgba(67, 67, 67, 0.24);
    padding: 0 0.9375em;
    font-size: 1em;
}

.funeralListingSection .nav-tabs .nav-link {
    color: var(--accent_color3);
    font-size: 1em;
    font-weight: 700;
    padding: 0.625em 0 !important;
    line-height: normal;
    margin: 0 1em;
}

.funeralListingSection .bi-chevron-right::before {
    font-weight: 600 !important;
}

.funeralListingSection .nav-tabs .nav-link:focus,
.funeralListingSection .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.funeralListingSection .nav-tabs .nav-item.show .nav-link,
.funeralListingSection .nav-tabs .nav-link.active {
    position: relative;
    border-color: transparent;
    font-size: 1em;
    color: var(--primary_color);
    background: 0 0;
}

.nav-tabs .nav-link.active::after {
    content: '';
    position: absolute;
    height: 2px;
    background-color: var(--primary_color);
    bottom: -1px;
    left: 0;
    width: 100%;
}

.funeralListingSection i {
    font-size: 1em;
    color: var(--accent_color3);
}

.funeralListingSection .fa-angle-right:before {
    padding-left: 10px;
}

.funeralListingSection .btn-link1 {
    color: var(--primary_color);
    font-size: 0.95rem;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
}

.funeralListingSection .commonTable {
    width: 100%;
    margin-bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    display: flex;
    align-items: center;
    padding: 15px;
}

.funeralListingSection .commonTable .detailsBtn {
    position: relative;
}

.funeralListingSection .commonTable .detailsBtn span {
    display: flex;
    align-items: center;
}

.funeralListingSection .commonTable .detailsHldr {
    width: 100%;
}

.funeral-modal {
    padding: 0 1em;
}

.funeralListingSection .modal-sub-heading h4 {
    color: #4f4f4f;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    text-transform: none;
}

.funeralListingSection .modal-content img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.funeralListingSection .userImg {
    display: flex;
    justify-content: center;
    font-size: 5em;
    color: gray;
}

.funeralListingSection .userImg img {
    border-radius: 100%;
    height: 168px;
    width: 168px;
}

.funeralListingSection hr {
    width: 50%;
    margin: 0 auto;
}

.funeralListingSection .summary {
    text-align: center;
    color: #333;
    font-size: 14px;
}

.funeralListingSection .commonTable th {
    font-weight: 600;
}

.funeralListingSection .commonTable td {
    font-weight: 300;
    vertical-align: middle;
}

.funeralListingSection .tableText {
    color: var(--grey-700, #4d4d4d);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
}

.funeralListingSection .commonTable .name {
    color: var(--accent_color1);
    font-weight: 700;
    padding: 0;
}

.funeralListingSection .text-muted {
    color: var(--accent_color1) !important;
    font-weight: 400;
}

.funeralListingSection .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #56ab91;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #56ab91;
}

@media (max-width: 767.98px) {
    .funeralListingSection .boxWrapper {
        height: auto;
        margin-bottom: 1rem;
    }

    .padding {
        padding: 0;
    }

    .nav-tabs .nav-link {
        font-size: 10px;
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        font-size: 11px;
    }

    .funeralListingSection .commonTable {
        display: block;
    }

    .funeralListingSection .commonTable .detailsHldr {
        margin-bottom: 15px;
    }

    .funeralListingSection .commonTable .detailsBtn span {
        justify-content: end;
    }

    .funeralListingSection .commonTable {
        border: none;
        box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.03);
        margin-bottom: 20px;
    }

    .funeralListingSection .nav-tabs {
        background: #fff;
    }

    .funeralListingSection .card .card-body {
        background: rgba(248, 248, 248, 1);
    }

    .funeralListingSection .nav-tabs {
        padding: 1em 1em 0 1em;
    }

    .funeralListingSection .p-paginator {
        padding: 0;
        background: none;
    }

    .p-dialog .p-dialog-content {
        padding: 1em !important;
    }
}
