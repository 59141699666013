.mediaFeaturedCardWrapper {
    position: relative;
    padding-top: 2em;
}

.mediaFeaturedCardWrapper .swiper {
    padding-top: 60px !important;
    padding-bottom: 50px !important;
}

.mediaFeaturedCardWrapper .newsCardBig {
    position: relative;
}

.mediaFeaturedCardWrapper .featuredCard {
    margin-top: 5em;
}

.mediaFeaturedCardWrapper .newsCardBig .card-body {
    position: relative;
}

.mediaFeaturedCardWrapper .newsCardBig .card-body .heading {
    position: relative;
    padding: 0;
    font-size: 2em;
    color: #046f44;
    line-height: normal;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
}

.mediaFeaturedCardWrapper .main-heading {
    color: #046f44;
    font-family: 'Poppins', sans-serif;
    font-size: 1.4em;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    position: absolute;
}

.mediaFeaturedCardWrapper .newsCardBig .card-body .textMain {
    position: relative;
    padding: 0;
    font-size: 2em;
    color: #000000;
    line-height: normal;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.mediaFeaturedCardWrapper .newsCardBig .card-body .summaryText {
    position: relative;
    padding: 0;
    font-size: 1em;
    color: #000000;
    line-height: normal;
    margin-top: 1.5em;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.mediaFeaturedCardWrapper .newsCardBig .NewsImg {
    position: relative;
    width: 30em;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    height: 30em;
    border-radius: 15px;
    box-shadow: 5px 15px 20px 0px rgba(0, 0, 0, 0.15);
}

.mediaFeaturedCardWrapper .newsCardBig .NewsImg img {
    width: 100%;
    border-radius: 15px;
    height: 30em;
    object-fit: cover !important;
}

.mediaFeaturedCardWrapper .button {
    background-color: #046f44;
    border: none;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-weight: 700;
    padding: 1em 1.5em;
    margin-top: 2em;
    border-radius: 10px;
}

.mediaFeaturedCardWrapper .arrow-left::after {
    top: calc(50% - 24px);
    border: solid transparent;
    content: ' ';
    position: absolute;
    pointer-events: none;
    border-width: 30px;
    z-index: 99;
    left: -54px;
    border-right-color: #046f44;
}

.mediaFeaturedCardWrapper .newsCard {
    position: relative;
    border-radius: 0.5em;
    box-shadow: 0px 4px 8px 0px rgba(192, 192, 192, 0.1);
}

.mediaFeaturedCardWrapper .card {
    height: 100%;
    border: none !important;
}

.mediaFeaturedCardWrapper .newsCard .cardImg {
    position: relative;
    overflow: hidden;
    margin: 0;
    display: flex;
    align-items: center;
}

.mediaFeaturedCardWrapper .newsCard .line {
    background-color: #e7e7e7;
    margin: 7px;
    height: 0;
}

.mediaFeaturedCardWrapper .newsCard .cardImg img {
    object-fit: cover;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.mediaFeaturedCardWrapper .newsCard .card-body {
    position: relative;
    padding: 0.625em 0 0.625em 0.625em;
    margin: 0;
    border-top: 1px solid #e7e7e7;
}

.mediaFeaturedCardWrapper .newsCard .card-body h3 {
    font-size: 1.125em;
    margin: 0 0 0.1875em 0;
    padding: 0;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.mediaFeaturedCardWrapper .newsCard .card-body .date {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.813em;
    font-weight: 400;
    color: #828282;
    font-family: 'Poppins', sans-serif;
}

.mediaFeaturedCardWrapper .newsCard .card-body .textMain {
    position: relative;
    margin: 0.5em 1em 0.5em 0;
    padding: 0;
    font-size: 0.813em;
    color: #000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: normal;
    height: auto;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.mediaFeaturedCardWrapper .card .cardImg1 {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.4;
    display: flex;
    object-fit: cover !important;
}

.mediaFeaturedCardWrapper .card .cardImg1 img {
    width: 100%;
    object-fit: cover !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    animation: fadeIn2 1s;
    -webkit-animation: fadeIn2 1s;
    -moz-animation: fadeIn2 1s;
    -o-animation: fadeIn2 1s;
    -ms-animation: fadeIn2 1s;
}

.mediaFeaturedCardWrapper .swiper-button-prev {
    top: 6% !important;
}

.mediaFeaturedCardWrapper .swiper-button-next {
    top: 6% !important;
}

@media (max-width: 767.98px) {
    .mediaFeaturedCardWrapper .card-order {
        order: 1;
    }

    .mediaFeaturedCardWrapper .button {
        width: 100%;
        padding: 1em;
    }

    .mediaFeaturedCardWrapper .newsCardBig .NewsImg {
        width: 100%;
    }

    .mediaFeaturedCardWrapper .swiper {
        padding-top: 60px !important;
        padding-bottom: 50px !important;
    }

    .mediaFeaturedCardWrapper .newsCardBig .card-body .textMain {
        margin-top: 1em;
    }
}