.gallerySection2 {
    position: relative;
    padding: 2em;
}

.headerBackground {
    background: #f8f8f8;
}

.padTopBottom {
    padding-top: 3.5em;
    padding-bottom: 3.5em;
}

.gallerySection2 .heading {
    font-weight: 500;
    font-size: 45px;
    text-align: start;
}

.gallerySection2 .body {
    font-weight: 400;
    font-size: 18px;
    text-align: start;
}

.gallerySection2 .search-hldr {
    position: relative;
    display: none;
}

.gallerySection2 .fa-close {
    color: #bdbdbd;
    position: absolute;
    top: 41px;
    right: 2.5rem;
    font-size: 1em;
    z-index: 999;
}

.gallerySection2 .search1 {
    top: 12px !important;
    left: 3rem !important;
    right: 1.5rem !important;
    left: auto !important;
    float: right;
}

.gallerySection2 .fa-close {
    color: #bdbdbd;
    position: absolute;
    top: 41px;
    right: 2.5rem;
    font-size: 1em;
    z-index: 999;
}

.gallerySection2 .search-hldr input {
    padding-left: 2.25em;
}

.gallerySection2 .search-hldr i {
    position: absolute;
    top: 0.625em;
    left: 0.625em;
    color: rgba(67, 67, 67, 0.48);
    z-index: 1;
}

.gallerySection2 .filters-main {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    padding: 24px 12px;
    margin-top: 1.5rem;
}

.gallerySection2 .filter-label {
    font-weight: 400;
    font-size: 14px;
    color: #828282;
    padding: 0em 1em;
}

.gallerySection2 .tag-dropdown-main .p-multiselect {
    border: none;
    margin-right: 1em;
}

.gallerySection2 .tag-dropdown-main .p-multiselect .p-multiselect-label.p-placeholder {
    color: var(--accent_color1);
    font-weight: 400;
    font-size: 14px;
    padding: 0rem 0;
}

.gallerySection2 .tag-dropdown-main .p-multiselect .p-multiselect-trigger {
    color: #282828;
    width: 2rem;
}

.gallerySection2 .tag-dropdown-main .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none;
    border: none;
}

.gallerySection2 .p-multiselect-panel {
    width: 300px !important;
    border-radius: 0;
}

.gallerySection2 .newsCard {
    position: relative;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    margin: 0;
    background: #fff;
}

.gallerySection2 .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.6/1;
}

.gallerySection2 .newsCard .cardImg img {
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    height: 100%;
}

.gallerySection2 .newsCard .card-body {
    position: relative;
    padding: 1em 15px;
    margin: 0;
}

.gallerySection2 .newsCard .card-body .tagName {
    font-weight: 400;
    font-size: 0.625em;
    text-transform: uppercase;
    line-height: 25.2px;
    letter-spacing: 2.1px;
}

.gallerySection2 .newsCard .dateName {
    position: relative;
    padding: 5px;
    display: flex;
    align-items: center;
}

.gallerySection2 .newsCard .dateName .name {
    font-size: 1em;
    font-weight: 500;
    color: #1d1d1d;
    margin-right: 0.9375em;
}

.gallerySection2 .newsCard .dateName .date {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 0.875em;
    color: #666;
}

.gallerySection2 .newsCard .dateName .date .dot {
    width: 0.5em;
    height: 0.5em;
    background-color: #046f44;
    margin-right: 0.625em;
    border-radius: 0.625em;
}

.gallerySection2 .newsCard .card-body .tag-heading {
    color: #046f44;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
    letter-spacing: 2.1px;
    text-transform: uppercase;
    margin-bottom: 0.6em;
}

.gallerySection2 .newsCard .card-body .title {
    font-size: 1rem;
    margin: 0 0 2em 0;
    padding: 0;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.gallerySection2 .newsCard .card-body .textMain {
    position: relative;
    margin: 0.5em 0;
    padding: 0;
    font-size: 0.875em;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: normal;
}

.gallerySection2 .newsCard .text {
    color: #046f44;
    font-weight: 700;
}

.gallerySection2 .clear-all-txt {
    color: #56ab91;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    cursor: pointer;
}

.gallerySection2 .filter-btn {
    display: flex;
}

.gallerySection2 .filter-btn .button {
    padding: 10px 24px 10px 10px;
    color: #282828;
    background: #fff;
    border-radius: 4px;
}

.filters-modal-header {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1em;
    align-items: center;
}

.filters-modal-header .title {
    font-weight: 700;
    color: #000;
    font-size: 24px;
}

.filters-modal-header .clear-all-txt {
    margin-left: auto;
    font-weight: 400;
    color: #000;
    font-size: 16px;
    border-bottom: 1px solid #000;
    margin-left: 8em;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    top: 50px;
}

.tag-filters-main .p-multiselect {
    border: none;
}

.tag-filters-main .p-multiselect .p-multiselect-label.p-placeholder {
    color: #000;
    font-weight: 400;
    font-size: 14px;
}

.tag-filters-main .p-multiselect .p-multiselect-trigger {
    color: #282828;
}

.tag-filters-main .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none;
    border: none;
}

.tag-filters-main {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1em;
}

.gallerySection2 .card {
    border: none;
    border-radius: 10px;
    border-radius: 15px;
    border: 1px solid #e3e4e8;
    background: #fff;
    box-shadow: 5px 5px 25px 0px #0000001a;
    height: 100%;
}

.gallerySection2 .newsCard .card-body .authorDateMain {
    display: flex;
    align-items: center;
    font-size: 0.75em;
    font-weight: 400;
    line-height: normal;
}

.gallerySection2 .newsCard .card-body .authorDateMain .author {
    color: var(--accent_color1);
}

.gallerySection2 .newsCard .card-body .authorDateMain .date {
    color: var(--accent_color3);
}

.gallerySection2 .mobileFiltersHldr {
    display: none;
}

.gallerySection2 .filtersHldr {
    display: block;
}

.gallerySection2 .p-paginator {
    background: none;
}

.gallerySection2 .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--primary_color);
    border-color: var(--primary_color);
    color: var(--accent_color4);
}

.gallerySection2 .p-multiselect .p-multiselect-label {
    padding: 0 0.75rem;
}

@media (max-width: 767.98px) {
    .gallerySection2 .mobileFiltersHldr {
        display: block;
    }

    .gallerySection2 .filtersHldr {
        display: none;
    }

    .tag-filters-main {
        padding-bottom: 0;
        padding-left: 1em;
    }

    .filters-modal-header {
        padding-left: 1em;
    }

    .filter-apply-btn {
        padding-right: 1em;
    }

    .p-multiselect-panel {
        min-width: 100% !important;
        left: 0px !important;
    }

    .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
        padding: 1em 0 0 0 !important;
    }

    .p-multiselect-label-container {
        overflow: auto;
        flex-direction: row;
        width: 300px;
        display: flex;
    }

    .p-multiselect-item {
        white-space: normal !important;
    }

    .gallerySection2 .p-multiselect .p-multiselect-label {
        display: flex;
        align-items: center;
        width: 100%;
        overflow: scroll !important;
        padding: 1em 0 0 0 !important;
    }

    .p-multiselect-label-container {
        display: flex;
        flex-direction: row;
    }

    .p-multiselect-label {
        overflow: inherit !important;
    }

    .gallerySection2 .newsCard {
        display: flex;
        flex-direction: row;
        padding: 15px;
        box-shadow: 3px 3px 5px 0px #0000001a;
        border: none;
    }

    .gallerySection2 .newsCard .cardImg {
        width: 150px;
        flex-shrink: 0 !important;
        aspect-ratio: 1.5;
        height: 98px;
    }

    .gallerySection2 .newsCard .cardImg img {
        border-radius: 10px;
        height: 100%;
        object-fit: cover;
    }

    .gallerySection2 .newsCard .card-body {
        padding: 0px 0px 0px 15px;
    }

    .gallerySection2 .newsCard .card-body .tag-heading {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px;
        margin-bottom: 0;
    }

    .gallerySection2 .heading {
        font-size: 32px;
    }

    .gallerySection2 .body {
        font-size: 12px;
        display: block;
    }

    .gallerySection2 .newsCard .card-body .title {
        margin-top: 5px;
        font-size: 12px;
        margin-bottom: 0;
        display: block;
    }

    .gallerySection2 .newsCard .card-body .tagName {
        font-size: 9px;
        line-height: normal;
    }

    .gallerySection2 .newsCard .card-body .authorDateMain {
        font-size: 9px;
        margin-top: 25px;
    }

    .gallerySection2 {
        padding: 1em 0 2em 0;
    }
}

.tag-filters-main .p-multiselect {
    width: 100%;
}

.filter-apply-btn {
    margin-top: 14em;
    display: flex;
    justify-content: end;
}

.filter-apply-btn .button {
    padding: 0.825em 3em;
    background-color: #00558c;
    color: #fff;
    border: none;
}
