.home-emanage {
    position: relative;
    padding: 3.125em 0;
}

.home-emanage .img-container {
    position: relative;
    width: 20.875em;
    margin: 0 auto;
}

.home-emanage .img-container img {
    width: 100%;
}

.home-emanage .desc-container {
    position: relative;
}

.home-emanage .desc-container h3 {
    font-size: 2em;
    margin-bottom: 0.5em;
}

.home-emanage .desc-container p {
    margin-bottom: 1.5em;
    font-size: 1.1111111111em;
}

.home-emanage .desc-container .download-container {
    display: flex;
    gap: 20px;
    align-items: center;
}

.home-emanage .desc-container .download-container .download-qr {
    width: 7.2222222222em;
    height: 7.2222222222em;
}

.home-emanage .desc-container .download-container p {
    color: #565c68;
    font-size: 13px;
    margin: 0;
}

.home-emanage .desc-container .download-container .download-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
}

.home-emanage .desc-container .download-container .download-buttons img {
    width: 7.2222222222em;
}

@media (max-width: 767.98px) {
    .home-emanage .img-container {
        width: 16.25em;
        margin-bottom: 1.5em;
    }

    .home-emanage .desc-container {
        text-align: center;
    }

    .home-emanage .desc-container h3 {
        font-size: 1.625em;
    }

    .home-emanage .desc-container p {
        font-size: 1em;
    }

    .home-emanage .desc-container .download-container {
        justify-content: center;
    }
}
