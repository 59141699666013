.ResourceSection {
    position: relative;
    padding-bottom: 1.5em;
    padding-top: 3em;
}

.ResourceSection .boxMain {
    position: relative;
    padding: 0.9375em;
    margin: 0;
    background: var(--accent_color4);
    border-radius: 0.5em;
    border: 1px solid #f2f2f3;
    box-shadow: 0 1px 2.5em rgba(4, 111, 68, 0.06);
}

.boxMainHldr {
    display: flex;
}

.ResourceSection .titleMain {
    position: relative;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
}

.ResourceSection .titleMain h3 {
    color: var(--primary_color);
    font-weight: 700;
    display: block;
    position: relative;
    padding-left: 0.8125em;
    font-size: 2em;
}

.ResourceSection .titleMain h3::before {
    content: '';
    position: absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background: var(--primary_color);
}

.ResourceSection .card {
    height: 100%;
}

.ResourceSection .swiper-slide {
    height: auto;
}

.ResourceSection .boxMain .TitleMain {
    position: relative;
    font-size: 0.875em;
    margin: 0 0 0.9375em 0;
    padding: 0;
    font-weight: 700;
    text-transform: uppercase;
    color: #111;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 3.1em;
    text-align: start;
}

.ResourceSection .boxMain .tileCardTxt {
    margin-top: auto;
}

.ResourceSection .boxMain .ImgMain {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 0;
    aspect-ratio: 1.4;
    display: flex;
}

.ResourceSection .boxMain .ImgMain img {
    width: 100%;
    border-radius: 0.5625em;
}

.ResourceSection .boxMain .BodyTxt {
    position: relative;
    margin: 0;
    padding: 0;
}

.ResourceSection .boxMain .BodyTxt .date {
    position: relative;
    font-size: 0.75em;
    color: #046f44;
    margin-bottom: 0.5em;
    text-align: start;
}

.ResourceSection .boxMain .BodyTxt p {
    font-size: 0.875em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.ResourceSection .boxMain .ViewResource {
    position: relative;
    margin-top: 10px;
    padding: 0.625em 0.9375em 0;
    margin-left: -0.9375em;
    margin-right: -0.9375em;
    border-top: 1px solid #c2cfe0;
    display: flex;
    align-items: center;
}

.ResourceSection .boxMain .ViewResource a {
    display: block;
    width: 100%;
}

.ResourceSection .boxMain .ViewResource a i {
    float: right;
}

.ResourceSection .boxMain .ViewResource .btn-link {
    color: var(--Primary-Green, #046f44);
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ResourceSection .swiper {
    padding-top: 38px !important;
}

.ResourceSection .swiper-button-prev,
.swiper-button-next {
    margin-top: 0;
}

.ResourceSection .swiper-button-next {
    margin-top: 0;
}

.ResourceSection .swiper-button-prev {
    top: 2% !important;
}

.ResourceSection .swiper-button-next {
    top: 2% !important;
}

.ResourceSection .viewIconArrowIcon {
    color: var(--accent_color4);
    background: var(--primary_color);
    border-radius: 100%;
    padding: 1px 3px;
}

@media (max-width: 767.98px) {
    .ResourceSection .boxMain {
        margin-bottom: 2em;
    }

    .ResourceSection .swiper-pagination-bullet-active {
        width: 43px !important;
        height: 7px !important;
        border-radius: 10px !important;
        background: var(--primary_color) !important;
    }
}

[dir='rtl'] .ResourceSection .titleMain {
    padding: 0.9375em 0.25em 0.9375em 0em;
}

[dir='rtl'] .ResourceSection .titleMain h3 {
    padding-left: 0;
    padding-right: 0.8125em;
}

[dir='rtl'] .ResourceSection .titleMain h3::before {
    right: 0;
}

[dir='rtl'] .float-end {
    float: left !important;
    transform: rotate(180deg);
}

[dir='rtl'] .ResourceSection .btn {
    margin-left: 8rem;
}
