.application_links {
    position: relative;
    padding: 100px 0px;
}

.application_links [class*='col-'] {
    margin-bottom: 1.5em;
}

.application_links .post-card {
    height: 100%;
    border: 1px solid var(--accent_color4);
    position: relative;
    background: var(--accent_color4);
    padding: 1.25em;
    border-radius: 0.625em;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.application_links .post-card:hover {
    border: 1px solid var(--primary_color);
    transform: translateY(-5px);
    box-shadow: 0 4px 60px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.application_links .post-card .cardTitle {
    font-size: 22px;
    color: var(--primary_color);
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: normal;
    margin-bottom: 10px;
}

.application_links .post-card h3 {
    font-size: 18px;
    color: var(--accent_color1);
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    margin-bottom: 10px;
}

.application_links .post-card p {
    font-size: 14px;
    color: var(--accent_color3);
    font-weight: 300;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    margin-bottom: 20px;
}

.application_links .btn {
    position: relative;
    font-size: 14px;
    box-shadow: none;
    white-space: normal;
    padding: 10px 24px;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-weight: 500;
    text-decoration: none;
    width: 50%;
    margin-top: auto;
}

.application_links .btn-main {
    background: var(--primary_color);
    color: var(--accent_color4);
    border: none;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.application_links .btn-main:active,
.application_links .btn-main:focus,
.application_links .btn-main:hover {
    color: var(--accent_color4);
    background-color: var(--primary_color);
}
