.communityWrapper {
    position: relative;
    margin: 0;
    padding-bottom: 3em;
    padding-top: 3em;
}

.communityWrapper h1 {
    font-size: 2em;
    color: var(--primary_color);
    position: relative;
    padding-bottom: 0.5em;
    font-weight: 700;
}

.communityWrapper .header-body-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--accent_color1);
}

.communityWrapper .header-body-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--accent_color1);
}

.communityWrapper .line {
    color: var(--accent_color3);
    margin: 1rem 0 0 0;
    width: 100%;
    border-top: 1px solid;
}

.communityWrapper .number {
    color: var(--accent_color2);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
}

.communityWrapper .text {
    color: var(--accent_color1);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    text-align: start;
}
