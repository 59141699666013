.searchResultSection {
    position: relative;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
}

.searchResultSection .boxMain {
    position: relative;
    padding: 0.9375em;
    margin: 0;
    background: #fff;
    border-radius: 0.5em;
    border: 1px solid #f2f2f3;
    box-shadow: 0 1px 2.5em rgba(4, 111, 68, 0.06);
}

.searchResultSection .search-hldr {
    position: relative;
}

.searchResultSection .fa-close {
    color: #bdbdbd;
    position: absolute;
    top: 41px;
    right: 2.5rem;
    font-size: 1em;
    z-index: 999;
}

.searchResultSection .search-hldr input {
    padding-left: 2.25em;
}

.searchResultSection .search-hldr i {
    position: absolute;
    top: 0.625em;
    left: 0.625em;
    color: rgba(67, 67, 67, 0.48);
    z-index: 1;
}

.searchResultSection .search1 {
    top: 12px !important;
    left: 3rem !important;
    right: 1.5rem !important;
    left: auto !important;
    float: right;
}

.searchResultSection .titleMain {
    position: relative;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
}

.searchResultSection .titleMain h3 {
    color: #046f44;
    font-weight: 700;
    display: block;
    position: relative;
    padding-left: 0.8125em;
    font-size: 2em;
}

.searchResultSection .titleMain h3::before {
    content: '';
    position: absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    background: #046f44;
}

.searchResultSection .card {
    height: 100%;
}

.searchResultSection .swiper-slide {
    height: auto;
}

.searchResultSection .boxMain .TitleMain {
    position: relative;
    font-size: 0.875em;
    margin: 0 0 0.9375em 0;
    padding: 0;
    font-weight: 700;
    color: #111;
    text-align: start;
}

.searchResultSection .boxMain .ImgMain {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.4;
    display: flex;
}

.searchResultSection .boxMain .ImgMain img {
    width: 100%;
}

.searchResultSection .boxMain .BodyTxt {
    position: relative;
    margin: 0;
    padding: 0;
}

.searchResultSection .boxMain .BodyTxt .date {
    position: relative;
    font-size: 0.75em;
    color: #046f44;
    margin-bottom: 0.5em;
    text-align: start;
}

.searchResultSection .boxMain .BodyTxt p {
    font-size: 0.875em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.searchResultSection .boxMain .ViewResource {
    position: relative;
    margin-top: 0.9375em;
    padding: 0.625em 0.9375em 0;
    margin-left: -0.9375em;
    margin-right: -0.9375em;
    border-top: 1px solid #c2cfe0;
    display: flex;
    align-items: center;
}

.searchResultSection .boxMain .ViewResource a {
    display: block;
    width: 100%;
}

.searchResultSection .boxMain .ViewResource a i {
    float: right;
}

.searchResultSection .boxMain .ViewResource .btn-link {
    color: var(--Primary-Green, #046f44);
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.searchResultSection .swiper {
    padding-top: 38px !important;
}

.searchResultSection .swiper-button-prev,
.swiper-button-next {
    margin-top: 0;
}

.searchResultSection .swiper-button-next {
    margin-top: 0;
}

.searchResultSection .swiper-button-prev {
    top: 2% !important;
}

.searchResultSection .swiper-button-next {
    top: 2% !important;
}

.searchResultSection .text {
    color: #56ab91;
    text-align: center;
    font-weight: 600;
}

@media (max-width: 767.98px) {
    .searchResultSection .boxMain {
        margin-bottom: 1.875em;
    }
}

[dir='rtl'] .searchResultSection .titleMain {
    padding: 0.9375em 0.25em 0.9375em 0em;
}

[dir='rtl'] .searchResultSection .titleMain h3 {
    padding-left: 0;
    padding-right: 0.8125em;
}

[dir='rtl'] .searchResultSection .titleMain h3::before {
    right: 0;
}

[dir='rtl'] .float-end {
    float: left !important;
    transform: rotate(180deg);
}

[dir='rtl'] .searchResultSection .btn {
    margin-left: 8rem;
}
