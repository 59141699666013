.updateSecation {
    position: relative;
}

.updateSecation [class*='col-'] {
    margin-bottom: 1.5em;
}

.updateSecation .post-card {
    height: 100%;
    border: 1px solid #fff;
    position: relative;
    background: #fff;
    padding: 1.25em;
    text-align: center;
    border-radius: 0.625em;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.updateSecation .post-card:hover {
    border: 1px solid #56ab91;
    transform: translateY(-5px);
    box-shadow: 0 4px 60px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.updateSecation .post-card .iconMain {
    width: 4.375em;
    height: 4.375em;
    margin: 0 auto;
}

.updateSecation .post-card .cardTitle {
    font-size: 1.375em;
    color: #046f44;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: normal;
    margin-bottom: 0.625em;
}

.updateSecation .post-card h3 {
    font-size: 1.125em;
    color: #282828;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    margin-bottom: 0.625em;
}

.updateSecation .post-card p {
    font-size: 1.125em;
    color: #4d4d4d;
    font-weight: 300;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    margin-bottom: 0.625em;
}

.btn {
    position: relative;
    font-size: 1em;
    box-shadow: none;
    white-space: normal;
    padding: 0.75em 1.875em;
    border-radius: 0.25em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    text-decoration: none;
}

.btn .btn-icon {
    font-size: 1.125em;
}

.btn .btn-icon.btn-icon-before {
    padding-left: 0;
    padding-right: 0.625em;
}

.btn.btn-center .btn-icon {
    padding-left: 0.625em;
}

.btn-main {
    background: #56ab91;
    color: #fff;
    border: none;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.btn-main .btn-icon {
    padding-left: 0.625em;
    padding-right: auto;
    -webkit-transition: transform 0.5s ease-in-out;
    -moz-transition: transform 0.5s ease-in-out;
    -o-transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
}

.btn-main:active,
.btn-main:focus,
.btn-main:hover {
    color: #fff;
    background-color: #252525;
}

.btn-main:active .btn-icon:not(.btn-icon-before),
.btn-main:focus .btn-icon:not(.btn-icon-before),
.btn-main:hover .btn-icon:not(.btn-icon-before) {
    transform: translateX(0.75em);
}

.btn-link {
    color: #56ab91;
    border: 0;
    padding: 0;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.btn-link .btn-icon {
    padding-left: 0.375em;
    padding-right: auto;
    -webkit-transition: transform 0.5s ease-in-out;
    -moz-transition: transform 0.5s ease-in-out;
    -o-transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
}

.btn-link:focus,
.btn-link:hover {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    color: #1d1d1d;
}

.btn-link:focus .btn-icon:not(.btn-icon-before),
.btn-link:hover .btn-icon:not(.btn-icon-before) {
    transform: translateX(0.625em);
}

.btn-sm {
    font-size: 0.875em;
    padding: 0.625em 1.5em;
}
