.speechDetailsSection {
    position: relative;
    padding: 2.125em 0;
    margin: 0;
    text-align: start;
}

.speechDetailsSection .container {
    max-width: 800px !important;
}

.speechDetailsSection .heading {
    color: var(--accent_color1);
    font-size: 1.8em;
    font-weight: 600;
}

.speechDetailsSection .author {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--accent_color3);
}

.speechDetailsSection .labelName {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--accent_color3);
}

.speechDetailsSection .date {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: start;
    color: var(--accent_color3);
}

.speechDetailsSection .speechDetailImageMain .container {
    max-width: 1015px !important;
}

.speechDetailsSection .speechInfoSecation .container {
    max-width: 1015px !important;
}

.speechDetailsSection .only-img img {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.speechDetailsSection .card-box {
    background: var(--accent_color2);
    height: 100%;
    border-radius: 8px;
}

.speechDetailsSection .card-box .detailsMain {
    border-right: 1px solid var(--accent_color3);
    height: 100%;
}

.speechDetailsSection .card-box .heading {
    font-size: 2em;
    font-weight: 600;
    text-align: left;
    text-align: left;
    color: var(--accent_color1);
}

.speechDetailsSection .card-box .sub-heading {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--accent_color1);
}

.speechDetailsSection .card-box .sub-heading1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--primary_color) !important;
}

.speechDetailsSection .card-box .sub-heading1 a {
    color: var(--primary_color);
}

.speechDetailsSection .card-box .sub-heading1 a:hover {
    text-decoration: underline;
}

.speechInfoSecation {
    margin-bottom: 1.875em;
}

.speechTextSecation {
    position: relative;
    margin: 0;
    padding: 0em 0 2.5em 0 !important;
}

.speechTextSecation p {
    color: #000;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 25px;
}
