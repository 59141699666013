#MainNav .navbar-nav .nav-item {
    position: static;
    padding: 20px 24px;
    display: flex;
    justify-content: center;
    height: 100%;
}

#MainNav .navbar-nav .nav-item .nav-link {
    padding: 0;
}

#MainNav .navbar-nav .mega-menu .nav-item::after {
    content: ' ';
    position: absolute;
    top: 100%;
    width: 25px;
    height: 16px;
    display: none;
    z-index: 10;
    /* animation-name: fadeInDown;
    animation-duration: 0s;
    animation-fill-mode: both;
    background: #fff; */
    margin: 0 auto;
}

#MainNav .navbar-nav .nav-item:hover::after {
    display: block;
}

.subMenuWrapper li.active {
    background-color: var(--accent_color3);
    color: var(--primary_color);
    border-radius: 10px;
}

.menuContainer {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    padding: 15px 0.75em 0 0.75em;
    margin: 0 auto;
    overflow: hidden;
    animation-name: fadeInDown;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    max-width: 100%;
    max-height: fit-content;
    top: 100%;
}

.menuContainer::after {
    content: ' ';
    position: absolute;
    top: 100%;
    width: 25px;
    height: 16px;
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
    display: none;
    z-index: 10;
    animation: fadeInAnimation ease 2.5s;
    animation-iteration-count: 1;
    animation-fill-mode: none;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    background: #fff;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.subMenuWrapper {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    background: #fff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.subMenuWrapper .titleHldr {
    padding: 0em 1em 0em 0em;
}

.subMenuWrapper .titleHldr .iconMain {
    position: relative;
    width: 60px;
    height: 60px;
}

.subMenuWrapper .titleHldr .iconMain img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.subMenuWrapper .titleHldr .heading {
    color: #404041;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.4px;
    margin-bottom: 0.7em;
    margin-top: 12px;
    text-transform: uppercase;
}

.subMenuWrapper .titleHldr hr {
    margin-bottom: 5px;
}

.subMenuWrapper .py-2 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
}

.subMenuWrapper .pl-2 {
    padding-left: 45px !important;
}

.subMenuWrapper .menu4 {
    padding-left: 45px;
    padding-right: 45px;
}

.subMenuWrapper li {
    list-style: none;
    color: #404041;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    padding: 0.7em 0;
    line-height: normal;
}

.subMenuWrapper li.mainLinks {
    padding: 1em 1.1em 1em 0.7em !important;
}

.subMenuWrapper .body-text {
    font-size: 0.9375em !important;
    font-weight: 400;
    padding: 1.1em 0 0.8em 0 !important;
}

.subMenuWrapper .sub-body-text {
    font-size: 0.9375em !important;
    padding: 0 !important;
}

.subMenuWrapper .viewMoreText {
    color: #404041;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 1em 0 1em 1.3em;
}

.subMenuWrapper .sideMenu {
    padding: 1em 2em;
    border-radius: 0px 15px 15px 0px;
    background: #eef7f4;
    height: 100%;
    position: relative;
    right: -1px;
}

.subMenuWrapper .sub-menu-style2 {
    padding: 3em 2em !important;
}

.subMenuWrapper .sub-menu-style3 {
    padding: 2.5em 2em 1em 2em !important;
}

.subMenuWrapper .sideMenu .title {
    color: #046f44;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: 2.25px;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

.subMenuWrapper .sideMenu .imgMain {
    position: relative;
    width: 100%;
    padding-top: 1.25rem;
    overflow: hidden;
    height: 230px;
}

.subMenuWrapper .sideMenu .imgMain img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.subMenuWrapper .sideMenu ul {
    padding-left: 0em;
}

.subMenuWrapper .sideMenu ul li {
    list-style: none;
    color: #404041;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    padding: 0.4em 0;
    line-height: normal;
}

.subMenuWrapper .sideMenu .viewMoreText {
    color: #404041;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 1em 0;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        /* transform: translateY(-20px); */
    }

    100% {
        opacity: 1;
        /* transform: translateY(0); */
    }
}

@keyframes fadeOpacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.7;
    }
}

.fade-opacity {
    opacity: 0; /* Ensure it starts from 0 opacity */
    animation: fadeOpacity 1s forwards;
}

.subMenuWrapper .imgTitle {
    position: relative;
    padding: 1.3em 2em;
    margin: 2em 0;
}

.subMenuWrapper .imgTitle ul {
    position: relative;
    display: grid;
    grid-template-columns: 32.5% 32.5% 32.5%;
    column-gap: 0.5%;
    margin: 0;
    padding: 0;
}

.subMenuWrapper .imgTitle ul li {
    position: relative;
    margin: 0;
    padding: 0;
    text-align: center;
}

.subMenuWrapper .imgTitle ul li .imgHldr {
    position: relative;
    width: 214px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    border-radius: 10px;
}

.subMenuWrapper .imgTitle ul li .imgHldr img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.subMenuWrapper .imgTitle ul li .link {
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin-top: 15px;
}

.menuContainer .row [class^='col-'] {
    padding: 0 0.75em;
}

.subMenuWrapper .tabMenuHldr {
    position: relative;
    padding-left: 35px;
    padding-right: 35px;
}

.subMenuWrapper .tabMenuHldr ul {
    position: relative;
    /* display: grid;
    grid-template-columns: 25% 25% 25% 25%; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.subMenuWrapper .tabMenuHldr ul li {
    position: relative;
    margin: 0 0 24px 0;
    padding: 0;
    text-align: center;
    width: 25%;
}

.subMenuWrapper .tabMenuHldr ul li .imgHldr {
    position: relative;
    width: 85%;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    aspect-ratio: 1.21/1;
}

.subMenuWrapper .tabMenuHldr ul li .imgHldr::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) -11.76%,
        rgba(255, 255, 255, 0.43) -11.75%,
        rgba(220, 220, 220, 0) 53.43%,
        rgba(0, 0, 0, 0.43) 80.21%,
        rgba(0, 0, 0, 0.43) 95.34%
    );
}

.subMenuWrapper .tabMenuHldr ul li .imgHldr img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.subMenuWrapper .tabMenuHldr ul li .imgHldr .titleLink {
    position: absolute;
    padding: 0 10px;
    left: 0;
    right: 0;
    bottom: 15px;
    z-index: 2;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}

.subMenuWrapper .p-tabview .p-tabview-nav {
    border: none !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: #046f44;
    color: var(--primary_color) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: var(--primary_color) !important;
}

.subMenuWrapper .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: #046f44;
}

.subMenuWrapper .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.subMenuWrapper .p-tabview .p-tabview-nav-btn.p-link {
    box-shadow: none;
}

.subMenuWrapper .subMenuWrapper .p-tabview .p-tabview-nav-btn.p-link {
    box-shadow: none;
}

.subMenuWrapper .p-tabview .p-tabview-nav-btn.p-link {
    color: var(--primary_color) !important;
}

.subMenuWrapper .p-tabview .p-tabview-nav-btn.p-link:focus {
    box-shadow: none;
}

.subMenuWrapper .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: var(--accent_color1) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.subMenuWrapper .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    padding-left: 0;
    padding-right: 0;
}

.subMenuWrapper .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--primary_color) !important;
    bottom: 0;
    left: 0;
    right: 0;
}

.subMenuWrapper .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    position: relative;
    padding: 0;
    padding-bottom: 1em;
}

.subMenuWrapper .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2px;
    height: 3px;
    width: 100%;
    background: var(--primary_color) !important;
}

.subMenuWrapper .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    display: none !important;
}

.subMenuWrapper .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: none !important;
}

.subMenuWrapper .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0;
    padding-bottom: 1em;
}

.subMenuWrapper .p-tabview .p-tabview-nav li {
    margin: 0 0 0 0em !important;
    width: auto;
}

.subMenuWrapper .p-tabview-nav-btn {
    top: 0;
    background: white !important;
    height: 38px;
    padding-bottom: 20px;
}

.subMenuWrapper .p-tabview .p-tabview-panels {
    padding: 1.25rem 0;
}

.subMenuWrapper .tabMenuHldr ul.p-tabview-nav {
    display: flex;
    justify-content: flex-start;
    padding-left: 3%;
}

.subMenuWrapper .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    position: absolute;
    right: 0;
    rotate: 90deg;
}

.subMenuWrapper
    .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-toggle-icon {
    rotate: 180deg;
    position: absolute;
    right: 0;
}

.subMenuWrapper .p-icon {
    width: 1.25rem;
    height: 14px;
}

.subMenuWrapper .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: 0;
    color: var(--accent_color1);
}

.mega-menu .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    color: var(--primary_color);
}

.mega-menu .more-icon {
    position: absolute;
    bottom: 6px;
    font-size: 16px;
}

.featured-summary {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.featured-title {
    font-size: 1rem;
}

.shrink-2 {
    flex-shrink: 2;
}
@media (max-width: 767.98px) {
    header .logoMain img {
        width: 80%;
    }

    header .searchMain .userMain .contPopup .titleBox {
        position: fixed;
        padding: 10px 10px 5px 10px;
        border-bottom: 1px solid gray;
        z-index: 9999;
        width: 100%;
        background-color: #fff;
    }

    header .searchMain .userMain .contPopup .contMain {
        padding-top: 54px;
    }

    header .searchMain .userMain .contPopup {
        overflow: auto;
        height: 100%;
    }

    .mega-menu .p-accordion .p-accordion-content {
        border: none;
    }

    .mega-menu .p-accordion .p-accordion-header .p-accordion-header-link {
        border: none;
        background: none;
    }

    .mega-menu .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
        box-shadow: none;
    }

    .mega-menu .p-accordion .p-accordion-header .p-accordion-header-link {
        padding: 1em 0;
    }

    .mega-menu .p-accordion .p-accordion-header .p-accordion-header-link {
        font-size: 1rem;
        color: black;
        font-weight: 500;
    }

    .mega-menu
        .p-accordion
        .p-accordion-header:not(.p-disabled).p-highlight:hover
        .p-accordion-header-link {
        background: none !important;
    }

    .mega-menu
        .p-accordion
        .p-accordion-header:not(.p-disabled).p-highlight
        .p-accordion-header-link {
        background: none !important;
    }

    .header2 .searchMain .userMain .contPopup .contMain ul li.mainHeading {
        color: var(--primary_color);
        text-align: left;
        width: 100%;
    }

    .mega-menu .p-accordion .p-accordion-content {
        padding: 0;
    }

    .mega-menu .menuContainer {
        position: relative;
        left: 0;
        right: 0;
        z-index: 9;
        margin: 0 auto;
        overflow: auto;
        animation: none;
        max-width: 100%;
        max-height: fit-content;
        top: 100%;
        padding: 0;
        margin-bottom: 0em;
    }

    .mega-menu .subMenuWrapper {
        box-shadow: none;
        border-radius: 0;
    }

    .subMenuWrapper .sideMenu {
        padding: 0 2em;
    }

    .mega-menu .subMenuWrapper .titleHldr {
        padding: 0em;
        margin-bottom: 0em;
        padding-top: 0 !important;
    }

    header .searchMain .userMain .contPopup .contMain ul li:hover {
        background: none;
    }

    .mega-menu .subMenuWrapper .sideMenu .imgMain {
        margin-bottom: 1em;
    }

    .mega-menu .subMenuWrapper .sideMenu {
        margin-top: 1em;
        margin-bottom: 0em;
        height: auto;
        padding-top: 10px !important;
        padding-bottom: 0 !important;
    }

    .mega-menu .subMenuWrapper .imgTitle ul {
        grid-template-columns: 100%;
    }

    .mega-menu .subMenuWrapper .tabMenuHldr {
        margin: 0;
        padding: 0;
    }

    .mega-menu .subMenuWrapper .tabMenuHldr ul {
        grid-template-columns: 50% 50%;
    }

    .mega-menu .subMenuWrapper .imgTitle {
        padding: 0;
        margin: 0;
    }

    header .searchMain .userMain .contPopup .contMain ul li {
        padding: 0;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }

    .mega-menu .subMenuWrapper .imgTitle ul li .imgHldr {
        width: 174px;
        height: 112px;
        border-radius: 10px;
        margin-left: 0;
        margin-right: 15px;
    }

    .mega-menu .subMenuWrapper .sideMenu .title {
        margin-bottom: 20px;
        margin-top: 0px !important;
    }

    .mega-menu .accordion-item {
        border: none;
    }

    .subMenuWrapper .imgTitle ul li .link {
        text-align: left;
    }

    .mega-menu .accordion-button::after {
        font: normal normal normal 14px/1 FontAwesome;
        color: var(--primary_color) !important;
        content: var(--up-down-arrow) !important;
        background: 0 0;
        font-size: 1.375em;
        position: absolute;
        top: 0.625em;
        right: 20px;
        text-align: center;
    }

    .mega-menu .accordion-body {
        padding-bottom: 0;
    }

    .mega-menu .accordion-button:not(.collapsed) {
        color: black;
        background: none;
        box-shadow: none;
    }

    .mega-menu
        .p-accordion
        .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
        .p-accordion-header-link {
        background: none;
        color: black;
    }

    .subMenuWrapper .tabMenuHldr ul li .imgHldr {
        margin-right: 1em;
    }

    .mega-menu .accordion-item:last-of-type .accordion-button.collapsed {
        border-radius: 0;
    }

    .header2 .searchMain .userMain .contPopup .contMain ul li.subHeading {
        width: 100%;
        text-align: left;
    }

    .header2 .searchMain .userMain .contPopup .contMain ul.mobileImg {
        align-items: center !important;
    }

    .subMenuWrapper .sideMenu .imgMain {
        padding-top: 0;
    }

    .subMenuWrapper .titleHldr .iconMain img {
        border-radius: 0;
    }

    .p-tabview .p-tabview-panels {
        overflow: scroll;
    }
}
