.paragraphHighlight {
    position: relative;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
    background-color: #eef7f4;
}

.paragraphHighlight .heading {
    position: relative;
}

.paragraphHighlight .heading h3 {
    color: #333333;
    font-weight: 600;
    display: flex;
    position: relative;
    padding-left: 2.5em;
    font-size: 1.11em;
    margin-bottom: 1em;
    line-height: 34px;
}

.paragraphHighlight .line {
    content: '';
    position: absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    width: 0.15em;
    height: 100%;
    background: #046f44;
}

.paragraphHighlight .body {
    color: #333333;
    font-weight: 500;
    display: flex;
    position: relative;
    font-size: 25px;
    padding-left: 2.5em;
    line-height: 37px;
}
.paragraphHighlight .body p a{
    color: var(--primary_color) !important;
    text-decoration: underline !important;
}