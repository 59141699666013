.ismailiCentresSecation {
    position: relative;
    margin: 0;
    padding-top: 2em;
}

.ismailiCentresSecation .card {
    border: none;
    border-radius: 0.5em;
}

.ismailiCentresSecation .card:hover {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.ismailiCentresSecation .card .cardImg {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.4;
    display: flex;
    align-items: center;
}

.ismailiCentresSecation .card .cardImg img {
    width: 100%;
    object-fit: fill;
    border-radius: 0.5em;
}

.ismailiCentresSecation .card .card-body {
    position: relative;
    padding: 1.25em 0 0 0;
    text-align: center;
    border: none;
}

.ismailiCentresSecation .card .card-body .logoMain {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 5.9375em;
}

.ismailiCentresSecation .card .card-body .logoMain img {
    width: auto;
    height: 5.9375em;
    object-fit: cover;
    border-radius: 0.5em;
}

.ismailiCentresSecation .titleMain {
    position: relative;
    background: none;
    box-shadow: none;
    padding: 0.9375em 1.25em 0.9375em 0;
    display: flex;
    align-items: center;
}

.ismailiCentresSecation .titleMain h3 {
    color: var(--primary_color);
    font-weight: 700;
    display: block;
    padding-left: 0.8125em;
    font-size: 2em;
    font-style: normal;
}

.ismailiCentresSecation .titleMain h3::before {
    content: '';
    position: absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    background: var(--primary_color);
}

@media (max-width: 767.98px) {
    .ismailiCentresSecation .card {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        transition: all 0.5s ease-in-out;
        margin-top: 1em;
    }

    .ismailiCentresSecation .swiper-pagination-bullet-active {
        background-color: var(--primary_color) !important;
    }
}
