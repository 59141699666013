.hero-card {
    position: relative;
    padding-bottom: 2em;
}

.hero-card .container {
    position: relative;
    text-align: center;
    color: white;
}

.hero-card .centered {
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding: 20px;
    color: #fff;
    font-size: 1.125em;
    font-weight: 600;
}

.hero-card .hashtag {
    position: relative;
    display: inline-block;
    padding: 0.1875em 0.9375em;
    border-radius: 0.25em;
    background: #eef7f4;
    color: #046f44;
    font-size: 0.875em;
    font-weight: 700;
}

.hero-card h3 {
    font-size: 1.125em;
    font-weight: 600;
}

.hero-card .date {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    font-size: 0.875em;
    color: #fff;
}

.hero-card .author {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    font-size: 0.875em;
    color: #fff;
}

.hero-card .datebody {
    justify-content: center;
}

.mt-6 {
    margin-top: 6rem !important;
}

.hero-card .heroCardHldr::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0.12%, rgba(0, 0, 0, 0.8) 99.88%);
}

.hero-card .swiper-pagination-fraction,
.hero-card .swiper-pagination-custom,
.hero-card .swiper-horizontal > .swiper-pagination-bullets,
.hero-card .swiper-pagination-bullets.swiper-pagination-horizontal {
    display: block !important;
}

.hero-card .swiper {
    padding-top: 0 !important;
}

.mt-6 {
    margin-top: 0 !important;
}