.mediaCardWrapper {
    position: relative;
    padding-top: 2em;
}

.mediaCardWrapper .swiper {
    padding-top: 60px !important;
    padding-bottom: 50px !important;
}

.mediaCardWrapper .newsCardBig {
    position: relative;
}

.mediaCardWrapper .main-heading {
    color: #046f44;
    font-family: 'Poppins', sans-serif;
    font-size: 1.4em;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.mediaCardWrapper .tag-filter {
    color: #828282;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    border: 1px solid #a6a6a6;
    padding: 0 2em;
    border-radius: 15px;
}

.mediaCardWrapper .filter-icon {
    color: #828282;
}

.mediaCardWrapper ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
}

.mediaCardWrapper ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a6a6a6;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.mediaCardWrapper .newsCardBig .card-body {
    position: relative;
    padding: 1.875em 0;
    margin: 0 auto;
}

.mediaCardWrapper .newsCardBig .card-body .heading {
    position: relative;
    padding: 0;
    font-size: 2em;
    color: #046f44;
    line-height: normal;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.mediaCardWrapper .newsCardBig .card-body .textMain {
    position: relative;
    padding: 0;
    font-size: 1.375em;
    color: #000000;
    line-height: normal;
    margin-top: 1.5em;
    font-family: 'Poppins', sans-serif;
}

.mediaCardWrapper .newsCardBig .card-body .summaryText {
    position: relative;
    padding: 0;
    font-size: 1em;
    color: #000000;
    line-height: normal;
    margin-top: 1.5em;
    font-family: 'Poppins', sans-serif;
}

.mediaCardWrapper .newsCardBig .NewsImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.mediaCardWrapper .newsCardBig .NewsImg img {
    width: 100%;
    border-radius: 0.9375em;
    height: 20em;
    object-fit: cover !important;
}

.mediaCardWrapper .button {
    background-color: #046f44;
    border: none;
    color: #fff;
    font-weight: 700;
    padding: 0.725em;
    margin-top: 2em;
    border-radius: 10px;
}

.mediaCardWrapper .newsCard {
    position: relative;
    border-radius: 0.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
}

.mediaCardWrapper .card {
    height: 100%;
    border: none !important;
}

.mediaCardWrapper .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.4;
    display: flex;
    object-fit: cover !important;
}

.mediaCardWrapper .newsCard .line {
    background-color: #e7e7e7;
    margin: 7px;
    height: 0;
}

.mediaCardWrapper .newsCard .cardImg img {
    width: 100%;
    object-fit: cover !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    animation: fadeIn2 1s;
    -webkit-animation: fadeIn2 1s;
    -moz-animation: fadeIn2 1s;
    -o-animation: fadeIn2 1s;
    -ms-animation: fadeIn2 1s;
}

.mediaCardWrapper .newsCard .card-body {
    position: relative;
    padding: 10px 16px;
    margin: 0;
}

.mediaCardWrapper .newsCard .card-body h3 {
    font-size: 1.125em;
    padding: 0;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.mediaCardWrapper .newsCard .card-body .date {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.813em;
    font-weight: 400;
    color: #828282;
    font-family: 'Poppins', sans-serif;
}

.mediaCardWrapper .newsCard .card-body .textMain {
    position: relative;
    margin: 0.4em 0 0 0;
    padding: 0;
    font-size: 0.813em;
    color: #000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: normal;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.mediaCardWrapper .newsCard .card-body .download-btn {
    font-family: 'Poppins', sans-serif;
    color: #1c6c42;
    font-size: 14px;
    font-weight: 500;
    text-align: end;
    justify-content: end !important;
}

.mediaCardWrapper .contPopup {
    position: absolute;
    top: 2.5em;
    width: 13.75em;
    left: 5em;
    height: auto;
    background: #fff;
    padding: 0;
    z-index: 10;
    border-radius: 0.5em;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.05);
}

.mediaCardWrapper .contPopup .titleBox {
    position: relative;
    padding: 0.9375em 0.9375em;
    height: 12em;
    overflow: scroll;
}

.mediaCardWrapper .contPopup .contMain {
    position: relative;
    padding: 0;
    width: 100%;
    overflow: auto;
    border-top: 1px solid #e3e4e8;
}

.mediaCardWrapper .contPopup .contMain ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}

.mediaCardWrapper .contMain ul li {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    line-height: normal;
    border-bottom: 1px solid #e3e4e8;
}

.mediaCardWrapper .contMain ul li {
    padding: 0.5em 0.9375em;
    position: relative;
    display: block;
    font-size: 0.875em;
    font-family: 'Poppins', sans-serif;
}

.mediaCardWrapper ul li:hover {
    background: #eef7f4;
}

.mediaCardWrapper .swiper-button-prev {
    top: 6% !important;
}

.mediaCardWrapper .swiper-button-next {
    top: 6% !important;
}

@media (max-width: 767.98px) {
    .mediaCardWrapper .card-order {
        order: 1;
    }

    .mediaCardWrapper .button {
        width: 100%;
        padding: 1em;
    }

    .mediaCardWrapper .swiper {
        padding-top: 60px !important;
        padding-bottom: 50px !important;
    }

    .mediaCardWrapper {
        padding-top: 1.5em;
    }
}