.paragraphBlockSection {
    position: relative;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
}

.paragraphBody .container {
    max-width: 900px;
}

.paragraphBlockSection .heading {
    color: var(--accent_color1);
    font-weight: 500;
    font-size: 1.11em;
    line-height: 34px;
}

.paragraphBlockSection .description p {
    color: var(--accent_color1);
    font-weight: 400;
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.paragraphBlockSection .description p a {
    color: var(--primary_color) !important;
    text-decoration: underline !important;
}
