.mediaFooter {
    position: relative;
    padding: 3em 0 3em 0;
    margin-top: 8rem;
    border-top: 3px solid var(--Grey-point-5, #f8f8f8);
    background: #fbfbfb;
}

.mediaFooter .mediaLogoAppHldr {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 0 !important;
}

.mediaFooter .mediaLogoAppHldr .logo {
    position: relative;
    margin-right: auto;
    margin-bottom: 1em;
}

.mediaFooter .mediaLogoAppHldr .logo img {
    width: 240px;
}

.mediaFooter .mediaAboutTxt {
    position: relative;
    color: #000000;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 1em;
    margin-bottom: 1em;
}

.mediaFooter .copyright {
    position: relative;
    color: #000000;
    line-height: normal;
    letter-spacing: 2px;
    font-size: 0.7em;
    text-transform: uppercase;
    font-family: Poppins;
    text-align: right;
    border-top: 1px solid #e0e0e0;
    padding: 3em 0;
}

.mediaFooter .mediaEmail {
    position: relative;
    color: #000000;
    line-height: normal;
    letter-spacing: 2px;
    font-size: 1em;
    text-transform: uppercase;
    font-family: Poppins;
}

.mediaFooter .linkMain {
    position: relative;
    margin-bottom: 1.25em;
    margin-top: 1.25em;
}

.mediaFooter .linkMain h6 {
    position: relative;
    margin: 0;
    padding: 0 0 0.325em 0;
    font-size: 0.825em;
    font-family: Poppins;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.mediaFooter .linkMain ul {
    position: relative;
    list-style: none;
    margin-top: 10px;
    padding: 0;
    display: grid;
    grid-template-columns: 100%;
}

.mediaFooter .linkMain ul.grid-size-50 {
    grid-template-columns: repeat(auto-fit, 50%);
}

.mediaFooter .linkMain ul li {
    position: relative;
    margin: 0 0.5em 0.5em 0;
    padding: 0;
    display: inline-block;
    line-height: normal;
    text-transform: uppercase;
    color: #000;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 1.95px;
    text-transform: uppercase;
}

.mediaFooter .linkMain ul li a:hover {
    color: #3f1a1a;
}

.mediaFooter .line {
    background-color: #000000;
    height: 1px;
    width: 70px;
}

.footer-col {
    width: 25%;
    padding: 0 15px;
}

.mediaFooter .linkMain h6::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    background-color: #000000;
    height: 2px;
    box-sizing: border-box;
    width: 50px;
}

.mediaFooter .linkMain ul li:not(:last-child) {
    margin-bottom: 5px;
}

@media (max-width: 767.98px) {
    .mediaFooter .mediaAboutTxt {
        font-size: 10px;
    }

    .mediaFooter .mediaEmail {
        margin-top: 1em;
    }

    .footer-col {
        width: 100%;
    }

    .mediaFooter .mediaEmail {
        text-align: center;
    }

    .mediaFooter .copyright {
        text-align: center;
    }
}