.contactTitleHldr {
    padding-bottom: 3.5em;
    padding-top: 3.5em;
    background: rgb(248, 248, 248);
}

.contactFormWrapper {
    position: relative;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}

.contactFormWrapper .shadow-lg {
    padding: 30px;
    border-radius: 30px;
    margin-top: 30px;
}

.contactFormWrapper h2 {
    font-size: 45px;
    font-weight: 500;
}

.contactFormWrapper p {
    margin-bottom: 15px;
}
.contactFormWrapper p:last-child {
    margin-bottom: 0;
}

.contactFormWrapper .contactImg {
    text-align: center;
}

.contactFormWrapper .contactImg img {
    width: 100%;
}

.contactFormWrapper .button {
    width: 150px;
    align-items: center;
    background: #fff;
    border: 1px solid #828282;
    border-radius: 0.625em;
    color: #828282;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    padding: 0.375em 1.5em;
    text-align: center;
    margin-left: auto;
}

.contactFormWrapper .button:hover {
    background: #007041;
    border: 1px solid #007041;
    color: #fff;
}

.contactFormWrapper sup {
    color: #007041;
}

.contactFormWrapper .react-tel-input .form-control {
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.75rem 3em;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
    height: auto;
    width: 100%;
}