.quote-panel {
    position: relative;
    padding-top: 3em;
    padding-bottom: 3em;
}

.quote-panel .quote-border-box {
    margin: 0 auto;
    padding: 40px;
    border-radius: 32px;
    border: 2px solid var(--accent_color3);
    display: flex;
}

.quote-panel .quote-border-box .card-heading {
    color: var(--primary_color);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 32px;
    letter-spacing: 0.5px;
}

.quote-panel .quote-border-box .card-title {
    color: var(--accent_color1);
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.quote-panel .quote-border-box .date {
    color: var(--accent_color1);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.quote-panel .quote-border-box .card-body p {
    color: var(--accent_color1);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.quote-panel .quote-border-box .imgPanel {
    position: relative;
    margin: auto;
    padding: 0 2rem;
}

.quote-panel .quote-border-box img {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 50%;
    height: 250px;
    width: 250px;
}

.quote-panel .line {
    border-top: 1px solid var(--accent_color2);
}

@media (max-width: 767.98px) {
    .quote-panel .quote-border-box .card-heading {
        text-align: center;
        font-size: 22px;
        padding: 10px;
    }

    .quote-panel .quote-border-box .card-title {
        font-size: 20px;
        text-align: center;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .quote-panel .quote-border-box .date {
        text-align: center;
    }

    .quote-panel .quote-border-box .card-body p {
        text-align: center;
    }

    .quote-panel .quote-border-box .imgPanel {
        padding: 2rem 3rem;
    }

    .quote-panel .quote-border-box {
        padding: 0;
    }

    .quote-panel .quote-border-box .card-heading {
        padding: 10px 0 5px 0;
    }
}