.featuredCardWrapper {
    position: relative;
}

.featuredCardWrapper .titleMain {
    position: relative;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0.9375em 1.25em 0.9375em 0;
    margin-top: 10px;
}

.featuredCardWrapper .titleMain h3 {
    color: #333;
    font-size: 2em;
    font-style: normal;
    font-weight: 700;
    line-height: 35.2px;
    color: var(--primary_color);
    padding-left: 0.8125em;
}

.featuredCardWrapper .titleMain h3::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    border-radius: 1em;
    background: var(--primary_color);
}

.featuredCardWrapper .card-titleMain {
    position: relative;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
}

.featuredCardWrapper .card-titleMain h3 {
    color: #333;
    font-size: 2em;
    font-style: normal;
    font-weight: 700;
    line-height: 35.2px;
    color: var(--primary_color);
}

.featuredCardWrapper .sub-title {
    color: #000;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 35.2px;
}

.featuredCardWrapper .newsCardBig {
    position: relative;
    background: #fff;
    border-radius: 0.9375em;
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.03);
    border: 1px solid #e5eaf4;
}

.featuredCardWrapper .newsCardBig .NewsImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.featuredCardWrapper .newsCardBig .NewsImg img {
    aspect-ratio: 1.4;
    width: 100%;
    border-radius: 0.9375em 0 0 0.9375em;
    object-fit: cover;
}

.featuredCardWrapper .newsCardBig .card-body {
    position: relative;
    padding: 1.875em 0;
    margin: 0 auto;
    width: 80%;
}

.featuredCardWrapper .newsCardBig .card-body .microsite {
    font-weight: 600;
    font-size: 16px;
    line-height: 12px;
    position: relative;
    padding-left: 0.8125em;
    border-left: 2px solid var(--primary_color);
    margin-bottom: 1em;
}

.featuredCardWrapper .newsCardBig .card-body .iconMain {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 0.625em;
}

.featuredCardWrapper .newsCardBig .card-body .iconMain .mins {
    font-size: 0.875em;
    font-weight: 300;
    margin-right: auto;
}

.featuredCardWrapper .newsCardBig .card-body .iconMain ul {
    position: relative;
    margin: 0;
    padding: 0;
}

.featuredCardWrapper .newsCardBig .card-body .iconMain ul li {
    list-style: none;
    margin-right: 0.1875em;
    font-size: 1.375em;
    line-height: normal;
    display: inline-block;
}

.featuredCardWrapper .newsCardBig .card-body .iconMain ul li a {
    color: #1d1d1d;
}

.featuredCardWrapper .newsCardBig .card-body .iconMain ul li a.active,
.featuredCardWrapper .newsCardBig .card-body .iconMain ul li a:hover {
    color: #4f887a;
}

.featuredCardWrapper .newsCardBig .card-body h3 {
    font-size: 1.5em;
    margin: 0 0 0.1875em 0;
    padding: 0;
    line-height: normal;
    font-weight: 700;
    color: var(--accent_color1);
}

.featuredCardWrapper .newsCardBig .card-body .date {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
    font-weight: 300;
    text-align: start;
}

.featuredCardWrapper .newsCardBig .card-body .author {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
    font-weight: 300;
}

.featuredCardWrapper .newsCardBig .card-body .textMain {
    position: relative;
    margin: 1.5em 0;
    padding: 0;
    font-size: 0.875em;
    color: var(--accent_color1);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    line-height: 20px;
    height: auto;
    font-weight: 400;
}

.featuredCardWrapper .newsCardBig .card-body .textMain span {
    color: var(--accent_color1) !important;
}

.featuredCardWrapper .card .card-body .tag-heading {
    color: var(--accent_color1);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 8px;
    border-left: 2px solid var(--primary_color);
}

.featuredCardWrapper .newsCardBig .card-body .hashtag {
    position: relative;
    display: inline-block;
    padding: 0.1875em 0.9375em;
    border-radius: 0.25em;
    background: var(--accent_color2);
    color: var(--primary_color);
    font-size: 0.875em;
    font-weight: 600;
    margin-top: 3em;
    text-transform: uppercase;
    line-height: 32px;
    letter-spacing: 15%;
}

.featuredCardWrapper .newsCard {
    position: relative;
    border-radius: 0.5em;
    border: none;
}

.featuredCardWrapper .newsCard:hover {
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #e3e4e8;
}

.featuredCardWrapper .card {
    height: 100%;
}

.featuredCardWrapper .card .card-body {
    border: none !important;
}

.featuredCardWrapper .swiper-slide {
    height: auto;
    margin-right: 10px;
    margin-left: 0;
    margin-bottom: 20px;
    margin-top: 20px;
}

.featuredCardWrapper .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.5;
    display: flex;
    align-items: center;
}

.featuredCardWrapper .newsCard .cardImg img {
    object-fit: cover;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.featuredCardWrapper .newsCard .cardImg .tag {
    z-index: 2;
    position: absolute;
    bottom: 0.9375em;
    right: 0.9375em;
    padding: 0.375em 0.5625em;
    border: 1px solid #f2f2f3;
    border-radius: 4px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.8) 0, rgba(255, 255, 255, 0.55) 100%);
    box-shadow: 0 20px 40px 0 rgba(57, 124, 191, 0.1);
    backdrop-filter: blur(20px);
    color: #1d1d1d;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.75em;
    text-transform: uppercase;
}

.featuredCardWrapper .newsCard .card-body {
    position: relative;
    padding: 1.25em;
    margin: 0;
}

.featuredCardWrapper .newsCard .card-body .iconMain {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 0.625em;
}

.featuredCardWrapper .newsCard .card-body .iconMain .mins {
    font-size: 0.875em;
    font-weight: 300;
    margin-right: auto;
}

.featuredCardWrapper .newsCard .card-body .iconMain ul {
    position: relative;
    margin: 0;
    padding: 0;
}

.featuredCardWrapper .newsCard .card-body .iconMain ul li {
    list-style: none;
    margin-right: 0.1875em;
    font-size: 1.375em;
    line-height: normal;
    display: inline-block;
}

.featuredCardWrapper .newsCard .card-body .iconMain ul li a {
    color: #1d1d1d;
}

.featuredCardWrapper .newsCard .card-body .iconMain ul li a.active,
.featuredCardWrapper .newsCard .card-body .iconMain ul li a:hover {
    color: #4f887a;
}

.featuredCardWrapper .newsCard .card-body h3 {
    color: #171821;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.featuredCardWrapper .newsCard .card-body .time {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0px;
    color: var(--accent_color1);
}

.featuredCardWrapper .newsCard .card-body .time .line {
    width: 1px;
    height: 16px;
    margin: 0 8px;
    background: var(--primary_color);
}

.featuredCardWrapper .button {
    color: var(--Grey-3, var(--Grey-3, #828282));
    text-align: center;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    border: 1px solid var(--Grey-3, #828282);
    border-radius: 0.625em;
    background: var(--White, #fff);
    display: inline-flex;
    padding: 0.375em 1.5em;
    align-items: center;
    gap: 10px;
}

.featuredCardWrapper .newsCard .card-body .date {
    color: #83858f;
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
}

.featuredCardWrapper .newsCard .card-body .author {
    color: #046f44;
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.featuredCardWrapper .newsCard .card-body .textMain {
    position: relative;
    margin: 0.5em 0;
    padding: 0;
    font-size: 0.875em;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    height: 4em;
    margin-bottom: 20px;
    text-align: start;
}

.featuredCardWrapper .newsCard .card-body .hashtag {
    position: relative;
    display: inline-block;
    padding: 0 0.9375em;
    border-radius: 0.25em;
    background: var(--accent_color2);
    color: var(--primary_color);
    font-size: 0.875em;
    font-weight: 600;
    margin-top: 1em;
    text-transform: uppercase;
    letter-spacing: 15%;
    line-height: 32px;
}

.featuredCardWrapper .newsCard .card-body .summary {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-weight: 400;
    line-height: 19.6px;
    font-size: 14px;
    color: var(--accent_color3);
}

.card-padding {
    padding: 0 27px 0 27px !important;
}

.featuredCardWrapper .swiper-button-prev,
.swiper-button-next {
    margin-top: 0;
}

.featuredCardWrapper .swiper-button-next {
    margin-top: 0;
}

.featuredCardWrapper .swiper-button-prev {
    top: 1% !important;
}

.featuredCardWrapper .swiper-button-next {
    top: 1% !important;
}

.featuredCardWrapper .swiper {
    padding-top: 50px !important;
    margin-top: -30px;
}

.featuredCardWrapper .swiper-button-prev {
    top: 5% !important;
}

.featuredCardWrapper .swiper-button-next {
    top: 5% !important;
}

.featuredCardWrapper .btn-icon {
    margin-top: 1px;
}

.featuredCardWrapper .newsCardBig .card-body .hashtag {
    position: relative;
    display: inline-block;
    padding: 0.1875em 0.9375em;
    border-radius: 0.25em;
    background: var(--accent_color2);
    color: var(--primary_color);
    font-size: 0.875em;
    font-weight: 600;
    margin-top: 4em;
    text-transform: uppercase;
    line-height: 32px;
    letter-spacing: 15%;
}

@media (max-width: 767.98px) {
    .featuredCardWrapper .swiper-slide {
        margin: 0;
    }

    .padding {
        padding: 15px;
    }

    .featuredCardWrapper .newsCardBig .NewsImg img {
        border-radius: 0;
    }

    .featuredCardWrapper .newsCardBig {
        border-radius: 0;
        box-shadow: none;
        border: none;
    }

    .card-padding {
        padding: 0 0.75em !important;
    }

    .eventWrapper .swiper {
        padding-top: 40px !important;
    }

    .featuredCardWrapper .sub-title {
        line-height: 25.2px;
    }

    .featuredCardWrapper .newsCardBig .card-body {
        width: 100%;
    }

    .featuredCardWrapper .newsCardBig .card-body .hashtag {
        margin-top: 0;
    }

    .featuredCardWrapper .swiper {
        padding-top: 30px !important;
    }
}

.featuredCardWrapper .swiper-pagination-bullet-active {
    width: 43px !important;
    height: 7px !important;
    border-radius: 10px !important;
    background: var(--primary_color) !important;
}

[dir='rtl'] .featuredCardWrapper .newsCard .card-body {
    padding: 0.625em 0.625em 0.625em 0.625em;
}

[dir='rtl'] .featuredCardWrapper .titleMain {
    padding: 0.9375em 0.25em 0.9375em 0;
}

[dir='rtl'] .featuredCardWrapper .titleMain h3 {
    padding-left: 0;
    padding-right: 0.8125em;
}

[dir='rtl'] .featuredCardWrapper .titleMain h3::before {
    right: 0;
}

@media (max-width: 767.98px) {
    .featuredCardWrapper .titleMain h3 {
        font-size: 1.375em;
    }

    .featuredCardWrapper .sub-title {
        font-size: 0.75em;
        line-height: normal;
    }

    .featuredCardWrapper .newsCard .cardImg img {
        border-radius: 0;
        height: 100%;
    }

    .featuredCardWrapper .newsCard .card-body {
        padding: 1.25em 0;
    }

    .featuredCardWrapper .card .card-body .tag-heading {
        line-height: normal;
    }
}
