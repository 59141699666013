.featuredEvents1Section {
    position: relative;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
}

.featuredEvents1Section .block-heading {
    font-size: 1em;
    line-height: 19.5px;
    text-align: center;
    font-weight: 400;
    color: #007041;
}

.featuredEvents1Section .content-heading {
    font-size: 3em;
    line-height: 58.51px;
    text-align: center;
    font-weight: 500;
    color: #000000;
    margin-top: 15px;
}

.featuredEvents1Section .newsCard {
    position: relative;
    border-radius: 0.5em;
    margin: 4px 4px 0 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    border: none;
    height: 100%;
}

.featuredEvents1Section .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.4;
    display: flex;
    align-items: center;
}

.featuredEvents1Section .newsCard .cardImg img {
    object-fit: cover;
    width: 100%;
    border-radius: 15px 15px 0px 0px;
}

.featuredEvents1Section .newsCard .card-body {
    position: relative;
    padding: 0.935em;
    margin: 0;
}

.featuredEvents1Section .newsCard .card-body .day {
    color: #000000;
    text-transform: uppercase;
    font-size: 0.75em;
}

.featuredEvents1Section .newsCard .card-body h3 {
    font-size: 1em;
    font-weight: 400;
    line-height: 19.5px;
    color: #000000;
    margin-bottom: 3em;
}

.featuredEvents1Section .newsCard .card-body .eventDate {
    font-size: 0.75em;
    color: #4f4f4f;
    font-weight: 400;
}

.featuredEvents1Section .swiper {
    padding: 0 15px;
}

.featuredEvents1Section .swiper-button-prev,
.featuredEvents1Section .swiper-button-next {
    top: var(--swiper-navigation-top-offset, 50%) !important;
    background: #fff;
    color: #007041;
    height: 38px !important;
    width: 38px !important;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.featuredEvents1Section .swiper-button-next {
    right: 0;
}

.featuredEvents1Section .swiper-button-prev {
    right: auto !important;
    left: 0px !important;
}

.featuredEvents1Section .swiper-button-next::before,
.featuredEvents1Section .swiper-button-prev::before {
    font-family: FontAwesome;
    font-size: 26px;
}

.featuredEvents1Section .swiper-button-next::before {
    content: '\f105';
}

.featuredEvents1Section .swiper-button-prev::before {
    content: '\f104';
}

@media (max-width: 767.98px) {
    .featuredEvents1Section .content-heading {
        font-size: 2em;
    }

    .featuredEvents1Section .newsCard {
        box-shadow: none;
    }

    .featuredEvents1Section .newsCard .cardImg img {
        border-radius: 15px 15px 15px 15px;
    }

    .featuredEvents1Section .newsCard .card-body h3 {
        margin-bottom: 1em;
    }

    .featuredEvents1Section .swiper {
        padding-bottom: 40px !important;
    }

    .featuredEvents1Section .swiper-pagination-bullet-active {
        background: var(--primary_color) !important;
    }

    .featuredEvents1Section .content-heading {
        margin-bottom: 1.5em;
    }
}
