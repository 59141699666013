.blockHeightMain {
    min-height: calc(100vh - 142px);
    min-height: calc(100svh - 142px);
}

.SecondBlockWrapper {
    position: relative;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    padding: 8em 0;
}

.SecondBlockWrapper::after {
    content: '';
    z-index: 1;
    width: 70%;
    max-width: 1500px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0) 0,
        rgba(0, 0, 0, 0) 1%,
        rgba(0, 0, 0, 0.65) 30%,
        rgba(0, 0, 0, 0.8) 60%
    );
}

.SecondBlockWrapper h2 {
    font-size: 60px;
    color: var(--accent_color4);
    font-weight: 700;
    margin-bottom: 24px;
}

.SecondBlockWrapper p {
    margin-bottom: 24px;
    font-size: 20px;
    color: var(--accent_color4);
    font-weight: 700;
}

.SecondBlockWrapper p:last-child {
    margin-bottom: 0;
}
