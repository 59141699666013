.Get-touch-wrapper {
    position: relative;
    padding: 3.125em 0;
}

.Get-touch-wrapper h2 {
    font-size: 1.5em;
    margin-bottom: 1.25em;
    color: #046f44;
    font-weight: 500;
}

.Get-touch-wrapper p {
    font-size: 1.25em;
    color: #000;
    font-weight: 400;
    margin-bottom: 1.25em;
    line-height: 40px;
}

.Get-touch-wrapper p a {
    color: var(--primary_color) !important;
    text-decoration: underline !important;
}

.Get-touch-wrapper .p-accordion .p-accordion-tab {
    margin-bottom: 2em;
}

.Get-touch-wrapper .p-accordion .p-accordion-tab:last-child {
    margin-bottom: 0;
}

.Get-touch-wrapper .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem;
}

.Get-touch-wrapper .p-accordion .p-accordion-header .p-accordion-header-link {
    background: var(--accent_color2) !important;
    border-radius: 0.625em;
    border: none;
    color: #000000;
    font-size: 1.25em !important;
    font-weight: 500 !important;
}

.Get-touch-wrapper
    .p-accordion
    .p-accordion-header:not(.p-disabled)
    .p-accordion-header-link:focus {
    box-shadow: none;
}

.Get-touch-wrapper
    .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link {
    background: #046f44;
    /* color: red; */
}

.Get-touch-wrapper .p-accordion .p-accordion-header .p-accordion-header-link svg {
    display: none;
}

.Get-touch-wrapper .p-accordion .p-accordion-header .p-accordion-header-link::after {
    content: '\f067';
    font: normal normal normal 16px/1 FontAwesome !important;
    background: 0 0;
    width: 2.2em;
    height: 2.2em;
    border: var(--primary_color) !important;
    text-align: center;
    font-size: 1.375em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--primary_color1) !important;
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
}

.Get-touch-wrapper
    .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link::after {
    content: '\f068';
    font: normal normal normal 16px/1 FontAwesome !important;
    color: var(--primary_color1) !important;
    border: var(--primary_color) !important;
    transition: transform 0.2s ease-in-out;
    transform: rotate(-180deg);
}

.Get-touch-wrapper .p-accordion .p-accordion-content {
    padding: 1rem;
    border: none;
    font-size: 18px;
    font-weight: 400;
    text-align: start;
}

@media (max-width: 767.98px) {
    .Get-touch-wrapper p {
        font-size: 0.875em !important;
    }

    .Get-touch-wrapper .p-accordion .p-accordion-header .p-accordion-header-link {
        font-size: 0.875em !important;
    }
}

[dir='rtl'] .Get-touch-wrapper .p-accordion .p-accordion-header .p-accordion-header-link::after {
    margin-right: auto;
    margin-left: 0;
}
