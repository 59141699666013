.photoGalleryWrapper {
    position: relative;
}

.photoGalleryWrapper .title {
    position: relative;
    margin: 0 0 30px 0;
    padding: 0;
}

.photoGalleryWrapper .title h2 {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 20px;
}

.photoGalleryWrapper .title .line {
    position: relative;
    width: 116px;
    height: 2px;
    display: block;
    margin-top: 15px;
    background: red;
}

.photoGalleryWrapper .photoGalleryHldr {
    position: relative;
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.photoGalleryWrapper .photoGalleryHldr .imgMain {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 1px solid #fff;
    transition: 0.5s;
    cursor: pointer;
}

.photoGalleryWrapper .photoGalleryHldr .imgMain img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.photoGalleryWrapper .photoGalleryHldr .imgMain:hover {
    transform: scale(1.3);
    z-index: 2;
}

.photoGalleryWrapper .photoGalleryHldr .imgMain:hover::after {
    content: '\e908';
    font-family: 'PrimeIcons';
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 50px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.photoDescription {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    z-index: 99999;
    text-align: center;
}

.customOverlay {
    position: relative;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.description {
    margin-top: auto;
    font-size: 16px;
    line-height: 1.5;
}

.PhotoView-Slider__Overlay {
    position: absolute;
    bottom: 0;
    z-index: 30;
    left: 0;
    right: 0;
}

@media (max-width: 767.98px) {
    .photoGalleryWrapper .photoGalleryHldr {
        grid-template-columns: 50% 50%;
    }
}
