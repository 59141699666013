.accordionWrapper {
    position: relative;
    padding: 2em 0 0 0;
}

.accordiontabWrapper .accordiontabHldr {
    position: relative;
}

.accordiontabWrapper .accordiontabHldr .nav-item .nav-link {
    font-size: 1em;
    font-weight: 600;
    color: var(--accent_color1);
}

.accordiontabWrapper .accordiontabHldr .nav-item .nav-link.active {
    color: var(--primary_color);
    background-color: var(--accent_color2);
}

.accordionWrapper .p-accordion .p-accordion-tab {
    margin-bottom: 2em;
}

.accordionWrapper .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem;
}

.accordionWrapper .p-accordion .p-accordion-header .p-accordion-header-link {
    background: var(--primary_color);
    border-radius: 0.625em;
    border: none;
    color: var(--accent_color4);
    font-size: 1.25em;
    font-weight: 700;
}

.accordionWrapper
    .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight:hover
    .p-accordion-header-link {
    background: var(--primary_color);
    color: var(--accent_color4);
}

.accordionWrapper
    .p-accordion
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link::after {
    color: #fff;
    border: 2px solid #fff;
}

.accordionWrapper
    .p-accordion
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link {
    background: var(--primary_color);
    color: var(--accent_color4);
}

.accordionWrapper .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none;
}

.accordionWrapper
    .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link {
    background: var(--primary_color);
    color: var(--accent_color4);
}

.accordionWrapper .p-accordion .p-accordion-header .p-accordion-header-link svg {
    display: none;
}

.accordionWrapper .p-accordion .p-accordion-header .p-accordion-header-link::after {
    content: '\f067';
    font: normal normal normal 16px/1 FontAwesome !important;
    background: 0 0;
    width: 2.2em;
    height: 2.2em;
    border: 2px solid var(--accent_color4);
    text-align: center;
    font-size: 1.375em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--accent_color4);
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
}

.accordionWrapper
    .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link::after {
    content: '\f068';
    font: normal normal normal 16px/1 FontAwesome !important;
    color: #fff;
    border: 2px solid #fff;
    transition: transform 0.2s ease-in-out;
    transform: rotate(-180deg);
}

.accordionWrapper .p-accordion .p-accordion-content {
    padding: 1rem;
    border: none;
    font-size: 1em;
    font-weight: 400;
    color: var(--accent_color1);
}

@media (max-width: 767.98px) {
    .padding {
        padding: 15px;
    }

    .accordionWrapper .accordiontabWrapper .accordiontabHldr .nav-item {
        margin: 0;
    }
}

@keyframes fadeIn2 {
    0% {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}
