.blockHeightMain {
    min-height: calc(100vh - 142px);
    min-height: calc(100svh - 142px);
}

.blockMainWrapper {
    position: relative;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    padding: 8em 0;
}

.blockMainWrapper h2 {
    font-size: 60px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 24px;
}

.blockMainWrapper p {
    margin-bottom: 24px;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
}

.blockMainWrapper p:last-child {
    margin-bottom: 0;
}

.blockMainWrapper .textMainHldr {
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
    width: 90%;
    max-width: 1170px;
    text-align: center;
}

.blockMainWrapper .textMainHldr h1 {
    font-size: 60px;
    color: #fff;
    font-weight: 600;
}

.blockMainWrapper .textMainHldr .txtMain {
    margin-top: 15px;
    font-size: 30px;
    color: #fff;
    font-weight: 400;
}
