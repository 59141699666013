.eventHeroMainWrapper {
    position: relative;
    background-size: cover;
    background-position: center center;
    padding: 8em 0;
}

.eventHeroMainWrapper h2 {
    font-size: 60px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 24px;
}

.eventHeroMainWrapper p {
    margin-bottom: 24px;
    font-size: 20px;
    color: #fff;
    font-weight: 500;
}

.eventHeroMainWrapper p:last-child {
    margin-bottom: 0;
}

.eventHeroMainWrapper .textMainHldr {
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
    width: 90%;
    max-width: 1170px;
    text-align: center;
}
