.ismailiTVWrapper {
    position: relative;
    padding-bottom: 3em;
    padding-top: 1em;
    background: #eef7f4;
}

.ismailiTVWrapper .titleMain {
    position: relative;
    background: none;
    padding: 0.9375em 1.25em 0.9375em 0em;
    display: flex;
    align-items: center;
}

.ismailiTVWrapper .titleMain h3 {
    color: #046f44;
    font-weight: 700;
    display: block;
    position: relative;
    padding-left: 0.8125em;
    font-size: 2em;
}

.ismailiTVWrapper .titleMain h3::before {
    content: '';
    position: absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    background: #046f44;
}

.ismailiTVWrapper .newsCardBig {
    position: relative;
    background: #fff;
    border-radius: 0.9375em;
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.03);
    border: 1px solid #e5eaf4;
    border: none;
}

.ismailiTVWrapper .newsCardBig .NewsImg {
    position: relative;
    width: 100%;
    height: 27.75em;
}

.ismailiTVWrapper .newsCardBig .NewsImg img {
    height: 27.75em;
    width: 100%;
    border-radius: 22px;
    padding: 0.75em;
    object-fit: cover;
}

.ismailiTVWrapper .newsCardBig .card-body {
    position: relative;
    padding: 1.875em 0;
    margin: 0 auto;
    width: 80%;
}

.ismailiTVWrapper .newsCardBig .card-body .card-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    margin: -9px 20px 10px;
}

.ismailiTVWrapper .newsCardBig .card-body .card-title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: 1.5rem !important;
}

.ismailiTVWrapper .newsCardBig .card-body .card-text {
    font-style: normal;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    line-height: normal;
}

.ismailiTVWrapper .newsCard {
    position: relative;
    padding: 0.5em;
    border-radius: 0.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    background: #fff;
    height: 29rem;
    border: none;
}

.ismailiTVWrapper .card {
    height: 100%;
}

.ismailiTVWrapper .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 14.5em;
}

.ismailiTVWrapper .newsCard .cardImg img {
    height: 14.5em;
    object-fit: cover;
    width: 100%;
    border-radius: 1em;
    padding: 10px;
}

.ismailiTVWrapper .newsCard .sub-card-body {
    position: relative;
    padding: 10px;
    margin: 0;
}

.ismailiTVWrapper .newsCard .dateName {
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
}

.ismailiTVWrapper .newsCard .dateName .name {
    font-size: 1em;
    font-weight: 500;
    color: #1d1d1d;
    margin-right: 0.9375em;
}

.ismailiTVWrapper .newsCard .dateName .date {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 0.875em;
    color: #666;
}

.ismailiTVWrapper .newsCard .dateName .date .dot {
    width: 0.5em;
    height: 0.5em;
    background-color: #046f44;
    margin-right: 0.625em;
    border-radius: 0.625em;
}

.ismailiTVWrapper .newsCard .sub-card-body h3 {
    font-size: 1.5em;
    margin: 0 0 0.1875em 0;
    padding: 0;
    line-height: normal;
    font-weight: 600;
}

.ismailiTVWrapper .swiper-slide {
    height: auto;
}

.ismailiTVWrapper .newsCard .sub-card-body .textMain {
    position: relative;
    padding: 0;
    font-size: 0.875em;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
}

.ismailiTVWrapper .button {
    border-radius: 6px;
    background-color: #56ab91;
    border: 1px solid;
    color: white;
    display: block;
    margin: 0 auto;
}

.ismailiTVWrapper .button .btn-text {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.ismailiTVWrapper .swiper-button-prev,
.swiper-button-next {
    margin-top: 0;
}

.ismailiTVWrapper .swiper-button-next {
    margin-top: 0;
}

.ismailiTVWrapper .swiper-button-prev {
    top: 1% !important;
}

.ismailiTVWrapper .swiper-button-next {
    top: 1% !important;
}

@media (max-width: 767.98px) {
    .ismailiTVWrapper .newsCardBig .card-body .card-heading {
        font-size: 1.5rem;
        margin: -3px 17px;
    }

    .ismailiTVWrapper .newsCardBig .NewsImg img {
        height: 20.75em;
    }

    .ismailiTVWrapper .newsCardBig .NewsImg {
        height: 20.75em;
    }

    .ismailiTVWrapper .newsCardBig .card-body .card-title {
        margin-top: 0 !important;
    }
}

[dir='rtl'] .ismailiTVWrapper .titleMain {
    padding: 0.9375em 0.25em 0.9375em 0em;
}

[dir='rtl'] .ismailiTVWrapper .titleMain h3 {
    padding-left: 0;
    padding-right: 0.8125em;
}

[dir='rtl'] .ismailiTVWrapper .titleMain h3::before {
    right: 0;
}

[dir='rtl'] .ismailiTVWrapper .newsCard .dateName .name {
    margin-left: 0.9375em;
}

[dir='rtl'] .ismailiTVWrapper .newsCard .dateName .date .dot {
    margin-left: 0.625em;
}
