.homeNewsWrapper {
    position: relative;
    margin: 0;
    padding-bottom: 3em;
    padding-top: 1em;
}

.homeNewsWrapper .boxMain {
    position: relative;
    padding: 1em;
    margin: 0;
    background: #fff;
    border-radius: 0.5em;
    margin-bottom: 14px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.homeNewsWrapper .bigNews {
    position: relative;
    margin: 0;
    padding: 0;
}

.homeNewsWrapper .bigNews .dateName {
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
}

.homeNewsWrapper .bigNews .dateName .name {
    font-size: 1em;
    font-weight: 500;
    color: #1d1d1d;
    margin-right: 0.9375em;
}

.homeNewsWrapper .bigNews .dateName .date {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 0.875em;
    color: #666;
}

.homeNewsWrapper .bigNews .dateName .date .dot {
    width: 0.5em;
    height: 0.5em;
    background-color: #046f44;
    margin-right: 0.625em;
    border-radius: 0.625em;
}

.homeNewsWrapper .bigNews .bigNewsImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0 0 0.625em 0;
    padding: 0;
}

.homeNewsWrapper .bigNews .bigNewsImg img {
    width: 100%;
    border-radius: 0.5625em;
    object-fit: cover;
}

.homeNewsWrapper .bigNews .bigNewsImg .tagOne {
    z-index: 2;
    position: absolute;
    top: 1.25em;
    right: 1.25em;
    padding: 0.375em 0.5625em;
    border: 1px solid #f2f2f3;
    border-radius: 4px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.8) 0, rgba(255, 255, 255, 0.55) 100%);
    box-shadow: 0 20px 40px 0 rgba(57, 124, 191, 0.1);
    backdrop-filter: blur(20px);
    color: #1d1d1d;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.75em;
    text-transform: uppercase;
}

.homeNewsWrapper .bigNews .bigNewsBody {
    position: relative;
    margin: 0;
    padding: 0;
}

.homeNewsWrapper .bigNews .bigNewsBody h3 {
    font-size: 2em;
    padding: 0;
    line-height: normal;
    font-weight: 600;
}

.homeNewsWrapper .bigNews .bigNewsBody p {
    font-size: 1em;
    font-weight: 400;
    color: #666;
    margin-bottom: 0.625em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
}

.homeNewsWrapper .smallNews {
    position: relative;
    margin: 0;
    padding: 0;
}

.homeNewsWrapper .smallNews .dateName {
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
}

.homeNewsWrapper .smallNews .dateName .name {
    font-size: 1em;
    font-weight: 500;
    color: #1d1d1d;
    margin-right: 0.9375em;
}

.homeNewsWrapper .smallNews .dateName .date {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 0.875em;
    color: #666;
}

.homeNewsWrapper .smallNews .dateName .date .dot {
    width: 0.5em;
    height: 0.5em;
    background-color: #046f44;
    margin-right: 0.625em;
    border-radius: 0.625em;
}

.homeNewsWrapper .smallNews .smallNewsImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.homeNewsWrapper .smallNews .smallNewsImg img {
    width: 100%;
    border-radius: 0.5625em;
    object-fit: cover;
    height: 100%;
}

.homeNewsWrapper .smallNews .smallNewsImg .tagOne {
    z-index: 2;
    position: absolute;
    top: 1.25em;
    right: 1.25em;
    padding: 0.375em 0.5625em;
    border: 1px solid #f2f2f3;
    border-radius: 4px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.8) 0, rgba(255, 255, 255, 0.55) 100%);
    box-shadow: 0 20px 40px 0 rgba(57, 124, 191, 0.1);
    backdrop-filter: blur(20px);
    color: #1d1d1d;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.75em;
    text-transform: uppercase;
}

.homeNewsWrapper .smallNews .smallNewsBody {
    position: relative;
    margin: 0;
    padding: 0;
}

.homeNewsWrapper .smallNews .smallNewsBody h3 {
    font-size: 1.125em;
    margin: 0 0 0.5em 0;
    padding: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    line-height: normal;
    font-weight: 600;
}

.homeNewsWrapper .smallNews .smallNewsBody p {
    font-size: 0.875em;
    font-weight: 300;
    color: #666;
    margin-bottom: 0.625em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    line-height: normal;
}

.btn {
    margin-right: 0;
}

.homeNewsWrapper .card-container {
    display: flex;
    width: 100%;
}

.homeNewsWrapper .left-card,
.homeNewsWrapper .right-cards {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.homeNewsWrapper .left-card {
    padding: 20px;
    background-color: #fff;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 100%;
}

.homeNewsWrapper .right-cards {
    margin: 10px;
    height: 100%;
}

.homeNewsWrapper .right-card {
    flex: 1;
    padding: 20px;
    background-color: #fff;
    margin-bottom: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.homeNewsWrapper .swiper {
    height: 100%;
}

@media (min-width: 767.98px) {
    .homeNewsWrapper .swiper {
        padding-top: 0 !important;
        padding-bottom: 30px !important;
    }
}

@media (min-width: 768px) {
    .homeNewsWrapper .swiper-pagination-fraction,
    .homeNewsWrapper .swiper-pagination-custom,
    .homeNewsWrapper .swiper-horizontal > .swiper-pagination-bullets,
    .homeNewsWrapper .swiper-pagination-bullets.swiper-pagination-horizontal {
        display: block !important;
    }
}

@media (max-width: 767.98px) {
    .homeNewsWrapper .smallNews .smallNewsImg {
        height: auto;
    }

    .homeNewsWrapper .left-card {
        height: auto;
    }

    .homeNewsWrapper .boxMain {
        margin-bottom: 30px !important;
    }

    .homeNewsWrapper .smallNews .smallNewsBody p {
        height: auto;
    }

    .homeNewsWrapper .smallNews .smallNewsBody p {
        height: auto;
        -webkit-line-clamp: 4;
    }

    .homeNewsWrapper {
        padding-top: 0;
    }
}
