.HeroStaticWrapper2 {
    position: relative;
    margin: 0;
    height: 354px;
    overflow: hidden;
}

.HeroStaticWrapper2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.HeroStaticWrapper2::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 40.84%,
        rgba(255, 255, 255, 0.692913) 70.07%,
        #ffffff 92.81%
    );
}

.HeroStaticWrapper2 .contMain {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
    z-index: 1;
    margin: 0 auto;
    right: 0;
}

.HeroStaticWrapper2 .contMain .logoHldr {
    position: relative;
    overflow: hidden;
    height: 166px;
    width: auto;
}

.HeroStaticWrapper2 .contMain .logoHldr img {
    width: auto;
    height: 100%;
}

@media (max-width: 767.98px) {
    .HeroStaticWrapper2 .contMain .logoHldr {
        height: 130px;
    }
}
