.pagenot-wrapper {
    position: relative;
    margin: 0;
    padding: 80px 0;
}

.pagenot-wrapper .pagenot-hldr {
    position: relative;
    display: flex;
    align-items: flex-start;
}

.pagenot-wrapper .pagenot-hldr .numberTxt {
    font-weight: 500;
    font-size: 40px;
    margin-right: 20px;
    padding-right: 20px;
    border-right: 2px solid var(--accent_color3);
    padding-top: 20px;
    padding-bottom: 20px;
}

.pagenot-wrapper .pagenot-hldr .notFound-txt {
    position: relative;
}

.pagenot-wrapper .pagenot-hldr .notFound-txt h2 {
    font-size: 40px;
    font-weight: 500;
}

.pagenot-wrapper .pagenot-hldr .notFound-txt p {
    font-size: 18px;
    font-weight: 400;
}

.pagenot-wrapper .searchMain {
    position: relative;
    padding-left: 0px;
    padding-top: 40px;
    width: 100%;
}

.pagenot-wrapper .searchMain p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

.pagenot-wrapper .searchMain .searchHldr {
    position: relative;
    margin: 0;
    padding: 0;
    width: 70%;
}

.pagenot-wrapper .searchMain .searchHldr i {
    position: absolute;
    top: 15px;
    right: 16px;
}

.pagenot-wrapper .searchMain .searchHldr input {
    background: rgba(248, 248, 248, 1);
    padding: 10px 22px;
    border-radius: 4px;
    border: none;
    width: 100%;
}

.pagenot-wrapper .searchMain .btn {
    position: relative;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding: 10px 30px;
    background: rgba(24, 123, 65, 1);
    color: #fff;
    margin-top: 40px;
}

@media (max-width: 767.98px) {
    .pagenot-wrapper .pagenot-hldr .numberTxt {
        display: none;
    }

    .pagenot-wrapper .pagenot-hldr .notFound-txt h2 {
        font-size: 24px;
        margin-bottom: 8px;
    }

    .pagenot-wrapper .pagenot-hldr .notFound-txt p {
        font-size: 14px;
    }

    .pagenot-wrapper .pagenot-hldr .notFound-txt {
        border: none;
        margin: 0;
        padding: 0;
    }

    .pagenot-wrapper .searchMain {
        padding-left: 0;
        width: 100%;
    }
}
