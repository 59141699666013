.programmesWrapper {
    position: relative;
    padding-top: 1em;
    padding-bottom: 3em;
}

.programmesWrapper .titleMain {
    position: relative;
    background: none;
    padding: 0.9375em 1.25em 0.9375em 0em;
    display: flex;
    align-items: center;
}

.programmesWrapper .titleMain h3 {
    color: var(--primary_color);
    font-weight: 700;
    padding-left: 0.8125em;
    line-height: 35.2px;
    font-size: 2em;
}

.programmesWrapper .titleMain h3::before {
    content: '';
    position: absolute;
    border-radius: 1em;
    top: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    background: var(--primary_color);
}

.programmesWrapper .programmesTagWrapper {
    position: relative;
    margin: 0;
    padding: 0;
}

.programmesWrapper .programmesTagWrapper ul {
    list-style: none;
    margin: 0 0;
    display: flex;
    align-items: center;
    cursor: grabbing;
}

.programmesWrapper .programmesTagWrapper ul li {
    position: relative;
    margin: 0.3125em 0.625em 0.3125em 0;
    padding: 0.325em 1em;
    display: inline-block;
    text-align: center;
    border-radius: 2em;
    cursor: pointer;
    color: var(--accent_color1);
    font-size: 0.875em;
    white-space: nowrap;
    border: 1px solid var(--primary_color);
    margin-bottom: 5px;
    text-transform: uppercase;
    line-height: 24.77px;
    font-weight: 400;
}

.programmesWrapper .newsCard {
    position: relative;
    padding: 0;
    border-radius: 0.5em;
    margin: 4px;
}

.programmesWrapper .card {
    border: none;
    height: 100%;
    background: none;
}

.programmesWrapper .programmesTagWrapper .active {
    background: var(--primary_color);
    color: var(--accent_color4);
    border: 1px solid var(--Secondary-Green, var(--primary_color));
    font-weight: 700;
}

.programmesWrapper .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.4;
    display: flex;
}

.programmesWrapper .newsCard .cardImg img {
    object-fit: cover;
    width: 100%;
    border-radius: 0.5em;
    animation: fadeIn2 1s;
    -webkit-animation: fadeIn2 1s;
    -moz-animation: fadeIn2 1s;
    -o-animation: fadeIn2 1s;
    -ms-animation: fadeIn2 1s;
}

.programmesWrapper .newsCard .cardImg .tag {
    z-index: 2;
    position: absolute;
    bottom: 0.9375em;
    right: 0.9375em;
    padding: 0.375em 0.5625em;
    border: 1px solid #f2f2f3;
    border-radius: 4px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.8) 0, rgba(255, 255, 255, 0.55) 100%);
    box-shadow: 0 20px 40px 0 rgba(57, 124, 191, 0.1);
    backdrop-filter: blur(20px);
    color: #1d1d1d;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.75em;
    text-transform: uppercase;
}

.programmesWrapper .newsCard .card-body {
    position: relative;
    padding: 0.625em 0 0 0;
    margin: 0;
}

.programmesWrapper .newsCard .card-body .iconMain {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 0.625em;
}

.programmesWrapper .newsCard .card-body .iconMain .mins {
    font-size: 0.875em;
    font-weight: 300;
    margin-right: auto;
}

.programmesWrapper .newsCard .card-body .iconMain ul {
    position: relative;
    margin: 0;
    padding: 0;
}

.programmesWrapper .newsCard .card-body .iconMain ul li {
    list-style: none;
    margin-right: 0.1875em;
    font-size: 1.375em;
    line-height: normal;
    display: inline-block;
}

.programmesWrapper .newsCard .card-body .iconMain ul li a {
    color: #1d1d1d;
}

.programmesWrapper .newsCard .card-body .iconMain ul li a.active,
.programmesWrapper .newsCard .card-body .iconMain ul li a:hover {
    color: #4f887a;
}

.programmesWrapper .newsCard .card-body h3 {
    font-size: 1.5em;
    margin: 0 0 0.1875em 0;
    padding: 0;
    line-height: normal;
    font-weight: 700;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: var(--accent_color1);
}

.programmesWrapper .newsCard .card-body .date {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
    font-weight: 600;
    color: var(--accent_color3);
    text-align: start;
}

.programmesWrapper .newsCard .card-body .textMain {
    position: relative;
    margin: 0.5em 0;
    padding: 0;
    font-size: 0.875em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: 24px;
    margin-bottom: 40px;
    height: auto;
    color: var(--accent_color3);
    font-weight: 600;
}

.programmesWrapper .newsCard .card-body .hashtag {
    position: relative;
    display: inline-block;
    padding: 0.1875em 0.9375em;
    border-radius: 0.25em;
    background: var(--accent_color2);
    color: var(--primary_color);
    font-size: 0.875em;
    font-weight: 600;
}

.scrollbarStyles {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.hidescrollbar::-webkit-scrollbar {
    display: none;
}

.programmesWrapper .swiper-button-prev,
.swiper-button-next {
    margin-top: 0;
}

.programmesWrapper .swiper-button-next {
    margin-top: 0;
}

.programmesWrapper .swiper-button-prev {
    top: 1% !important;
}

.programmesWrapper .swiper-button-next {
    top: 1% !important;
}

.programmesWrapper .newsCard .card-body .tag-heading {
    color: var(--accent_color3);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 8px;
    border-left: 2px solid var(--primary_color);
}

.programmesWrapper .swiper {
    padding-top: 35px !important;
}

.programmesWrapper .viewAllBtn {
    font-size: 18px;
    color: var(--primary_color);
    font-weight: 700;
    position: absolute;
    right: 0;
    top: 35px;
    padding: 0 1.25em;
}

@media (max-width: 767.98px) {
    .programmesWrapper .programmesTagWrapper ul {
        display: block;
        text-align: center;
    }

    .programmesWrapper .newsCardBig {
        border-radius: 0;
        box-shadow: none;
        border: none;
    }

    .programmesWrapper .newsCardBig .card-body {
        width: auto;
    }

    .programmesWrapper .newsCard {
        border-radius: 0;
        box-shadow: none;
        border: none;
        margin: 0;
        padding: 0;
    }

    .programmesWrapper .newsCard .cardImg img {
        border-radius: 0;
    }

    .card-padding {
        padding: 0 0.75em !important;
    }

    .programmesWrapper .scrollbar::-webkit-scrollbar-track {
        background-color: #e6e6e6;
    }

    .programmesWrapper .scrollbar::-webkit-scrollbar {
        width: 5px;
        background-color: #e6e6e6;
    }

    .programmesWrapper .scrollbar::-webkit-scrollbar-thumb {
        background-color: #046f44;
        border: 1px solid #046f44;
    }

    .programmesWrapper .tagBox {
        position: relative;
        margin: 2.5em 0 0 0;
        padding: 0;
        list-style: none;
        height: 10.5em;
        overflow-y: scroll;
    }

    .programmesWrapper .swiper {
        padding-top: 20px !important;
    }
}

@keyframes fadeIn2 {
    0% {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

[dir='rtl'] .programmesWrapper .titleMain {
    padding: 0.9375em 0.25em 0.9375em 0em;
}

[dir='rtl'] .programmesWrapper .titleMain h3 {
    padding-right: 0.8125em;
    padding-left: 0;
}

[dir='rtl'] .programmesWrapper .titleMain h3::before {
    right: 0;
}

[dir='rtl'] .programmesWrapper .programmesTagWrapper {
    padding: 0.9375em 0.25em 0.9375em 0em;
}

[dir='rtl'] .programmesWrapper .programmesTagWrapper ul li {
    margin: 0.3125em 0em 0.3125em 0.625em;
}
