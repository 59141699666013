.news-annou-wrapper {
    position: relative;
    margin: 0;
    padding: 2em 0;
}

.news-annou-wrapper .news-annou-hldr {
    position: relative;
    display: flex;
    align-items: center;
}

.news-annou-wrapper .news-annou-hldr .news-annou-img {
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 0.9375em;
    aspect-ratio: 1.5;
}

.news-annou-wrapper .news-annou-hldr .news-annou-img img {
    width: 100%;
    height: 100%;
    border-radius: 0.9375em;
    object-fit: cover;
    object-position: 50% 50%;
}

.news-annou-wrapper .news-annou-hldr .news-annou-img .imgtext {
    color: #333333;
    padding: 0.625em;
    font-weight: 400;
    font-size: 0.775rem;
}

.news-annou-wrapper .news-annou-hldr .swiper-button-prev,
.news-annou-wrapper .news-annou-hldr .swiper-button-next {
    top: 170px !important;
    bottom: 0;
    padding: 16px;
    background: var(--primary_color);
    border-radius: 50%;
    color: var(--accent_color4);
    background: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.news-annou-wrapper .news-annou-hldr .swiper-button-prev::before,
.news-annou-wrapper .news-annou-hldr .swiper-button-next::before {
    font-family: 'PrimeIcons';
}

.news-annou-wrapper .news-annou-hldr .swiper-button-prev {
    left: 0 !important;
}

.news-annou-wrapper .news-annou-hldr .swiper-button-next {
    right: 0 !important;
    left: auto !important;
}

.news-annou-wrapper .news-annou-hldr .swiper-button-next::before {
    content: '\e932';
    color: var(--primary_color);
    font-size: 16px;
}

.news-annou-wrapper .news-annou-hldr .swiper-button-prev::before {
    content: '\e931';
    color: var(--primary_color);
    font-size: 16px;
}

.news-annou-wrapper .news-annou-hldr .card {
    border: none;
}

.news-annou-wrapper .news-annou-hldr .cardImg img {
    border-radius: 1em;
    object-fit: cover;
    object-position: 50% 50%;
}

.news-annou-wrapper .news-annou-hldr .news-annou-txtMain {
    position: relative;
    margin: 0;
    padding: 0;
}

.news-annou-wrapper .news-annou-hldr .news-annou-txtMain .tagMain {
    color: #046f44;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 0.5em;
    text-align: start;
}

.news-annou-wrapper .news-annou-hldr .news-annou-txtMain h1 {
    font-size: 1.75em;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    color: #1d1d1d;
}

.news-annou-wrapper .news-annou-hldr .cont-social-hldr {
    position: relative;
    padding: 0;
    margin-top: 3em;
}

.news-annou-wrapper .news-annou-hldr .cont-social-hldr .cont-main h4 {
    font-size: 0.9375em;
    font-weight: 600;
}

.news-annou-wrapper .news-annou-hldr .cont-social-hldr .cont-main .dateTime {
    position: relative;
    display: flex;
    align-items: center;
}

.news-annou-wrapper .news-annou-hldr .cont-social-hldr .cont-main .dateTime span {
    font-size: 0.75em;
    color: var(--accent-color3);
    font-weight: 400;
}

.news-annou-wrapper .news-annou-hldr .cont-social-hldr ul.socialHldr {
    position: relative;
    margin: 0.2em 0em 0em 0em;
    padding: 0;
    list-style: none;
    text-align: start;
    display: flex;
}

.news-annou-wrapper .news-annou-hldr .cont-social-hldr ul.socialHldr li {
    display: inline-block;
    list-style: none;
    margin: 1em 1em 1em 0;
    font-size: 0.7em;
}

.news-annou-wrapper .news-annou-hldr .cont-social-hldr ul.socialHldr li i {
    font-size: 1.5em;
    color: #828282;
}

.article2-TextSecation {
    position: relative;
    margin: 0;
    padding: 0;
}

.article2-TextSecation .textHldr {
    position: relative;
    margin: 0;
    border-top: 1px solid #e6e6e6;
    padding: 1.5em 0em;
    line-height: 28px;
}

.article2-TextSecation .textHldr p {
    font-weight: 400;
    font-size: 1.25em;
    margin-bottom: 25px;
    letter-spacing: 0px;
}

.article2-TextSecation .textHldr ul li {
    list-style: disc;
    margin-bottom: 25px;
    font-size: 1.25em;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 28px;
}

.article2-TextSecation .textHldr p a {
    color: var(--primary_color) !important;
    text-decoration: underline !important;
}

.article2-TextSecation .iconHldr {
    position: relative;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: end;
    padding: 0em;
}

.article2-TextSecation .iconHldr ul {
    position: relative;
    margin: 23px 0 0 10px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.article2-TextSecation .iconHldr ul li {
    list-style: none;
    margin-right: 0.8em;
    display: inline-block;
}

.article2-TextSecation .iconHldr ul li:last-child {
    margin-right: 0;
}

.article2-TextSecation .p-dropdown {
    width: auto !important;
}

.article2-TextSecation .iconHldr .p-dropdown {
    border-bottom: 1px solid var(--accent_color3);
    margin: 0px;
    margin-inline-start: 10px;
}

.article2-TextSecation .p-dropdown:not(.p-disabled):hover {
    border-bottom: 1px solid var(--accent_color3) !important;
}
.article2-photoGallery {
    padding: 2.125em 0;
}

@media (max-width: 767.98px) {
    .news-annou-wrapper .news-annou-hldr .news-annou-txtMain .tagMain {
        font-weight: 500;
        font-size: 0.75em;
        margin-bottom: 0;
    }

    .news-annou-wrapper .news-annou-hldr .news-annou-txtMain h1 {
        font-size: 1.25em;
    }

    .news-annou-wrapper .mobile-view {
        padding: 0 !important;
    }

    .news-annou-wrapper .news-annou-hldr .cont-social-hldr {
        margin-bottom: 1em;
    }

    .news-annou-wrapper .news-annou-hldr .news-annou-img {
        border-radius: 0;
    }

    .news-annou-wrapper .news-annou-hldr .news-annou-img img {
        border-radius: 0;
    }

    .article2-TextSecation .textHldr .card-order {
        order: 1;
    }

    .article2-TextSecation .iconHldr {
        background: rgba(248, 248, 248, 1);
        margin-bottom: 1em;
    }

    .news-annou-wrapper .news-annou-hldr .cardImg img {
        border-radius: 0;
    }

    .article2-TextSecation .textHldr ul li {
        font-size: 1em;
    }

    .article2-TextSecation .textHldr p {
        font-size: 1em;
    }
}

[dir='rtl'] .news-annou-wrapper .news-annou-hldr .cont-social-hldr ul.socialHldr li {
    margin: 1em 0 1em 1em;
}

[dir='rtl'] .article2-TextSecation .iconHldr ul li {
    margin-right: 0;
    margin-left: 0.8em;
}

[dir='rtl'] .article2-TextSecation .iconHldr ul {
    margin: 23px 10px 0 0px;
}
