.eventWrapper {
    position: relative;
    padding-top: 1em;
    padding-bottom: 3em;
}

.eventWrapper .titleMain {
    position: relative;
    background: #fff;
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.eventWrapper .titleMain h3 {
    padding: 0;
    line-height: normal;
    font-weight: 400;
}

.eventWrapper .sub-title {
    color: #000;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.eventWrapper .newsCardBig {
    position: relative;
    background: #fff;
    border-radius: 0.9375em;
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.03);
    border: 1px solid #e5eaf4;
}

.eventWrapper .newsCardBig .NewsImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.eventWrapper .newsCardBig .NewsImg img {
    aspect-ratio: 1.4;
    width: 100%;
    border-radius: 0.9375em 0 0 0.9375em;
    object-fit: cover;
}

.eventWrapper .newsCardBig .card-body {
    position: relative;
    padding: 1.875em 0;
    margin: 0 auto;
    width: 80%;
}

.eventWrapper .newsCardBig .card-body .iconMain {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 0.625em;
}

.eventWrapper .newsCardBig .card-body .iconMain .mins {
    font-size: 0.875em;
    font-weight: 300;
    margin-right: auto;
}

.eventWrapper .newsCardBig .card-body .iconMain ul {
    position: relative;
    margin: 0;
    padding: 0;
}

.eventWrapper .newsCardBig .card-body .iconMain ul li {
    list-style: none;
    margin-right: 0.1875em;
    font-size: 1.375em;
    line-height: normal;
    display: inline-block;
}

.eventWrapper .newsCardBig .card-body .iconMain ul li a {
    color: #1d1d1d;
}

.eventWrapper .newsCardBig .card-body .iconMain ul li a.active,
.eventWrapper .newsCardBig .card-body .iconMain ul li a:hover {
    color: #4f887a;
}

.eventWrapper .newsCardBig .card-body h3 {
    font-size: 1.5em;
    margin: 0 0 0.1875em 0;
    padding: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    height: 2.5em;
    font-weight: 700;
}

.eventWrapper .newsCardBig .card-body .date {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
    font-weight: 300;
}

.eventWrapper .newsCardBig .card-body .textMain {
    position: relative;
    margin: 1.5em 0;
    padding: 0;
    font-size: 0.875em;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    line-height: normal;
    height: 7.5em;
}

.eventWrapper .newsCardBig .card-body .hashtag {
    position: relative;
    display: inline-block;
    padding: 0.1875em 0.9375em;
    border-radius: 0.25em;
    background: #eef7f4;
    color: #046f44;
    font-size: 0.875em;
    font-weight: 500;
}

.eventWrapper .newsCard {
    position: relative;
    border-radius: 0.5em;
    margin: 4px 4px 0 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px 15px 0px 0px;
}

.eventWrapper .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    aspect-ratio: 1.4;
    display: flex;
    align-items: center;
}

.eventWrapper .newsCard .cardImg img {
    object-fit: cover;
    width: 100%;
    border-radius: 15px 15px 0px 0px;
}

.eventWrapper .newsCard .cardImg .tag {
    z-index: 2;
    position: absolute;
    bottom: 0.9375em;
    right: 0.9375em;
    padding: 0.375em 0.5625em;
    border: 1px solid #f2f2f3;
    border-radius: 4px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.8) 0, rgba(255, 255, 255, 0.55) 100%);
    box-shadow: 0 20px 40px 0 rgba(57, 124, 191, 0.1);
    backdrop-filter: blur(20px);
    color: #1d1d1d;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.75em;
    text-transform: uppercase;
}

.eventWrapper .newsCard .card-body {
    position: relative;
    padding: 0.935em;
    margin: 0;
}

.eventWrapper .newsCard .card-body .iconMain {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 0.625em;
}

.eventWrapper .newsCard .card-body .iconMain .mins {
    font-size: 0.875em;
    font-weight: 300;
    margin-right: auto;
}

.eventWrapper .newsCard .card-body .iconMain ul {
    position: relative;
    margin: 0;
    padding: 0;
}

.eventWrapper .newsCard .card-body .iconMain ul li {
    list-style: none;
    margin-right: 0.1875em;
    font-size: 1.375em;
    line-height: normal;
    display: inline-block;
}

.eventWrapper .newsCard .card-body .iconMain ul li a {
    color: #1d1d1d;
}

.eventWrapper .newsCard .card-body .iconMain ul li a.active,
.eventWrapper .newsCard .card-body .iconMain ul li a:hover {
    color: #4f887a;
}

.eventWrapper .newsCard .card-body h3 {
    font-size: 16px;
    margin: 0 0 0.1875em 0;
    padding: 0;
    font-weight: 400;
}

.eventWrapper .newsCard .card-body .date {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
    font-weight: 600;
    color: #046f44;
    text-align: start;
}

.eventWrapper .newsCard .card-body .eventDate {
    color: #828282;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
}

.eventWrapper .newsCard .card-body .eventLocation {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
}

.eventWrapper .newsCard .card-body .textMain {
    position: relative;
    margin: 0.5em 0;
    padding: 0;
    margin-bottom: 20px;
}

.eventWrapper .newsCard .card-body .textMain {
    font-size: 0.875em;
    color: #666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: normal;
}

.eventWrapper .newsCard .card-body .eventBtn {
    bottom: 0.3em;
    left: 0;
}

.eventWrapper .newsCard .card-body .hashtag {
    position: relative;
    display: inline-block;
    padding: 0.1875em 0.9375em;
    border-radius: 0.25em;
    background: #eef7f4;
    color: #046f44;
    font-size: 0.875em;
    font-weight: 500;
}

.eventWrapper .button {
    border-radius: 4px;
    background: #04656f;
    border: none;
    color: white;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    padding: 3px 18px;
    width: 162px;
    border-radius: 4px;
}

.newsCard .dateMain {
    z-index: 2;
    position: absolute;
    width: 4.125em;
    height: 5.125em;
    border-radius: 5px;
    background: #c9b198;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    top: -2.5em;
    left: 0.9375em;
    text-align: center;
    color: #fff;
    padding: 0.125em 0.75em;
}

.newsCard .dateMain h2 {
    font-size: 1.5em;
    font-weight: 700;
    line-height: normal;
}

.newsCard.dateMain .month {
    font-size: 0.75em;
    font-weight: 600;
    line-height: normal;
}

.card-padding {
    padding: 0 27px 0 27px !important;
}

.eventWrapper .card {
    height: 100% !important;
}

.eventWrapper .card {
    border: none;
}

.eventWrapper .swiper-button-prev,
.swiper-button-next {
    margin-top: 0;
}

.eventWrapper .swiper-button-next {
    margin-top: 0;
}

.eventWrapper .swiper-button-prev {
    top: 0% !important;
}

.eventWrapper .swiper-button-next {
    top: 0% !important;
}

.eventWrapper .card .card-body .tag-heading {
    color: #046f44;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
    letter-spacing: 2.1px;
    text-transform: uppercase;
}

.eventWrapper .swiper {
    padding-top: 50px !important;
    margin-top: -30px;
}

.eventWrapper .morebtn {
    color: var(--accent_color3);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    border: 1px solid var(--accent_color3);
    background: #fff;
    display: inline-flex;
    padding: 6px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
}

.eventWrapper .location-icon {
    margin-left: 3px;
    margin-right: 13px;
}

@media (max-width: 767.98px) {
    .padding {
        padding: 15px;
    }

    .eventWrapper .newsCardBig .NewsImg img {
        border-radius: 0;
    }

    .eventWrapper .newsCardBig {
        border-radius: 0;
        box-shadow: none;
        border: none;
    }

    .eventWrapper .newsCardBig .card-body {
        width: auto;
    }

    .eventWrapper .newsCardBig .card-body .textMain {
        height: auto;
    }

    .eventWrapper .morebtn {
        margin-top: 1em;
    }

    .eventWrapper .newsCard {
        padding: 0;
        height: auto;
    }

    .eventWrapper .newsCard .card-body .textMain {
        height: auto;
    }

    .card-padding {
        padding: 0 0.75em !important;
    }

    .eventWrapper .swiper {
        padding-top: 40px !important;
    }
}

[dir='rtl'] .eventWrapper .button {
    float: right;
}

[dir='rtl'] .newsCard .dateMain {
    left: 0;
    right: 0.9375em;
}

[dir='rtl'] .eventWrapper .titleMain {
    padding: 0.9375em 0.25em 0.9375em 0;
}

[dir='rtl'] .eventWrapper .titleMain h3::before {
    right: 0;
}

[dir='rtl'] .eventWrapper .titleMain h3 {
    padding-right: 0.8125em;
    padding-left: 0;
}
