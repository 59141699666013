.header2 {
    position: fixed;
    padding: 8px 0 8px 0;
    margin: 0;
    background: #fff;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
}

.header2 .topLinks {
    position: relative;
    margin: 0px;
    padding: 0;
}

.header2 .topLinks ul {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header2 .topLinks ul li {
    position: relative;
    padding: 0;
    list-style: none;
    font-size: 12px;
}

.header2 .topLinks ul li i {
    margin-right: 5px;
}

.header2 .topLinks ul li:last-child {
    margin-right: 0;
}

.header2 .topLinks ul li span {
    font-size: 12px;
    user-select: none;
}

.header2 .topLinks ul li a:hover {
    color: #171821;
}

.header-row {
    flex-direction: row;
}

.header2 .logoMain {
    height: 4rem;
}

.header2 .navbar-nav .nav-item .nav-link {
    font-size: 1em;
    font-weight: 400;
    padding: 0;
    margin: 0 0.9375em;
    color: #333;
}

.header2 .navbar-nav .nav-item .nav-link:hover {
    color: #333;
}

.header2 .navbar-nav .nav-item .nav-link.menuArrow {
    position: relative;
}

.header2 .navbar-nav .nav-item .nav-link.menuArrow::after {
    position: absolute;
    width: 16px;
    height: 11px;
    content: '';
    background: url(../../../public/assets/images/down-arrow.svg) no-repeat center right;
    top: 5px;
    top: -18px;
    right: -27px;
}

.header2 .navbar-nav .nav-item .nav-link.menuArrowMobile {
    position: relative;
}

.header2 .navbar-nav .nav-item .nav-link.menuArrowMobile::after {
    position: absolute;
    width: 16px;
    height: 11px;
    content: '';
    background: url(../../../public/assets/images/down-arrow.svg) no-repeat center right;
    top: 5px;
    top: -18px;
    right: -27px;
}

.header2 .navbar-nav .nav-item .dropdown-menu {
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.header2 .navbar-nav .nav-item .dropdown-menu .dropdown-item {
    font-size: 1em;
    font-weight: 400;
    padding: 0.5em 1.375em;
}

.header2 .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover {
    color: #3386c0;
    background: rgba(51, 134, 192, 0.2);
}

.header2 .searchMainHldr {
    position: relative;
    margin-top: auto;
    display: flex;
    align-items: center;
}

.header2 .searchMainHldr ul {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header2 .searchMainHldr ul li {
    position: relative;
    margin: 0 30px 0 0;
    padding: 0;
    list-style: none;
    font-size: 14px;
}

.header2 .searchMainHldr ul li.userName {
    margin-right: 0;
    padding-left: 30px;
    border-left: 1px solid #e0e0e0;
}

.header2 .searchMainHldr ul li span {
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--accent_color3);
}

.header2 .searchMainHldr ul li span .userIcon {
    border: 1px solid var(--accent_color3);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-right: 10px;
    font-size: 20px;
}

.header2 .searchMainHldr ul li span .searchHldr {
    font-size: 20px;
}

.header2 .searchMainHldr ul li span .playIcon {
    border: 1px solid var(--primary_color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 10px;
    font-size: 16px;
    color: var(--primary_color);
}

.header2 .searchMain {
    position: absolute;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.header2 .searchMain .userMain {
    position: relative;
    margin-left: 0.9375em;
}

.header2 .navbar-toggler {
    border: none;
    padding: 0;
}

.header2 .navbar-toggler .navbar-toggler-icon {
    color: #046f44;
}

.header2 .searchMain .userMain .contPopup .contMain ul li:hover {
    background: #eef7f4;
}

.header2 .contPopup .titleBox .heading {
    padding: 5px 10px;
}

.header2 .contPopup .titleBox .headingLine {
    border-bottom: 1px solid #e3e4e8;
    padding: 0 10px;
}

.header2 .contPopup1 .heading {
    padding: 5px 10px;
    font-size: 1em;
    text-align: center;
}

.header2 .contPopup1 .headingLine {
    border-bottom: 1px solid #e3e4e8;
    margin: 7px;
}

.header2 .searchMain .userMain .contPopup1 .contMain1 ul li:hover {
    background: #eef7f4;
}

.header2 ul.nav li.dropdown:hover ul.dropdown-menu {
    display: block;
}

.header2 .searchMain .userMain .contPopup1 {
    position: absolute;
    top: 2.5em;
    right: 0;
    width: 13.75em;
    height: auto;
    background: #fff;
    padding: 0;
    z-index: 10;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.05);
}

.header2 .searchMain .userMain .contPopup1 .contMain1 {
    height: auto;
    border: none;
}

.header2 .searchMain .userMain .translate-icon {
    width: 1.1em;
    height: 1.1em;
    border-radius: 0;
    margin-bottom: 3px;
}

.line {
    background-color: #999;
    height: 1px;
}

.showAll {
    font-size: 1.1875em;
    font-weight: 500;
    color: var(--accent_color1);
}

.MainNavWrapper {
    position: fixed;
    margin-top: -60px;
    /* same as height */
    height: 60px;
    padding: 0 0;
    background: #046f44;
    z-index: 999;
    display: flex;
    justify-content: center;
    width: 100%;
    transition: transform 0.3s ease;
}

.headerBottom {
    height: 60px;
}

.MainNavWrapper .navbar-nav .nav-item .nav-link {
    font-size: 14px;
    color: #7de0d8;
}

.MainNavWrapper .navbar-nav .nav-item .nav-link:hover {
    color: #fff;
    text-decoration: underline;
}

.MainNavWrapper .navbar-nav .nav-item .dropdown-menu {
    border: none;
    border-radius: 0;
    margin-top: 0;
    background: #eef7f4;
    padding: 0;
    min-width: 11.25em;
    top: 100%;
}

.MainNavWrapper .navbar-nav .nav-item .dropdown-menu .dropdown-item {
    color: #56ab91;
    font-size: 1.125em;
    padding: 0.375em 0.75em;
    font-weight: 400;
}

.MainNavWrapper .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover {
    color: #046f44;
    border-bottom: 1px solid #fff;
}

ul.tvMore-link {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin-right: auto;
}

ul.tvMore-link li {
    position: relative;
    margin: 0;
    padding: 0 15px;
    list-style: none;
}

.header2 .userDropdown {
    position: absolute;
    top: 40px;
    right: 0;
    width: 200px;
    height: auto;
    background: #fff;
    padding: 0;
    z-index: 10;
    border-radius: 0.5em;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #f3f3f3;
    padding: 8px 15px;
}

.contPopup1 {
    position: absolute;
    top: 7em;
    width: 13.75em;
    height: auto;
    background: #fff;
    padding: 0;
    z-index: 10;
    box-shadow: 0px 2px 15px 0px #0000001a;
    border-radius: 6px;
    right: 12em;
}

.contPopup1 .titleBox {
    position: relative;
    padding: 0.9375em 0.9375em;
}

.contPopup1 .titleBox h3 {
    font-size: 1em;
}

.contPopup1 .titleBox .line {
    background: #e3e4e8;
    height: 1px;
    text-align: center;
    margin: 0.9375em 0;
}

.contPopup1 .titleBox .line span {
    background: #fff;
    padding: 0 0.9375em;
    position: relative;
    top: -12px;
    color: #c9c9c9;
    font-size: 0.875em;
}

.contPopup1 .contMain1 {
    position: relative;
    padding: 0;
    width: 100%;
    height: 15.625em;
    overflow: auto;
    border-top: 1px solid #e3e4e8;
}

.contPopup1 .contMain1 ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}

.contPopup1 .contMain1 ul li {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    line-height: normal;
}

.contPopup1 .contMain1 ul li {
    padding: 0.5em 0.9375em;
    position: relative;
    display: block;
    font-size: 0.875em;
}

.contPopup1 .contMain1 ul li:hover {
    background: #eef7f4;
}

.contPopup1 .contMain1 {
    position: relative;
    padding: 0;
    width: 100%;
    height: auto;
    overflow: auto;
    border-top: none;
}

.contPopup1 .contMain1 ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}

.contPopup1 .contMain1 ul li {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    line-height: normal;
}

.contPopup1 .contMain1 ul li {
    padding: 0.5em 0.9375em;
    position: relative;
    display: block;
    font-size: 0.875em;
}

.contPopup1 .contMain1 ul li:hover {
    background: #eef7f4;
}

.header2 .searchMain .userMain .contPopup {
    position: absolute;
    top: 1.5em;
    right: 0;
    width: 13.75em;
    height: auto;
    background: #fff;
    padding: 0;
    z-index: 10;
    border-radius: 0.5em;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #f3f3f3;
    padding-bottom: 15px;
}

.header2 .searchMain .userMain .contPopup .titleBox {
    position: relative;
    padding: 10px 10px 5px 10px;
}

.header2 .searchMain .userMain .contPopup .titleBox h3 {
    font-size: 1em;
}

.header2 .searchMain .userMain .contPopup .titleBox .line {
    background: #e3e4e8;
    height: 1px;
    text-align: center;
    margin: 0.9375em 0;
}

.header2 .searchMain .userMain .contPopup .titleBox .line span {
    background: #fff;
    padding: 0 0.9375em;
    position: relative;
    top: -12px;
    color: #c9c9c9;
    font-size: 0.875em;
}

.header2 .searchMain .userMain .contPopup .contMain {
    position: relative;
    padding: 0;
    width: 100%;
    height: 14.625em;
    overflow: auto;
}

.header2 .searchMain .userMain .contPopup .contMain ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.header2 .searchMain .userMain .contPopup .contMain ul li {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    line-height: normal;
}

.header2 .searchMain .userMain .contPopup .contMain ul li {
    padding: 0.5em 0.9375em;
    position: relative;
    display: block;
    font-size: 0.875em;
}

.header2 .searchMain .userMain .contPopup .contMain ul li:hover {
    background: #eef7f4;
}

.header2 .searchMain .userMain .contPopup .contMain1 {
    position: relative;
    padding: 0;
    width: 100%;
    height: 2.625em;
    overflow: auto;
    border-top: 1px solid #e3e4e8;
}

.header2 .searchMain .userMain .contPopup .contMain1 ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}

.header2 .searchMain .userMain .contPopup .contMain1 ul li {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    line-height: normal;
    border-bottom: 1px solid #e3e4e8;
}

.header2 .searchMain .userMain .contPopup .contMain1 ul li {
    padding: 0.5em 0.9375em;
    position: relative;
    display: block;
    font-size: 0.875em;
}

.header2 .searchMain .userMain .contPopup .contMain1 ul li:hover {
    background: #eef7f4;
}

.header2 .button {
    border-radius: 6px;
    background-color: #56ab91;
    border: 1px solid;
    color: white;
    display: block;
    margin: 0 auto;
}

.header2 .button .btn-text {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.border {
    border-bottom: 1px solid #e3e4e8;
}

.slide-up {
    transform: translateY(-100%);
}

.header2 .mt-6 {
    margin-top: 0 !important;
}

[dir='rtl'] .header2 .searchMain .searchBox .search-form button {
    margin-right: -45px;
    margin-left: 0px;
}

[dir='rtl'] .header2 .searchMain .userMain {
    margin-right: 0.9375em;
    margin-left: 0px;
}

[dir='rtl'] .header2 .searchMain .userMain .contPopup1 {
    left: 0 !important;
    right: auto !important;
}

[dir='rtl'] .header2 .logoMain {
    position: relative;
    padding: 0;
    margin-right: 1.375em;
    margin-left: 0;
}

.menuArrow {
    position: relative;
}

.menuArrow::after {
    position: absolute;
    width: 16px;
    height: 11px;
    content: '';
    background: url(../../../public/assets/images/down-arrow.svg) no-repeat center right;
    top: 5px;
    top: -18px;
    right: -27px;
}

.MainNavWrapper .fa {
    margin-left: 10px;
}

.MainNavWrapper .up-arrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.search-dialog .search-card {
    padding: 15px;
    position: relative;
    margin-bottom: 30px;
    border-radius: 10px;
}

.search-dialog .search-card:hover {
    background-color: #ebf2ee;
}

.search-dialog .card-body h3:hover {
    color: var(--accent_color1);
}

.search-dialog .card-body .tagsName:hover {
    color: var(--accent_color3);
}

.search-dialog .modal-search-bar {
    border-bottom: 1px solid #e3e4e8 !important;
    border-radius: 0;
    background: #fff;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
}

.search-dialog .search-icon {
    position: relative;
    top: 9px;
    color: var(--primary_color);
    font-size: 30px;
}

.search-dialog .arrow-icon {
    position: relative;
    top: 22px;
    font-size: 25px;
    color: var(--primary_color);
    cursor: pointer;
}

.search-dialog .search-button {
    position: absolute;
    top: 18px;
    right: 32px;
    border: 0;
    height: 57px;
    background-color: rgb(8 76 97/ 1);
    width: 52px;
}

.search-dialog .search-button {
    position: absolute;
    top: 18px;
    right: 32px;
    border: 0;
    height: 57px;
    background-color: rgb(8 76 97/ 1);
    width: 52px;
}

.search-dialogh .search-button .icon {
    color: #fff;
}

.search-dialog .p-inputtext {
    border-radius: 0;
    width: 100%;
    border: none;
}

.search-dialog .p-inputtext:enabled:focus {
    box-shadow: none;
    border: none;
}

.search-dialog .img-card {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-dialog .img-card img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.search-dialog .card-body h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--accent_color1);
}

.search-dialog .card-body .tagsName {
    font-size: 14px;
    font-weight: 600;
    color: var(--accent_color3);
}

.search-dialog .newsCard {
    position: relative;
    border-radius: 0.5em;
    margin-bottom: 30px;
}

.search-dialog .card {
    border: none !important;
}

.search-dialog .card .card-body {
    border: none !important;
}

.search-dialog .newsCard .card-body {
    position: relative;
    padding: 0.625em 0 0.625em 0.625em;
    margin: 0;
}

.search-dialog .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    border-radius: 10px;
    aspect-ratio: 1.5;
}

.search-dialog .newsCard .cardImg img {
    object-fit: cover;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.search-dialog .newsCard .cardImg img:not([src]) {
    visibility: hidden;
    /* Hides the broken image icon when no source is provided */
}

.search-dialog .newsCard .cardImg img[src=''] {
    visibility: hidden;
    /* Hides if the source is empty */
}

.search-dialog .newsCard .card-body h3 {
    color: #171821;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
}

.search-dialog .newsCard .card-body .date {
    color: #83858f;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.search-dialog .newsCard .card-body .author {
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.search-dialog .newsCard .card-body .summary {
    color: #1d1d1d;
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;
}

.search-dialog .ais-Hits-item,
.search-dialog .ais-InfiniteHits-item {
    box-shadow: none;
    display: inline;
}

.arrow-icon-main {
    display: none;
}

.header2-close-icon .closeIconMain {
    height: 100px;
    width: 100px;
}

.header2-close-icon .closeIconMain::after {
    width: 30px;
    color: var(--primary_color);
}

.header2-close-icon .closeIconMain::before {
    width: 30px;
    color: var(--primary_color);
}

@media (max-width: 767.98px) {
    .header-main {
        height: 62px;
    }

    .header2 .topLinks {
        display: none;
    }

    .header2 .searchMainHldr {
        display: none;
    }

    .search-dialog .search-card {
        padding: 0;
    }

    .arrow-icon-main {
        display: block;
    }

    .header2 .p-dialog .p-dialog-content {
        padding: 0 !important;
    }

    .ais-Hits-item,
    .ais-InfiniteHits-item {
        padding: 0 !important;
    }

    .search-dialog .modal-search-bar {
        margin-top: 5em;
    }

    .search-dialog .search-card {
        margin-bottom: 15px;
    }

    .search-dialog .card-body {
        padding-right: 20px;
        position: relative;
    }

    .search-dialog .card-body h3 {
        font-size: 14px;
    }

    .arrow-icon-main {
        position: absolute;
        top: 25%;
        right: 0;
        font-size: 22px;
        color: #00558c;
    }

    .search-dialog {
        padding-top: 0 !important;
        height: 100% !important;
        width: 100% !important;
        max-height: 100% !important;
    }

    .p-dialog .p-dialog-header {
        border-top-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        padding: 0 !important;
    }

    .header2 .navbar-collapse {
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        background: white;
        margin-left: -13px;
        margin-right: -13px;
        padding: 1em;
    }

    .header2 .logoMain img {
        width: 40% !important;
        height: auto !important;
    }

    .header2 .navbar-collapse .navbar-nav .nav-item .nav-link {
        margin: 0;
        font-size: 1.25em;
        margin-bottom: 10px;
    }

    .header2 .navbar-collapse .navbar-nav .nav-item .nav-link.menuArrow::after {
        top: -28px;
        right: 50px;
        right: 0;
    }

    .header2 .navbar-collapse .navbar-nav .nav-item .nav-link.menuArrowMobile::after {
        right: 0;
        top: -31px;
    }

    .header2 .searchMain {
        top: 5px;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 44px;
    }

    .header2 .burger-menu-mobile {
        position: absolute;
        top: 5px;
        right: 0;
    }

    .header2 .navbar-toggler:focus {
        box-shadow: none;
    }

    .header2 .searchMain .userMain {
        display: block;
    }

    .header2 .searchMain .search-icon i {
        font-size: 1.125em;
        color: #9197a6;
    }

    .header2 .searchMain .searchBox {
        display: none;
        position: fixed;
        top: 86px;
        left: 0;
        right: 0;
        padding: 20px;
        box-shadow: 0 8px 10px 0 rgba(1, 41, 112, 0.1);
        background: #fff;
        z-index: 9999;
        transition: 0.3s;
    }

    .header2.sticky-top {
        z-index: 999;
    }

    .headerBottom {
        display: none;
    }

    .header2 .searchMain .userMain .contPopup {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
    }

    .header2 .searchMain .userMain .contPopup .contMain {
        height: 100%;
    }

    .header2 .close-icon {
        position: absolute;
        right: 16px;
        top: 12px;
        color: var(--primary_color);
        font-size: 24px;
    }

    .header2 .searchMain .userMain .contPopup .contMain ul li {
        padding: 0.5em 0;
    }

    .header2 .logoMain {
        height: auto;
    }

    .MainNavWrapper {
        display: none;
    }

    .header2-close-icon .closeIconMain {
        height: 50px;
        width: 75px;
    }
}

@media all and (min-width: 768px) {
    .header2 .nav-item:hover .dropdown-menu {
        display: block;
    }

    .MainNavWrapper .nav-item:hover .dropdown-menu {
        display: block;
    }
}

[dir='rtl'] #header-wrapper .mr-2 {
    margin-left: 0.5rem;
    margin-right: auto;
}

[dir='rtl'] #header-wrapper .text-base .mr-2 {
    margin-left: 0.5rem;
    margin-right: 0;
}

[dir='rtl'] #header-wrapper .rtl-lang {
    left: 0;
    right: auto;
}

@media (min-width: 728px) {
    [dir='rtl'] .md\:right-0 {
        left: 0px;
        right: auto;
    }
}

[dir='rtl'] .rtl-subMenu .rtl-linkMain a .bi-arrow-right::before {
    content: '\f12f';
}

[dir='rtl'] .rtl-subMenu .rtl-linkMain .bi-chevron-right::before {
    content: '\f284';
}

[dir='rtl'] .rtl-subMenu .rtl-linkMain .rtl-img .text-sm {
    margin-right: 15px;
}

.menu-hide {
    transform: translateY(-100%);
    transition-duration: 0.3s;
    transition-timing-function: linear;
    transition-property: transform;
}

.menu-show {
    transition-duration: 0.3s;
    transition-timing-function: linear;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

[dir='rtl'] .pi-arrow-right:before {
    content: '\e91a';
}

[dir='rtl'] .pi-caret-right:before {
    content: '\e904';
}
