header {
    position: fixed;
    padding: 1.1em 0;
    margin: 0;
    background: #fff;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
}

.header-main-1 {
    position: fixed;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    z-index: 1000;
}

header .logoMain {
    position: relative;
    padding: 0;
    margin-right: 4.375em;
}

header .navbar-nav .nav-item .nav-link {
    font-size: 1em;
    font-weight: 400;
    padding: 0;
    margin: 0 0.9375em;
    color: #333;
}

header .navbar-nav .nav-item .nav-link:hover {
    color: #333;
}

header .navbar-nav .nav-item .nav-link.menuArrow {
    position: relative;
}

header .navbar-nav .nav-item .nav-link.menuArrow::after {
    position: absolute;
    width: 16px;
    height: 11px;
    content: '';
    background: url(../../../public/assets/images/down-arrow.svg) no-repeat center right;
    top: 5px;
    top: -18px;
    right: -27px;
}

header .navbar-nav .nav-item .nav-link.menuArrowMobile {
    position: relative;
}

header .navbar-nav .nav-item .nav-link.menuArrowMobile::after {
    position: absolute;
    width: 16px;
    height: 11px;
    content: '';
    background: url(../../../public/assets/images/down-arrow.svg) no-repeat center right;
    top: 5px;
    top: -18px;
    right: -27px;
}

header .navbar-nav .nav-item .dropdown-menu {
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

header .navbar-nav .nav-item .dropdown-menu .dropdown-item {
    font-size: 1em;
    font-weight: 400;
    padding: 0.5em 1.375em;
}

header .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover {
    color: #3386c0;
    background: rgba(51, 134, 192, 0.2);
}

header .searchMain {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

header .searchMain .search-icon i {
    color: #4bc8be;
}

header .searchMain .searchBox {
    position: relative;
    min-width: 15.625em;
}

header .searchMain .searchBox .search-form {
    display: flex;
    align-items: center;
}

header .searchMain .searchBox .search-form input {
    border: 0;
    font-size: 0.875em;
    color: #999;
    border: none;
    padding: 0.75em 2.375em 0.75em 0.9375em;
    border-radius: 8px;
    transition: 0.3s;
    width: 100%;
    background: #f6f6f6;
}

header .search-country input {
    border: 0;
    font-size: 0.875em;
    color: #999;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    transition: 0.3s;
    background: #f6f6f6;
    margin: 0 auto;
    width: 100%;
}

header .searchMain .searchBox .search-form button {
    border: 0;
    padding: 0;
    margin-left: -45px;
    background: 0 0;
}

header .searchMain .searchBox .search-form button i {
    color: #4bc8be;
}

header .searchMain .userMain {
    position: relative;
    margin-left: 0.9375em;
    display: flex;
    align-items: center;
}

header .searchMain .userMain img {
    width: 2.5em;
    height: 2.5em;
    border-radius: 5em;
}

header .navbar-toggler {
    border: none;
    padding: 0;
}

header .navbar-toggler .navbar-toggler-icon {
    color: #046f44;
}

header .searchMain .userMain .contPopup .contMain ul li:hover {
    background: #eef7f4;
}

header .contPopup .titleBox .heading {
    padding: 5px 10px;
}

header .contPopup .titleBox .headingLine {
    border-bottom: 1px solid #e3e4e8;
    padding: 0 10px;
}

header .contPopup1 .heading {
    padding: 5px 10px;
    font-size: 1em;
    text-align: center;
}

header .contPopup1 .headingLine {
    border-bottom: 1px solid #e3e4e8;
    margin: 7px;
}

header .searchMain .userMain .contPopup1 .contMain1 ul li:hover {
    background: #eef7f4;
}

header ul.nav li.dropdown:hover ul.dropdown-menu {
    display: block;
}

header .searchMain .userMain .contPopup1 {
    position: absolute;
    top: 2.5em;
    right: 0;
    width: 13.75em;
    height: auto;
    background: #fff;
    padding: 0;
    z-index: 10;
    /* border-radius: 0.5em; */
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.05);
}

header .searchMain .userMain .contPopup1 .contMain1 {
    height: auto;
    border: none;
}

header .searchMain .userMain .translate-icon {
    width: 1.1em;
    height: 1.1em;
    border-radius: 0;
    margin-bottom: 3px;
}

.line {
    background-color: #999;
    height: 1px;
}

.showAll {
    font-size: 1.1875em;
    font-weight: 700;
    color: #000;
}

.MainNavWrapper1 {
    position: fixed;
    margin: 0;
    padding: 0 0;
    background: #046f44;
    z-index: 999;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 5em;
}

.headerBottom {
    height: 60px;
}

.MainNavWrapper1 .navbar-nav .nav-item .nav-link {
    font-size: 14px;
    color: #7de0d8;
}

.MainNavWrapper1 .navbar-nav .nav-item .nav-link:hover {
    color: #fff;
    text-decoration: underline;
}

.MainNavWrapper1 .navbar-nav .nav-item .dropdown-menu {
    border: none;
    border-radius: 0;
    margin-top: 0;
    background: #eef7f4;
    padding: 0;
    min-width: 11.25em;
    top: 100%;
}

.MainNavWrapper1 .navbar-nav .nav-item .dropdown-menu .dropdown-item {
    color: #56ab91;
    font-size: 1.125em;
    padding: 0.375em 0.75em;
    font-weight: 400;
}

.MainNavWrapper1 .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover {
    color: #046f44;
    border-bottom: 1px solid #fff;
}

ul.tvMore-link {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin-right: auto;
}

ul.tvMore-link li {
    position: relative;
    margin: 0;
    padding: 0 15px;
    list-style: none;
}

header .searchMain .userMain .contPopup1 {
    position: absolute;
    top: 2.5em;
    right: 0;
    width: 13.75em;
    height: auto;
    background: #fff;
    padding: 0;
    z-index: 10;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.05);
}

header .searchMain .userMain .contPopup1.langPopup {
    top: 4px;
    right: -23px;
}

header .searchMain .userMain .contPopup1 .titleBox {
    position: relative;
    padding: 0.9375em 0.9375em;
}

header .searchMain .userMain .contPopup1 .titleBox h3 {
    font-size: 1em;
}

header .searchMain .userMain .contPopup1 .titleBox .line {
    background: #e3e4e8;
    height: 1px;
    text-align: center;
    margin: 0.9375em 0;
}

header .searchMain .userMain .contPopup1 .titleBox .line span {
    background: #fff;
    padding: 0 0.9375em;
    position: relative;
    top: -12px;
    color: #c9c9c9;
    font-size: 0.875em;
}

header .searchMain .userMain .contPopup1 .contMain1 {
    position: relative;
    padding: 0;
    width: 100%;
    height: 15.625em;
    overflow: auto;
    border-top: 1px solid #e3e4e8;
}

header .searchMain .userMain .contPopup1 .contMain1 ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}

header .searchMain .userMain .contPopup1 .contMain1 ul li {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    line-height: normal;
    border-bottom: 1px solid #e3e4e8;
}

header .searchMain .userMain .contPopup1 .contMain1 ul li {
    padding: 0.5em 0.9375em;
    position: relative;
    display: block;
    font-size: 0.875em;
}

header .searchMain .userMain .contPopup1 .contMain1 ul li:hover {
    background: #eef7f4;
}

header .searchMain .userMain .contPopup1 .contMain1 {
    position: relative;
    padding: 0;
    width: 100%;
    height: auto;
    overflow: auto;
    border-top: none;
}

header .searchMain .userMain .contPopup1 .contMain1 ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}

header .searchMain .userMain .contPopup1 .contMain1 ul li {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    line-height: normal;
    border-bottom: none;
}

header .searchMain .userMain .contPopup1 .contMain1 ul li {
    padding: 0.5em 0.9375em;
    position: relative;
    display: block;
    font-size: 0.875em;
}

header .searchMain .userMain .contPopup1 .contMain1 ul li:hover {
    background: #eef7f4;
}

header .searchMain .userMain .contPopup {
    position: absolute;
    top: 2.5em;
    right: 0;
    width: 13.75em;
    height: auto;
    background: #fff;
    padding: 0;
    z-index: 10;
    border-radius: 0.5em;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.05);
}

header .searchMain .userMain .contPopup .titleBox {
    position: relative;
    padding: 10px 10px 5px 10px;
}

header .searchMain .userMain .contPopup .titleBox h3 {
    font-size: 1em;
}

header .searchMain .userMain .contPopup .titleBox .line {
    background: #e3e4e8;
    height: 1px;
    text-align: center;
    margin: 0.9375em 0;
}

header .searchMain .userMain .contPopup .titleBox .line span {
    background: #fff;
    padding: 0 0.9375em;
    position: relative;
    top: -12px;
    color: #c9c9c9;
    font-size: 0.875em;
}

header .searchMain .userMain .contPopup .contMain {
    position: relative;
    padding: 0;
    width: 100%;
    height: 14.625em;
    overflow: auto;
}

header .searchMain .userMain .contPopup .contMain ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}

header .searchMain .userMain .contPopup .contMain ul li {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    line-height: normal;
}

header .searchMain .userMain .contPopup .contMain ul li {
    padding: 0.5em 0.9375em;
    position: relative;
    display: block;
    font-size: 0.875em;
}

header .searchMain .userMain .contPopup .contMain ul li:hover {
    background: #eef7f4;
}

header .searchMain .userMain .contPopup .contMain1 {
    position: relative;
    padding: 0;
    width: 100%;
    height: 2.625em;
    overflow: auto;
    border-top: 1px solid #e3e4e8;
}

header .searchMain .userMain .contPopup .contMain1 ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}

header .searchMain .userMain .contPopup .contMain1 ul li {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    line-height: normal;
    border-bottom: 1px solid #e3e4e8;
}

header .searchMain .userMain .contPopup .contMain1 ul li {
    padding: 0.5em 0.9375em;
    position: relative;
    display: block;
    font-size: 0.875em;
}

header .searchMain .userMain .contPopup .contMain1 ul li:hover {
    background: #eef7f4;
}

header .button {
    border-radius: 6px;
    background-color: #56ab91;
    border: 1px solid;
    color: white;
    display: block;
    margin: 0 auto;
}

header .button .btn-text {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.border {
    border-bottom: 1px solid #e3e4e8;
}

.slide-up {
    transform: translateY(-100%);
}

header .mt-6 {
    margin-top: 0 !important;
}

[dir='rtl'] header .searchMain .searchBox .search-form button {
    margin-right: -45px;
    margin-left: 0px;
}

[dir='rtl'] header .searchMain .userMain {
    margin-right: 0.9375em;
    margin-left: 0px;
}

[dir='rtl'] header .searchMain .userMain .contPopup1 {
    left: 0 !important;
    right: auto !important;
}

[dir='rtl'] header .logoMain {
    position: relative;
    padding: 0;
    margin-right: 1.375em;
    margin-left: 0;
}

.menuArrow {
    position: relative;
}

.menuArrow::after {
    position: absolute;
    width: 16px;
    height: 11px;
    content: '';
    background: url(../../../public/assets/images/down-arrow.svg) no-repeat center right;
    top: 5px;
    top: -18px;
    right: -27px;
}

.MainNavWrapper1 .fa {
    margin-left: 10px;
}

.MainNavWrapper1 .up-arrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.search-dialog .search-card {
    padding: 15px;
    position: relative;
    margin-bottom: 30px;
    background: white;
    border-radius: 10px;
}

.search-dialog .search-card:hover {
    background-color: #00558c;
    color: white;
}

.search-dialog .search-card.active {
    background-color: #00558c;
    color: white;
}

.search-dialog .search-card.active .author {
    color: white;
}

.search-dialog .search-card:hover .author {
    color: white;
}

.search-dialog .modal-search-bar {
    border-bottom: 1px solid #e3e4e8 !important;
    border-radius: 0;
    background: #fff;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
}

.search-dialog .search-icon {
    position: relative;
    top: 16px;
    color: #00558c;
    font-size: 30px;
}

.search-dialog .arrow-icon {
    position: relative;
    top: 22px;
    font-size: 25px;
    color: #00558c;
    cursor: pointer;
}

.search-dialog .search-button {
    position: absolute;
    top: 18px;
    right: 32px;
    border: 0;
    height: 57px;
    background-color: rgb(8 76 97/ 1);
    width: 52px;
}

.search-dialog .search-button {
    position: absolute;
    top: 18px;
    right: 32px;
    border: 0;
    height: 57px;
    background-color: rgb(8 76 97/ 1);
    width: 52px;
}

.search-dialogh .search-button .icon {
    color: #fff;
}

.search-dialog .p-inputtext {
    border-radius: 0;
    width: 100%;
    border: none;
}

.search-dialog .p-inputtext:enabled:focus {
    box-shadow: none;
    border: none;
}

.search-dialog .img-card {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-dialog .img-card img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.search-dialog .card-body h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}

.search-dialog .card-body .author {
    font-size: 14px;
    font-weight: 600;
    color: #828282;
}

.search-dialog .newsCard {
    position: relative;
    border-radius: 0.5em;
    margin-bottom: 30px;
}

.search-dialog .card {
    border: none !important;
}

.search-dialog .card .card-body {
    border: none !important;
}

.search-dialog .newsCard .card-body {
    position: relative;
    padding: 0.625em 0 0.625em 0.625em;
    margin: 0;
}

.search-dialog .newsCard .cardImg {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    border-radius: 10px;
}

.search-dialog .newsCard .cardImg img {
    object-fit: cover;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.search-dialog .newsCard .card-body h3 {
    color: #171821;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
}

.search-dialog .newsCard .card-body .date {
    color: #83858f;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.search-dialog .newsCard .card-body .author {
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.search-dialog .newsCard .card-body .summary {
    color: #1d1d1d;
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;
}

.search-dialog .ais-Hits-item,
.search-dialog .ais-InfiniteHits-item {
    box-shadow: none;
    display: inline;
}

.arrow-icon-main {
    display: none;
}

.profileMain {
    border-left: 1px solid black;
    padding: 0 0 0 1.5em;
}

@media (max-width: 767.98px) {
    .search-dialog .search-card {
        padding: 0;
    }

    .arrow-icon-main {
        display: block;
    }

    .p-dialog .p-dialog-content {
        padding: 0 !important;
    }

    .ais-Hits-item,
    .ais-InfiniteHits-item {
        padding: 0 !important;
    }

    .search-dialog .modal-search-bar {
        margin-top: 5em;
    }

    .search-dialog .search-card {
        margin-bottom: 15px;
    }

    .search-dialog .card-body {
        padding-right: 20px;
        position: relative;
    }

    .search-dialog .card-body h3 {
        font-size: 14px;
    }

    .arrow-icon-main {
        position: absolute;
        top: 25%;
        right: 0;
        font-size: 22px;
        color: #00558c;
    }

    .search-dialog {
        padding-top: 0 !important;
        height: 100% !important;
        width: 100% !important;
        max-height: 100% !important;
    }

    .p-dialog .p-dialog-header {
        border-top-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        padding: 0 !important;
    }

    header .navbar-collapse {
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        background: white;
        margin-left: -13px;
        margin-right: -13px;
        padding: 1em;
    }

    header .logoMain img {
        width: 40% !important;
        height: auto !important;
    }

    header .navbar-collapse .navbar-nav .nav-item .nav-link {
        margin: 0;
        font-size: 1.25em;
        margin-bottom: 10px;
    }

    header .navbar-collapse .navbar-nav .nav-item .nav-link.menuArrow::after {
        top: -28px;
        right: 50px;
        right: 0;
    }

    header .navbar-collapse .navbar-nav .nav-item .nav-link.menuArrowMobile::after {
        right: 0;
        top: -31px;
    }

    header .searchMain {
        top: 2px;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 44px;
    }

    header .burger-menu-mobile {
        position: absolute;
        top: 2px;
        right: 0;
    }

    header .navbar-toggler:focus {
        box-shadow: none;
    }

    header .searchMain .userMain {
        display: block;
    }

    header .searchMain .search-icon i {
        font-size: 1.125em;
        color: #9197a6;
    }

    header .searchMain .searchBox {
        display: none;
        position: fixed;
        top: 86px;
        left: 0;
        right: 0;
        padding: 20px;
        box-shadow: 0 8px 10px 0 rgba(1, 41, 112, 0.1);
        background: #fff;
        z-index: 9999;
        transition: 0.3s;
    }

    header.sticky-top {
        z-index: 999;
    }

    .headerBottom {
        display: none;
    }

    header .searchMain .userMain .contPopup {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
    }

    header .searchMain .userMain .contPopup .contMain {
        height: 100%;
    }

    header .close-icon {
        position: absolute;
        right: 16px;
        top: 18px;
        color: #046f44;
        font-size: 24px;
    }
}

@media all and (min-width: 768px) {
    header .nav-item:hover .dropdown-menu {
        display: block;
    }

    .MainNavWrapper1 .nav-item:hover .dropdown-menu {
        display: block;
    }
}
