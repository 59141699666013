.info-panel {
    position: relative;
    padding-top: 1em;
}

.info-panel .logo {
    padding: 0px;
    width: 8rem;
    margin: 0 auto;
    display: flex;
}

.info-panel img {
    width: 100%;
    object-fit: cover;
}

.info-panel .header {
    color: var(--primary_color);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26.4px;
    text-align: center;
    margin-top: 1.5em;
}

.info-panel p {
    color: var(--accent_color1);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
}

.info-panel .button {
    border-radius: 4px;
    background-color: var(--primary_color);
    border: 1px solid;
    color: var(--accent_color4);
    font-weight: 600;
    font-size: 14px;
    padding: 8px 18px 8px 18px !important;
    text-align: center;
}

.info-border-box {
    margin: 0 auto;
    padding: 40px;
    border-radius: 32px;
    border: 1px solid var(--accent_color2);
    background: var(--accent_color4);
    box-shadow: 5px 4px 10px 0px rgba(68, 68, 68, 0.1);
}

@media (max-width: 767.98px) {
    .info-border-box {
        display: block;
    }

    .info-panel .logo {
        padding: 0;
        margin-right: 0 !important;
        width: 100%;
    }

    .info-panel .button {
        margin-left: auto;
    }

    .info-panel img {
        width: 128px;
        margin: 0 70px;
    }

    .info-panel .logo {
        padding: 21px !important;
        justify-content: center;
    }

    .info-border-box {
        padding: 20px;
    }
}
